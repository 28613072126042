import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"currency/";

export async function getCurrencies() {
    const {data: Currencies} = await http.get(apiEndpoint);
    return Currencies;
}

export async function getCurrency(currencyId) {
  const {data: Currency} = await http.get(apiEndpoint+currencyId);
  return Currency;
}

export async function addCurrency(currency) {  
  const {data: Currency} = await http.post(apiEndpoint, currency);
  return Currency;
}

export async function updateCurrency(currency) {
  const {data: Currency} = await http.put(apiEndpoint, currency);
  return Currency;
}

export async function deleteCurrency(currencyId) {
  return await http.delete(apiEndpoint+currencyId);
}

export async function getCurrencyHistory(currencyId) {
  const {data: CurrencyHistory} = await http.get(apiEndpoint+"audit/"+currencyId);
  return CurrencyHistory;
}
