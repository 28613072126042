import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { usePermitted } from "../../common/components/permissions/permissions";
import TradingMarketAdmin from "./tradingMarkets/tradingMarketAdmin";
import { useTranslation } from "react-i18next";

const TradingAdmin = () => {
  const queryTradingMarkets = usePermitted(["api-query-market-control"]);
  const manageTradingMarkets = usePermitted(["api-manage-market-control"]);
  const { t } = useTranslation(["trading"]);

  return (
    <Tabs
      className="nav-dark"
      defaultActiveKey={1}
      id="trading-admin-tabs"
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {queryTradingMarkets && (
        <Tab eventKey={1} title={t("TradingMarkets")}>
          <TradingMarketAdmin allowEdit={manageTradingMarkets} />
        </Tab>
      )}
    </Tabs>
  );
};

export default TradingAdmin;

// class TradingAdmin extends Component {

//     render() {
//         return (

//         );
//     }
// }

// export default TradingAdmin;
