import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"marketAttribute/";

export async function getMarketAttributes(marketId) {
  const {data: MarketAttributes} = await http.get(apiEndpoint+marketId);
  return MarketAttributes;
}

export async function addMarketAttribute(marketAttribute) {  
  const {data: MarketAttribute} = await http.post(apiEndpoint, marketAttribute);
  return MarketAttribute;
}

export async function updateMarketAttribute(marketAttribute) {
  const {data: MarketAttribute} = await http.put(apiEndpoint, marketAttribute);
  return MarketAttribute;
}

export async function deleteMarketAttribute(marketAttributeId) {
  return await http.delete(apiEndpoint+marketAttributeId);
}

export async function getMarketAttributeHistory(marketAttributeId) {
  const {data: MarketAttributeHistory} = await http.get(apiEndpoint+"audit/"+marketAttributeId);
  return MarketAttributeHistory;
}
