import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import { withTranslation } from "react-i18next";
import MarketGrid from './marketGrid/marketGrid';
import MarketTrades from './marketTrades/marketTrades';
import UserOrders from './userOrders/userOrders';
import OrderForm from './orderForm/orderForm';

class TradingLayout extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "column",
          weight: 75,
          children: [
            {
              type: "row",
              weight: 80,
              children: [
                {
                  type: "tabset",
                  weight: 50,
                  enableMaximize: false,
                  children: [
                    {
                      type: "tab",
                      name: this.props.t("MarketGrid"),
                      enableClose: false,
                      component: "MarketGrid",
                    },
                  ],
                },
              ]
            },
            {
              type: "row",
              weight: 25,
              children: [
                {
                  type: "tabset",
                  weight: 100,
                  enableMaximize: false,
                  children: [
                    {
                      type: "tab",
                      name: this.props.t("MarketTrades"),
                      enableClose: false,
                      component: "MarketTrades",
                    },
                    {
                      type: "tab",
                      name: this.props.t("UserOrders"),
                      enableClose: false,
                      enableScroll: false,
                      component: "UserOrders",
                    },                    
                  ],
                },
              ]
            },            
          ]
        },
        {
          type: "column",
          weight: 20,
          children: [
            {
              //Figure out how to make this persistent even when empty.
              type: "tabset",
              id: "1",
              weight: 50,
              enableDeleteWhenEmpty: false,
              enableMaximize: false,
              children: [
                {
                  type: "tab",
                  name: this.props.t("OrderForm"),
                  enableClose: false,
                  component: "OrderForm",
                  id: "OrderForm"
                },       
              ],
            }
          ]
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleOrderClicked = (type, data) => {
    this.state.model.doAction(FlexLayout.Actions.selectTab("OrderForm"));
    const lastGridClick = new Date();
    this.setState({type: type, data: data, lastGridClick: lastGridClick})
  };
  

  // handleTabRename = (nodeId, name) => {
  //   this.state.model.doAction(
  //     FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
  //   );
  // };

  factory = (node) => {
    const componentId = node.getComponent();
    //const RoleId = node.getId();

    if (componentId === "MarketGrid") {
      const {marketControl, pickOrder, modifyOrder, withdrawOrder, userAccountId, tradeAllowed} = this.props;   

      return (
        <MarketGrid
          marketControl={marketControl}
          marketOrders={this.props.marketOrders}
          organizationOrders={this.props.organizationOrders}
          marketTrades={this.props.marketTrades}
          onOrderClicked={this.handleOrderClicked}
          modifyOrder={modifyOrder} 
          withdrawOrder={withdrawOrder} 
          pickOrder={pickOrder}
          userAccountId={userAccountId}
          tradeAllowed={tradeAllowed}          
        />
      );
    } 
    else if (componentId === "MarketTrades")
    {
      const {userAccountId} = this.props;

      return (
        <MarketTrades
          marketTrades={this.props.marketTrades}
          organizationTrades={this.props.organizationTrades}
          useAccountId={userAccountId}
        />
      );
    } 
    else if (componentId === "UserOrders")
    {
      return (
        <UserOrders
          organizationOrders={this.props.organizationOrders}
        />
      );
    }     
    else if (componentId === "OrderForm")
    {
      const {type, data, lastGridClick} = this.state;
      const now = new Date();
      const {marketControl, submitOrder, modifyOrder, withdrawOrder, userAccountId, tradeOnBehalf, tradeAllowed} = this.props;      
      return <OrderForm 
                marketControl={marketControl}
                type={type ?? "Bid"} 
                data={data ?? {}} 
                submitOrder={submitOrder} 
                lastGridClick={lastGridClick ?? now} 
                modifyOrder={modifyOrder} 
                withdrawOrder={withdrawOrder}
                useAccountId={userAccountId} 
                tradeOnBehalf={tradeOnBehalf} 
                tradeAllowed={tradeAllowed}
              />;
    }         
    else
    {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["auth"])(TradingLayout);
