import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_ROLE_API+"role/";

export async function getRoles() {
  const {data: Roles} = await http.get(apiEndpoint);
  return Roles;
}

export async function getAssignableRoles() {
  const {data: Roles} = await http.get(apiEndpoint+"assignable");
  return Roles;
}

export async function getParentRoles() {
  const {data: Roles} = await http.get(apiEndpoint+"parents");
  return Roles;
}

export async function getChildRoles() {
  const {data: Roles} = await http.get(apiEndpoint+"children");
  return Roles;
}

export async function getRole(RoleId) {
  const {data: Role} = await http.get(apiEndpoint+RoleId);
  return Role;
}

export async function addRole(role) {  
  const {data: Role} = await http.post(apiEndpoint, role);
  return Role;
}

export async function updateRole(role) {
  const {data: Role} = await http.put(apiEndpoint, role);
  return Role;
}

export async function deleteRole(RoleId) {
  return await http.delete(apiEndpoint+RoleId);
}

export async function getChildren(roleId) {
  const {data: RoleHistory} = await http.get(apiEndpoint+"children/"+roleId);
  return RoleHistory;
}

export async function assignRole(roleId, role) {  
  await http.post(apiEndpoint+"children/"+roleId, role);
}

export async function revokeRole(roleId, role) {
  await http.delete(apiEndpoint+"children/"+roleId, {data: role});
}


export async function getRoleHistory(RoleId) {
  const {data: RoleHistory} = await http.get(apiEndpoint+"audit/"+RoleId);
  return RoleHistory;
}
