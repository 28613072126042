import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import _ from "lodash";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  valueProperty,
  groupingProperty,
  onRowClick,
  onMouseEnter,
  onMouseLeave,
  classProperty,
  ...rest
}) => {
  const groupingValues = _.uniqBy(
    data ? data.filter((obj) => obj[groupingProperty]) : [],
    groupingProperty
  ).map((obj) => obj[groupingProperty]);
  const ungroupedData = data
    ? data.filter((d) => !groupingValues.includes(d[groupingProperty]))
    : [];

  return (
    <table className="centered-table" {...rest}>
      <TableHeader
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
        headerClassName="centered-header"
      />

      {groupingValues.map((x) => (
        <TableBody
          key={x}
          data={data.filter((d) => d[groupingProperty] === x)}
          cellClassName="centered-cell"
          columns={columns}
          classProperty={classProperty}
          onRowClick={onRowClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          valueProperty={valueProperty}
        />
      ))}
      {ungroupedData.length > 0 && (
        <TableBody
          data={ungroupedData}
          cellClassName="centered-cell"
          columns={columns}
          classProperty={classProperty}
          onRowClick={onRowClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          valueProperty={valueProperty}
        />
      )}
    </table>
  );
};

export default Table;
