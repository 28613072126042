import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"markGeneration/";

export async function generateAlbertaEnviromentalSettlements(generateMarksMetadata) {  
  const {data: generatedMarks} = await http.post(apiEndpoint+"generatealbertaenviromentalsettlements", generateMarksMetadata);
  return generatedMarks;
}

export async function copyPriorMarks(generateMarksMetadata) {  
  const {data: generatedMarks} = await http.post(apiEndpoint+"copypriormarks", generateMarksMetadata);
  return generatedMarks;
}
