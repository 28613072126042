import React from 'react';

const EnvironmentIndicator = () => {

const environment = process.env.REACT_APP_ENVIRONMENT;

    return (
        <div className={"environment-indicator-container " + environment}>
            <div className="environment-indicator">
            {window.location.origin.replace(/(^\w+:|^)\/\//, '')}
            </div>
        </div>
    );
};

export default EnvironmentIndicator