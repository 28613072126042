import React, {useEffect, useState, useCallback } from 'react';
import {  HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { usePermitted } from "./../common/components/permissions/permissions";
import Loading from "./../common/components/loading/loading";
import TradingLayout from './tradingLayout';

const TradingComponent = () => {

    const { keycloak, initialized } = useKeycloak();
    const [connection, setConnection] = useState(null);
    const [marketControl, setMarketControl] = useState([]);
    const [marketOrders, setMarketOrders] = useState([]);
    const [marketTrades, setMarketTrades] = useState([]);
    const [organizationOrders, setOrganizationOrders] = useState([]);
    const [organizationTrades, setOrganizationTrades] = useState([]);

    const userAccountId = initialized && keycloak.tokenParsed.OriginatingUserAccountID;
    const tradeOnBehalf = usePermitted(["operator", "administrator"]);
    const tradeAllowed = usePermitted(["manage-orders","operator", "administrator"]);

    const messageHub = process.env.REACT_APP_TRADING_ENGINE+"hub/trade";

    const connect = useCallback(() => {
        //Fetch data and Connect
        const connect = async () => {
            try {
                //initiate a connection
                const connection = new HubConnectionBuilder()
                    //.withUrl("https://localhost:7247/hub/message", {
                    .withUrl(messageHub, {
                        accessTokenFactory: () => keycloak.token,
                    })
                    .configureLogging(LogLevel.Information)
                    .withAutomaticReconnect()
                    .build();

                //set up handlers

                //Toast on new user login
                connection.on("UserAccountConnected", (user)  => {
                    toast.info("User Connected: "+user.login);
                });            

                connection.on("MarketControl", (marketControl)  => {
                    setMarketControl(marketControl);
                });

                //Populate initial market state
                connection.on("MarketOrders", (marketOrders)  => {
                    //console.log("Market Orders:",marketOrders);                
                    setMarketOrders(marketOrders);
                });


                connection.on("MarketTrades", (marketTrades)  => {
                    //console.log("Market Trades:",marketTrades);                
                    setMarketTrades(marketTrades);
                });            

                connection.on("OrganizationOrders", (organizationOrders)  => {
                    setOrganizationOrders(organizationOrders);               
                });

                connection.on("OrganizationTrades", (organizationTrades)  => {
                    //console.log("Organization Trades:",organizationTrades);                
                    setOrganizationTrades(organizationTrades);
                });  

                //Handle new market orders
                connection.on("Order", (order)  => {
                    processOrder(order);
                });

                //Handle new trades
                connection.on("Trade", (trade)  => {
                    processTrade(trade);
                });

                //Handle new user orders
                connection.on("OrganizationOrder", (order)  => {
                    processOrganizationOrder(order);
                });

                connection.on("OrganizationTrade", (trade)  => {       
                    processOrganizationTrade(trade);
                }); 

                connection.on("OrderConfirm", (orderConfirm)  => {
                    if (orderConfirm.orderConfirmStatus === "Accepted")
                    {
                        toast.success("Order Successfully Submitted: "+orderConfirm.orderConfirmReason);
                    }
                    else if (orderConfirm.orderConfirmStatus === "Rejected")
                    {
                        toast.error("Order Submission Failed: "+orderConfirm.orderConfirmReason);
                    }
                    else
                    {
                        toast.warning("Unknown Order Confirm Status: "+orderConfirm.orderConfirmStatus+" - "+orderConfirm.orderConfirmReason);
                    }                                
                }); 

                connection.start()
                .then(() => {
                    setConnection(connection);
                })
                .catch(console.error);;

            }
            catch (e)
            {
                console.log(e)
            } 
        };
            
        connect()
            .catch(console.error);                 
    },[keycloak, messageHub] )

    useEffect(() => {
        if (!connection) {
            connect();
        }
    
        return () => {
            // Cleanup function to stop the SignalR connection on unmount
            if (connection) {
                connection.stop()
                    .then(() => console.log("SignalR connection closed."))
                    .catch((error) => console.error("Error stopping trading engine connection:", error));
            }
        };
    }, [connection, keycloak.token, messageHub, connect]);

    const processOrder = (order) =>
    {
        setMarketOrders( prevMarketOrders => {

            let orders = [...prevMarketOrders];
            const index = orders.findIndex(o => o.orderId === order.orderId);

            if (index >= 0)
            {
                if (order.orderState !== "Traded" && order.orderState !== "Withdrawn")
                {
                    orders[index] = order; //Update the order
                }
                else
                {
                    orders.splice(index,1); //Remove the order
                }
            }
            else
            {
                //Only add if it's a live order
                if (order.orderState !== "Traded" && order.orderState !== "Withdrawn")
                {
                    orders.push(order);
                }
            }

            //setMarketOrders(orders);

            //console.log("Order:",order);
            //toast.info("Order: "+order.orderId);
            return orders;
        });
    }

    const processTrade = (trade) =>
    {
        setMarketTrades( prevMarketTrades => {
            let trades = [...prevMarketTrades];
            const index = trades.findIndex(t=> t.tradeId === trade.tradeId);
            if (index >= 0)
            {
                trades[index] = trade;
            }
            else
            {
                trades.push(trade);
            }

            //setMarketTrades(trades);           
        
            //console.log("Trade:",trade);
            //toast.info("Trade: "+trade.tradeId);

            return trades;
        });
    }

    const processOrganizationTrade = (trade) =>
    {
        setOrganizationTrades( prevOrganizationTrades => {
            let trades = [...prevOrganizationTrades];
            const index = trades.findIndex(t=> t.tradeId === trade.tradeId);
            if (index >= 0)
            {
                trades[index] = trade;
            }
            else
            {
                trades.push(trade);

                if(trade.OrderDirection === "Buy")
                {
                    toast.info("Your organization's bid traded:"+trade.buyerOrderId);
                }
                if("Else")
                {
                    toast.info("Your organization's offer traded:"+trade.sellerOrderId);
                }
            }

            //setMarketTrades(trades);           
        
            //console.log("Trade:",trade);
            //toast.info("Trade: "+trade.tradeId);

            return trades;
        });
    }

    const processOrganizationOrder = (order) =>
    {
        setOrganizationOrders( prevOrganizationOrders => {           
            let orders = [...prevOrganizationOrders];
            const index = orders.findIndex(o => o.orderId === order.orderId);


                if (index >= 0)
                {
                    if (order.orderState !== "Traded" && order.orderState !== "Withdrawn")
                    {
                        orders[index] = order; //Update the order
                    }
                    else
                    {
                        orders.splice(index,1); //Remove the order
                    }
                }
                else
                {
                    
                    //Only add if it's a live order
                    if (order.orderState !== "Traded" && order.orderState !== "Withdrawn")
                    {
                        orders.push(order);
                    }
                }

            //setOrganizationOrders(orders);

            //console.log("My Order:",order);
            //toast.info("My Order: "+order.orderId+" "+order.orderState);

            return orders;
        });
    }

    const submitOrder = async (order) => {
        if (connection) {
            try {
                await connection.invoke("SubmitOrder", order);
            } catch (error) {
                console.error(`Error invoking SubmitOrder method:`, error);
                toast.error(error.message.substr(error.message.indexOf("Exception: ")+11));
            }
        } else {
            console.error('SignalR connection not established yet.');
        }
    };


    const modifyOrder = async (orderData) => {
        console.log("Modify Order called", orderData);

        if (connection) {
            try {
                await connection.invoke("ModifyOrder", orderData);
            } catch (error) {
                console.error(`Error invoking ModifyOrder method:`, error);
                toast.error(error.message.substr(error.message.indexOf("Exception: ")+11));          
            }
        } else {
            console.error('SignalR connection not established yet.');
        }      
    };

    const withdrawOrder = async (orderData) => {
        console.log("Withdraw Order called", orderData);
        
        if (connection) {
            try {
                await connection.invoke("WithdrawOrder", orderData);
            } catch (error) {
                console.error(`Error invoking WithdrawOrder method:`, error);
                toast.error(error.message.substr(error.message.indexOf("Exception: ")+11));       
            }
        } else {
            console.error('SignalR connection not established yet.');
        }      
    };

    const pickOrder = async (orderData) => {
        console.log("Pick Order called", orderData);

        if (connection) {
            try {
                await connection.invoke("PickOrder", orderData);
            } catch (error) {
                console.error(`Error invoking PickOrder method:`, error);
                toast.error(error.message.substr(error.message.indexOf("Exception: ")+11));           
            }
        } else {
            console.error('SignalR connection not established yet.');
        }           
    };

    if(!connection || !marketControl.length > 0)
    {
        return <Loading/>
    }
    else
    {
        return (
            <div style={{height:"1000px"}}>         
            <TradingLayout marketControl={marketControl} marketOrders={marketOrders} marketTrades={marketTrades} organizationOrders={organizationOrders} organizationTrades={organizationTrades} userAccountId={userAccountId} tradeOnBehalf={tradeOnBehalf} tradeAllowed={tradeAllowed} submitOrder={submitOrder} modifyOrder={modifyOrder} withdrawOrder={withdrawOrder} pickOrder={pickOrder}/>
            </div>
        );
    }
};

export default TradingComponent