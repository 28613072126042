import React, {Component} from "react";
import * as FlexLayout from "flexlayout-react";
import HistoricalMarketDataHeader from "./historicalMarketDataHeader";
import MarketSelector from "./../marketSelector";
import { withTranslation } from 'react-i18next';

class HistoricalMarketData extends Component {  
  LAYOUT ={
    global: {
      minWidth: 100,
      minHeight: 100
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 15,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Markets"),
              enableClose: false,
              component: "MarketSelector"
            }
          ]
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 85,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
          ]
        }
      ]
    }
  };


  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT)
  };

  handleMarketSelect = (market) => {
    const {productId, marketId, name} = market;

    try{


      this.state.model.doAction(FlexLayout.Actions.addNode(
        {type:"tab", component:"HistoricalMarketDataHeader", name:`${name}`, id:`${marketId}`, config:{productId, market}},
        "1", FlexLayout.DockLocation.CENTER, 0, true));
    }
    catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(marketId));
    }

  }

  handleTabRename= (nodeId, name) =>
  {
    this.state.model.doAction(FlexLayout.Actions.updateNodeAttributes(nodeId, {name:name}));
  }

  factory = node => {
    const componentId = node.getComponent();
    //const MarkId = node.getId();
        
    if (componentId === "MarketSelector") {
      return <MarketSelector
                onClick={this.handleMarketSelect}
                userAccountId={this.props.userAccountId}
                userOrganizations={this.props.userOrganizations}
                bypassSubscriptions={this.props.bypassSubscriptions}
                contributeOnly={false}
                openDefaultTab={true}
                marketsWithAttributes={this.props.marketsWithAttributes} 
                marketGroupMarkets={this.props.marketGroupMarkets}
                products={this.props.products}
                userAccountSubscriptionsByUserAccount={this.props.userAccountSubscriptionsByUserAccount}                
              />;
    } 
    else if (componentId === "HistoricalMarketDataHeader") {
      const {productId, market} = node.getConfig();      
      return <HistoricalMarketDataHeader productId={productId} market = {market} userAccountId= {this.props.userAccountId} userOrganizations={this.props.userOrganizations} onRename={this.handleTabRename} />
    }
    else if (componentId === "Placeholder") {
      return <div></div>
    }
    else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["marketdata"])(HistoricalMarketData);
