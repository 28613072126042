import React, { Component } from 'react';
import uuid from'react-uuid';
import { toast } from "react-toastify";
import { getChildRoles} from "../../../common/services/auth/roleService";
import { getChildren, assignRole, revokeRole } from "../../../common/services/auth/roleService";
import Loading from '../../../common/components/loading/loading';
import DualListBox from '../../../common/components/form/dualListBox';
import { withTranslation } from 'react-i18next';

class ChildRoles extends Component {

    state = { 
        options: [],
        selected: [],        
        isLoading: true
     } 

     async componentDidMount() {
        const {roleId} = this.props;
        const allRoles = await getChildRoles();
        const roles = await getChildren(roleId);

        this.setState(
            {   
                options: this.mapToOptionsViewModel(allRoles)
                , selected: this.mapToSelectedViewModel(roles)
                , isLoading: false
            });       
    };

    mapToOptionsViewModel(roles) {
        return roles.map(role => (
            {
                value: role.roleId,
                label: role.name
            })
         );
    }

    mapToSelectedViewModel(roles) {
        return roles.map(role => (
                role.roleId
            )
        );
    }

    async handleChange(selected, selection, controlKey)
    {      
        const {roleId} = this.props;
        const eventId = uuid();

        if(controlKey !== 'available'  && controlKey !== 'selected')
        {
            toast.warning(this.props.t("UnexpectedError"));
            return null;
        }

        // set the initial state,will clean up at the end if there are failures.
        this.setState({selected: selected});

        for (const childRoleId of selection)
        {
            if(controlKey==="available")
            {
                try
                {
                    await assignRole(roleId, {childRoleId: childRoleId, eventId: eventId})
                    toast.success(this.props.t("ChildRoleAdded"));
                }
                catch (ex)
                {
                    console.log(ex);
                    var index = selected.indexOf(childRoleId);
                    delete selected[index];
                    toast.warning(this.props.t("ChildRoleAdditionFailed"));
                }
            }
            else if (controlKey==="selected")
            {
                try
                {
                    await revokeRole(roleId, {childRoleId: childRoleId, eventId: eventId})
                    toast.success(this.props.t("ChildRoleRemoved"));
                }
                catch (ex)
                {
                    console.log(ex);
                    selected.push(childRoleId);
                    toast.warning(this.props.t("ChildRoleRemovalFailed"));
                }
            }
        }

        //Clean up with the actual outcome state
        this.setState({selected: selected});
    };

    render() { 
        const {options, selected, isLoading} = this.state;
        const {allowEdit} = this.props;
        
        if (isLoading) return (<Loading/>);

        return (
            <div>
                <DualListBox canFilter
                    className = "rdl-hide-move-all"
                    disabled= {!allowEdit}
                    options={options}
                    selected={selected}
                    onChange={(selected, selection, controlKey)=>this.handleChange(selected, selection, controlKey)}
                />
            </div>    
        );
    }
}
 
export default withTranslation(["auth"])(ChildRoles);