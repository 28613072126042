import React from 'react';

const Input = ({name, label, error, enabled, ...rest}) => {
    return (  
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <input 
                {...rest}
                id={name}
                name={name}
                className="form-control"            
            />
            {error && <div className="p-1 alert alert-danger">{error}</div>}
        </div>
    );
}
 
export default Input;