import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { withTranslation } from "react-i18next";

class ReviewMarksToggle extends Component {
  render() {
    const {
      t,
      markId,
      markState,
      handleApprove,
      handleReject,
      canApproveMarks,
    } = this.props;

    return (
      canApproveMarks && (
        <ButtonGroup
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="btn-group-toggle"
          data-toggle="buttons"
          aria-label="Basic example"
        >
          <Button
            onClick={() => handleReject([markId])}
            disabled={markState === "Rejected"}
            variant={
              markState === "Rejected"
                ? "danger"
                : markState === "Submitted"
                ? "secondary"
                : " dark"
            }
          >
            {markState === "Rejected"
              ? t("Rejected")
              : t("Reject")}
          </Button>
          <Button
            onClick={() => handleApprove([markId])}
            disabled={markState === "Approved"}
            variant={
              markState === "Approved"
                ? "success"
                : markState === "Submitted"
                ? "secondary"
                : " dark"
            }
          >
            {markState === "Approved"
              ? t("Approved")
              : t("Approve")}
          </Button>
        </ButtonGroup>
      )
    );
  }
}

export default withTranslation(["marketdata"])(ReviewMarksToggle);
