import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../common/components/table/pagination";
import { getRegistry } from "../../common/services/albertaEnvironmental/albertaEnvironmentalService";
import SearchBox from "../../common/components/search/searchBox";
import { formatDate } from "../../common/services/utilities";
import { withTranslation } from "react-i18next";
import AlbertaEnvironmentalRegistryTable from "./albertaEnvironmentalRegistryTable";
import Input from "../../common/components/form/input";
import Select from "../../common/components/form/select";
import * as XLSX from 'xlsx';
import FontAwesome from "react-fontawesome";
import LifecycleChart from './lifecycleChart';
import PieChart from './pieChart';

class AlbertaEnvironmentalRegistry extends Component {

  state = {
    registry: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    aeor: true,
    epc: true,
    registryDate: "",
    minDate: formatDate(new Date('2023-09-01')), // Hard coded, earliest date that we started scraping
    maxDate: formatDate(new Date()),
    quantificationProtocols: [],
    quantificationProtocol: "All",
    vintages: [],
    vintage: "All",    
    statuses: [],
    status: "All",
    sortColumn: { path: "TransactionId", order: "desc" },
    isLoading: true,
    reloading: false,
  };

  async componentDidMount() {
    await this.loadData();
  }

  async loadData(registryDate=null)
  {
    const registry = await getRegistry(registryDate);

    const quantificationProtocols = [{quantificationProtocol: "All"}].concat([ ...new Set(registry.filter(x => x.QuantificationProtocol).map(x => x.QuantificationProtocol))].sort().map(x => ({quantificationProtocol: x})));
    const statuses = [{status: "All"}].concat([ ...new Set(registry.filter(x => x.Status).map(x => x.Status))].sort().map(x => ({status: x})));
    const vintages = [{vintage: "All"}].concat([ ...new Set(registry.filter(x => x.Vintage).map(x => x.Vintage))].sort().map(x => ({vintage: x})));

    this.setState({registry: registry, isLoading: false, reloading: false, quantificationProtocols: quantificationProtocols, statuses: statuses, vintages: vintages });
  }

  handleRegistryDateChange = (registryDate) => {
    this.setState({registryDate: registryDate, reloading: true});

    (async () => {
      await this.loadData(registryDate);
    })();
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleQuantificationProtocolChange = (quantificationProtocol) => {
    this.setState({ quantificationProtocol: quantificationProtocol, currentPage: 1  });
  };

  handleVintageChange = (vintage) => {
    this.setState({ vintage: vintage, currentPage: 1  });
  };

  handleStatusChange = (status) => {
    this.setState({ status: status, currentPage: 1  });
  };

  handleToggleAeor = () => {
    this.setState({ aeor: !this.state.aeor, currentPage: 1  });
  };

  handleToggleEpc = () => {
    this.setState({ epc: !this.state.epc, currentPage: 1  });
  };

  downloadExcel = (data) => {

    const excelData = data.map((record) => (
      {
        ProjectIdentifier: record.ProjectIdentifier,
        CurrentOwner: record.CurrentOwner,
        EmissionOffsetProjectDeveloper: record.EmissionOffsetProjectDeveloper,
        Quantity: record.Quantity,
        Registry: record.Registry,
        Vintage: record.Vintage,                
        QuantificationProtocol: record.QuantificationProtocol,
        ActivityStart: record.ActivityStart ? formatDate(record.ActivityStart) : null,
        ComplianceYear: record.ComplianceYear,
        ExpiryDate: record.ExpiryDate ? formatDate(record.ExpiryDate) : null,
        Facility: record.Facility,
        OffsetStartDate: record.OffsetStartDate ? formatDate(record.OffsetStartDate) : null,
        OffsetEndDate: record.OffsetEndDate ? formatDate(record.OffsetEndDate) : null,
        Status: record.Status,
        Title: record.Title,
        VerifierCompany: record.VerifierCompany,
        SerialStart: record.SerialStart,
        SerialEnd: record.SerialEnd,
        TransactionId: record.TransactionId,
      }
  ));


    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "AlbertaEnvironmentalRegistry.xlsx");
  };

  getFilteredData = () => {
    const {
      searchQuery,
      sortColumn,
      epc,
      aeor,
      // startDate,
      // endDate,
      quantificationProtocol,
      vintage,      
      status,
      registry: allRegistry,
    } = this.state;

    let filtered = allRegistry;

    if (!epc) filtered = filtered.filter((u) => u.Registry !== "Emission Performance Credit");
    if (!aeor) filtered = filtered.filter((u) => u.Registry !== "AEOR");

    // filtered = filtered.filter(
    //   (u) =>          
    //       u.OffsetEndDate >= startDate
    //       && u.OffsetStartDate <= endDate
    // );

    if(quantificationProtocol !== "All")
    {
      filtered = filtered.filter(
        (u) => u.QuantificationProtocol === quantificationProtocol ?? u.QuantificationProtocol
      )
    }

    if(status !== "All")
    {
      filtered = filtered.filter(
        (u) => u.Status === status ?? u.Status
      )
    }

    if(vintage !== "All")
    {
      filtered = filtered.filter(
        (u) => u.Vintage === parseInt(vintage) ?? u.Vintage
      )
    }

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (
            u.CurrentOwner !== null &&          
            u.CurrentOwner.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.Title !== null &&
            u.Title.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.EmissionOffsetProjectDeveloper !== null &&
            u.EmissionOffsetProjectDeveloper.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.Facility !== null &&
            u.Facility.toLowerCase().includes(searchQuery.toLowerCase())
          ) ||
          (
            u.ProjectIdentifier !== null &&
            u.ProjectIdentifier.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

      const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

      return { data: sorted };
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
    } = this.state;

    const {data} = this.getFilteredData();
    const registry = paginate(data, currentPage, pageSize);

    return { totalCount: data.length, data: registry, allData: data };
  };

  render() {
    const {      
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      aeor,
      epc,
      registry: unfilteredRegistry,
      registryDate,
      minDate,
      maxDate,
      quantificationProtocols,
      quantificationProtocol,
      vintages,
      vintage,      
      statuses,
      status,
      isLoading,
      reloading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, isSubscriber } = this.props;

    const { totalCount, data: registry, allData: allRegistry } = this.getPagedData();

    const uniqueAEORStatusValues = ['Retired', 'Retired for Compliance', 'Pending Retirement', 'Pending Retirement Compliance CSA Approval', 'Pending Retirement Federal OBPS CSA Approval', 'Pending Retire - Federal OBPS', 'Active', 'Removed', 'Revoked', 'Cancelled', 'Locked In Transaction', 'Retire - Federal OBPS'];
    const AEORColorScheme = ['grey', 'darkgrey', 'orange', 'darkorange', 'goldenrod', 'gold', 'darkgreen', 'black', 'red', 'darkred', 'green', 'darkblue'];
    const AEORVintages = Array.from(new Set(unfilteredRegistry.filter((u) => u.Registry === "AEOR").map(item => item.Vintage))).sort((a, b) => a - b);
    const AEORData = allRegistry.filter((u) => u.Registry === "AEOR")

    const uniqueEPCStatusValues =  ['Retired', 'Retired for Compliance', 'Pending Retirement Compliance CSA Approval', 'Pending Retirement Voluntary CSA Approval', 'Pending Retirement', 'Active', 'Cancelled', 'Locked In Transaction'];
    const EPCColorScheme = [
                              'grey' //retired
                              , 'darkgrey' //Retired for Compliance
                              , 'goldenrod' //Pending Retirement Compliance CSA Approval
                              , 'darkorange' //Pending Retirement Voluntary CSA Approval
                              , 'orange' //Pending Retirement                
                              , 'darkgreen' //Active
                              , 'darkred' //Cancelled
                              , 'green' //Locked In Transaciton
                          ];
    const EPCVintages = Array.from(new Set(unfilteredRegistry.filter((u) => u.Registry === "Emission Performance Credit").map(item => item.Vintage))).sort((a, b) => a - b);
    const EPCData = allRegistry.filter((u) => u.Registry === "Emission Performance Credit")

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex align-items-center justify-content-center"><h1>{t("AlbertaEnvironmentalRegistry")}</h1></div>
          <div className="row">
            <div className="col-md-2" style={{alignSelf:"flex-end"}}>
              <SearchBox
                value={searchQuery}
                onChange={this.handleSearch} 
                disabled = {!isSubscriber}       
              />
            </div>
            <div className="col-md-2">
              <Input                    
                  data-bs-theme="dark" 
                  type="date"
                  min={minDate} 
                  max={maxDate} 
                  onKeyDown={(e) => e.preventDefault()}
                  onFocus={(e) => e.blur()}
                  onChange={e => this.handleRegistryDateChange(e.currentTarget.value)}
                  value = {registryDate}
                  label = {t("RegistryDate")}
                  disabled = {!isSubscriber}                  
              />                
            </div>           
            <div className='col-md-2'>
              <Select
                  data-bs-theme="dark" 
                  idField = "status"
                  optionField = "status"
                  name = "status"
                  //disabled={Object.keys(organizations).length < 2}
                  value = {status}
                  label = {t("Status")}
                  options={statuses}
                  onChange={e => this.handleStatusChange(e.currentTarget.value)}
                  disabled = {!isSubscriber}                  
              />        
            </div>       
            <div className='col-md-1'>
              <Select
                  data-bs-theme="dark" 
                  idField = "vintage"
                  optionField = "vintage"
                  name = "vintage"
                  //disabled={Object.keys(organizations).length < 2}
                  value = {vintage}
                  label = {t("Vintage")}
                  options={vintages}
                  onChange={e => this.handleVintageChange(e.currentTarget.value)}
                  disabled = {!isSubscriber}                  
              />        
            </div>    
            <div className="col-md-1" style={{alignSelf:"flex-end"}}>
              <Form.Switch
                className="switch"
                type="switch"
                label={t("Aeor")}
                checked={aeor}
                value={aeor}
                onChange={this.handleToggleAeor}
                disabled = {!isSubscriber}                
              />
            </div>
            <div className="col-md-1" style={{alignSelf:"flex-end"}}>
              <Form.Switch
                className="switch"
                type="switch"
                label={t("Epc")}
                checked={epc}
                value={epc}
                onChange={this.handleToggleEpc}
                disabled = {!isSubscriber}                
              />
            </div>     
            
            <div className='col-md-2'>
              <Select
                  data-bs-theme="dark" 
                  idField = "quantificationProtocol"
                  optionField = "quantificationProtocol"
                  name = "quantificationProtocol"
                  //disabled={Object.keys(organizations).length < 2}
                  value = {quantificationProtocol}
                  label = {t("QuantificationProtocol")}
                  options={quantificationProtocols}
                  onChange={e => this.handleQuantificationProtocolChange(e.currentTarget.value)}
                  disabled = {!isSubscriber}                  
              />        
            </div>        
            <div className='col-md-1'  style={{alignSelf:"flex-end"}}>    
              <button
                className="btn btn-sm btn-secondary"
                onClick={()=>this.downloadExcel(allRegistry)}
                disabled = {!isSubscriber}         
              >
                <FontAwesome  name="download"/>
                {" "}
                {t("Excel")}
              </button>
            </div>           
          </div>
        </div>        
        <div className="row">
          <div className="col-md-2">
            <p>{t("RetrievedAlbertaEnvironmentalRegistry", { count: totalCount })}</p>
          </div>
          {!isSubscriber &&
            <div className="col-md-8 d-flex align-items-center justify-content-center" style={{fontStyle: "italic", color: "darkred"}}>
              <p>{t("SubscriptionInquiry")}{" "}<a href={"mailto:"+contactEmail}>{contactEmail}</a></p>
            </div>        
          }
        </div>        
        {!reloading && 
          <>
            <div className="row">
              <div className="col-md-3 market-data-tile">
                <PieChart 
                      data={AEORData} 
                      title="Offsets by Owner" 
                      groupBy="CurrentOwner" 
                />     
              </div>
              <div className="col-md-3 market-data-tile">
                <PieChart 
                        data={AEORData} 
                        title="Offsets by Developer" 
                        groupBy="EmissionOffsetProjectDeveloper" 
                  />                
              </div>      
              <div className="col-md-3 market-data-tile">
                <PieChart 
                      data={AEORData} 
                      title="Offsets by Protocol" 
                      groupBy="QuantificationProtocol" 
                />            
              </div>      
              <div className="col-md-3 market-data-tile">
                <PieChart 
                        data={EPCData} 
                        title="EPCs By Owner" 
                        groupBy="CurrentOwner" 
                  />                
              </div>      
            </div>         
            <div className="row">
              <div className="col-md-6 market-data-tile">
                <LifecycleChart 
                    data={AEORData} 
                    title="AEOR Status by Vintage" 
                    uniqueStatusValues={uniqueAEORStatusValues} 
                    colorScheme={AEORColorScheme} 
                    vintages={AEORVintages}
                />
                </div>
                <div className="col-md-6 market-data-tile">
                <LifecycleChart 
                    data={EPCData} 
                    title="EPC Status by Vintage" 
                    uniqueStatusValues={uniqueEPCStatusValues} 
                    colorScheme={EPCColorScheme} 
                    vintages={EPCVintages}                
                />
                </div>      
            </div>        
            <AlbertaEnvironmentalRegistryTable
              registry={registry}
              sortColumn={sortColumn}
              onSort={this.handleSort}
              //onRowClick={this.handleTradeSelected}
            />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}        
            />
          </>
        }
        {
          reloading && <Loading />
        }
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalRegistry);
