import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_USER_ACCOUNT_API+"useraccountorganization/";

export async function getOrganizationsByUser(userAccountId) {
    const {data: organizations} = await http.get(apiEndpoint+userAccountId);
    return organizations;
}

export async function getUserAccountsByOrganization(organizationId) {
  const {data: organizations} = await http.get(apiEndpoint+"GetUsersByOrganization/"+organizationId);
  return organizations;
}

export async function assignOrganization(userAccountId, organization) {  
  await http.post(apiEndpoint+userAccountId, organization);
}

export async function revokeOrganization(userAccountId, organization) {
  await http.delete(apiEndpoint+userAccountId, {data:organization});
}

export async function getUserAccountOrganizationHistory(userAccountId) {
  const {data: userAccountHistory} = await http.get(apiEndpoint+"audit/"+userAccountId);
  return userAccountHistory;
}
