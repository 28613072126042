import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_TRADING_ENGINE+"marketstate/";

export async function getMarketControl() {
  const {data: marketControl} = await http.get(apiEndpoint);
  return marketControl;
}

export async function getMarketControlByMarket(marketId) {
  const {data: marketControl} = await http.get(apiEndpoint+marketId);
  return marketControl;
}

export async function insertOrUpdateMarketControl(marketControlInput) {
  const {data: marketControl} = await http.post(apiEndpoint, marketControlInput);
  return marketControl;
}

export async function getMarketControlHistory(marketId) {
  const {data: marketControlHistory} = await http.get(apiEndpoint+"audit/"+marketId);
  return marketControlHistory;
}

export async function openMarkets() {  
  await http.post(apiEndpoint+"OpenMarkets");
}

export async function closeMarkets() {  
  await http.post(apiEndpoint+"CloseMarkets");
}

export async function haltMarket(marketId) {  
  await http.post(apiEndpoint+"Halt/"+marketId);
}

export async function resumeMarket(marketId) {  
  await http.post(apiEndpoint+"Resume/"+marketId);
}