import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"product/";

export async function getProducts() {
  const {data: Products} = await http.get(apiEndpoint);
  return Products;
}

export async function getProduct(productId) {
  const {data: Product} = await http.get(apiEndpoint+productId);
  return Product;
}

export async function getProductWithQualityAttributes(productId) {
  const {data: Product} = await http.get(apiEndpoint+"WithAttributes/"+productId);
  return Product;
}

export async function addProduct(product) {  
  const {data: Product} = await http.post(apiEndpoint, product);
  return Product;
}

export async function updateProduct(product) {
  const {data: Product} = await http.put(apiEndpoint, product);
  return Product;
}

export async function deleteProduct(productId) {
  return await http.delete(apiEndpoint+productId);
}

export async function getProductHistory(productId) {
  const {data: ProductHistory} = await http.get(apiEndpoint+"audit/"+productId);
  return ProductHistory;
}
