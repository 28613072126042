import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"venue/";

export async function getVenues() {
    const {data: Venues} = await http.get(apiEndpoint);
    return Venues;
}

export async function getVenue(venueId) {
  const {data: Venue} = await http.get(apiEndpoint+venueId);
  return Venue;
}

export async function addVenue(venue) {  
  const {data: Venue} = await http.post(apiEndpoint, venue);
  return Venue;
}

export async function updateVenue(venue) {
  const {data: Venue} = await http.put(apiEndpoint, venue);
  return Venue;
}

export async function deleteVenue(venueId) {
  return await http.delete(apiEndpoint+venueId);
}

export async function getVenueHistory(venueId) {
  const {data: VenueHistory} = await http.get(apiEndpoint+"audit/"+venueId);
  return VenueHistory;
}
