import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getUomHistory } from "../../../common/services/markets/uomService";
import { withTranslation } from "react-i18next";

class UomHistory extends Component {
  state = {
    uomHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { uomId } = this.props;
    const uomHistory = this.mapToViewModel(await getUomHistory(uomId));

    this.setState({
      uomHistory: _.orderBy(uomHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(uoms) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return uoms.map((uom) => ({
      id: uom.id,
      name: uom.name,
      code: uom.code,
      uomTypeName: uom.uomTypeName,
      active: uom.active.toString(),
      updatedByUserAccountId: uom.createdByUserAccountId,
      updatedBy:
        uom.updatedByUserAccountFirstName +
        " " +
        uom.updatedByUserAccountLastName,
      updatedDate: new Date(uom.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(uom.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: uom.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("UomName") },
    { path: "code", label: this.props.t("UomCode") },
    { path: "uomTypeName", label: this.props.t("UomType") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { uomHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, uomId } = this.props;

    return (
      <div className="history-table-container">
        {t("UomID")} {uomId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={uomHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(UomHistory);
