import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getUserAccountSubscriptions } from "../../../common/services/marketdata/userAccountSubscriptionService";
import SearchBox from "../../../common/components/search/searchBox";
import UserAccountSubscriptionsTable from "./userAccountSubscriptionsTable";
import { withTranslation } from "react-i18next";

class UserAccountSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.handleUserAccountSubscriptionUpdated =
      this.handleUserAccountSubscriptionUpdated.bind(this);
  }

  state = {
    userAccountSubscriptions: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {
    this.setState({
      userAccountSubscriptions: await getUserAccountSubscriptions(),
      isLoading: false,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleUserAccountSubscriptionSelected = (
    userAccountSubscription,
    isNew = false
  ) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(
      userAccountSubscription,
      this.handleUserAccountSubscriptionUpdated,
      isNew
    );
  };

  handleUserAccountSubscriptionUpdated = (userAccountSubscription) => {
    const userAccountSubscriptions = [...this.state.userAccountSubscriptions];
    const index = userAccountSubscriptions.findIndex(
      (u) =>
        u.userAccountSubscriptionId ===
        userAccountSubscription.userAccountSubscriptionId
    );
    if (index > -1) {
      userAccountSubscriptions.splice(index, 1);
    }
    userAccountSubscriptions.push(userAccountSubscription);
    this.setState({
      userAccountSubscriptions: userAccountSubscriptions,
    });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      userAccountSubscriptions: allUserAccountSubscriptions,
      activeOnly,
    } = this.state;

    let filtered = allUserAccountSubscriptions;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.login != null &&
            u.login.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (u.organizationSubscriptionName != null &&
            u.organizationSubscriptionName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const userAccountSubscriptions = paginate(sorted, currentPage, pageSize);

    return {
      totalCount: filtered.length,
      data: userAccountSubscriptions,
    };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit } = this.props;

    const { totalCount, data: userAccountSubscriptions } = this.getPagedData();

    const newUserAccountSubscription = {
      userAccountSubscriptionId: uuid(),
      name: t("New UserAccountSubscription"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() =>
                  this.handleUserAccountSubscriptionSelected(
                    newUserAccountSubscription,
                    true
                  )
                }
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddUserAccountSubscription")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedUserAccountSubscription", { count: totalCount })}</p>
        <UserAccountSubscriptionsTable
          userAccountSubscriptions={userAccountSubscriptions}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleUserAccountSubscriptionSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["auth"])(UserAccountSubscriptions);
