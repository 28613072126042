import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getUserAccountSubscriptionHistory } from "../../../common/services/marketdata/userAccountSubscriptionService";
import { withTranslation } from "react-i18next";

class UserAccountSubscriptionHistory extends Component {
  state = {
    userAccountSubscriptionHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { userAccountSubscriptionId } = this.props;
    const userAccountSubscriptionHistory = this.mapToViewModel(
      await getUserAccountSubscriptionHistory(userAccountSubscriptionId)
    );

    this.setState({
      userAccountSubscriptionHistory: _.orderBy(
        userAccountSubscriptionHistory,
        "id",
        "desc"
      ),
      isLoading: false,
    });
  }

  mapToViewModel(userAccountSubscriptions) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return userAccountSubscriptions.map((userAccountSubscription) => ({
      id: userAccountSubscription.id,
      login: userAccountSubscription.login,
      organizationSubscriptionName:
        userAccountSubscription.organizationSubscriptionName,
      contributor: userAccountSubscription.contributor.toString(),
      active: userAccountSubscription.active.toString(),
      updatedByUserAccountId: userAccountSubscription.updatedByUserAccountId,
      updatedBy:
        userAccountSubscription.updatedByUserAccountFirstName +
        " " +
        userAccountSubscription.updatedByUserAccountLastName,
      updatedDate: new Date(
        userAccountSubscription.updatedDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      writeDate: new Date(userAccountSubscription.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: userAccountSubscription.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "login", label: this.props.t("UserAccountName") },
    {
      path: "organizationSubscriptionName",
      label: this.props.t("OrganizationSubscription"),
    },
    { path: "contributor", label: this.props.t("Contributor") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { userAccountSubscriptionHistory, sortColumn, isLoading } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, userAccountSubscriptionId } = this.props;

    return (
      <div className="history-table-container">
        {t("UserAccountSubscriptionID")} {userAccountSubscriptionId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={userAccountSubscriptionHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["auth"])(UserAccountSubscriptionHistory);
