import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs, Tab } from "react-bootstrap";
import {
  getTermSchedule,
  addTermSchedule,
  updateTermSchedule,
} from "../../../common/services/markets/termScheduleService";
import { getPeriodicities } from "../../../common/services/markets/periodicityService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import TermScheduleHistory from "./termScheduleHistory";
import { withTranslation } from "react-i18next";

class TermSchedule extends Form {
  state = {
    data: {
      termScheduleId: "",
      name: "",
      description: "",
      forwardReportingPeriodicity: "",
      historicalReportingPeriodicity: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    periodicities: [],
    errors: {},
    isLoading: true,
    isNew: true,
  };

  schema = {
    termScheduleId: Joi.string(),
    name: Joi.string().required().label(this.props.t("TermScheduleName")),
    description: Joi.string()
      .required()
      .label(this.props.t("TermScheduleDescription")),
    forwardReportingPeriodicity: Joi.string()
      .required()
      .label(this.props.t("ForwardReportingPeriodicity")),
    historicalReportingPeriodicity: Joi.string()
      .required()
      .label(this.props.t("HistoricalReportingPeriodicity")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { termScheduleId, isNew } = this.props;
    this.setState({ isNew: isNew });
    var termSchedule = {};

    if (!isNew) {
      termSchedule = await getTermSchedule(termScheduleId);
      termSchedule.eventId = uuid();
    } //New User Add
    else {
      termSchedule = {
        termScheduleId: termScheduleId,
        name: "",
        description: "",
        forwardReportingPeriodicity: "",
        historicalReportingPeriodicity: "",
        active: true,
        eventId: uuid(),
      };
    }
    this.setState({
      data: this.mapToViewModel(termSchedule),
      isLoading: false,
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(termSchedule.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(termSchedule.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });

    this.setState({ periodicities: await getPeriodicities() });

    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(termSchedule) {
    return {
      termScheduleId: termSchedule.termScheduleId,
      name: termSchedule.name,
      description: termSchedule.description,
      forwardReportingPeriodicity: termSchedule.forwardReportingPeriodicity,
      historicalReportingPeriodicity:
        termSchedule.historicalReportingPeriodicity,
      active: termSchedule.active,
      eventId: termSchedule.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      let response = {};

      if (!isNew) {
        await updateTermSchedule(this.state.data);
        toast.success(t("TermScheduleUpdated"));
      } else {
        await addTermSchedule(this.state.data);
        this.setState({ isNew: false });
        onRename(this.state.data.termScheduleId, this.state.data.name);
        toast.success(t("TermScheduleAdded"));
      }

      //Call back to the select list to allow it to update.
      this.props.submitCallbackFunction(response);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;              
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, termScheduleId, allowEdit } = this.props;

    const periodicities = this.state.periodicities.map((x) => ({
      value: x,
      name: x,
    }));

    return (
      <div className="container-fluid">
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderInput("name", t("Name"))}
              {this.renderInput("description", t("Description"))}
              {this.renderSelect(
                "forwardReportingPeriodicity",
                t("ForwardReportingPeriodicity"),
                periodicities,
                "value",
                "name"
              )}
              {this.renderSelect(
                "historicalReportingPeriodicity",
                t("HistoricalReportingPeriodicity"),
                periodicities,
                "value",
                "name"
              )}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Tab eventKey={1} title={t("History")}>
                <TermScheduleHistory termScheduleId={termScheduleId} />
              </Tab>
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["markets"])(TermSchedule);
