import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../../common/components/table/table';
import Loading from '../../../common/components/loading/loading';
import { getRoleHistory} from "../../../common/services/auth/roleService";
import { withTranslation } from 'react-i18next';

class RoleHistory extends Component {

    state = {
        roleHistory: [],
        pageSize: 20,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true
   };

    async componentDidMount() {
        const {roleId} = this.props;
        const roleHistory = this.mapToViewModel(await getRoleHistory(roleId));

        this.setState({roleHistory: _.orderBy(roleHistory, "id", "desc"), isLoading:false});
    };

    mapToViewModel(roles) {
        const dateFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
            second: 'numeric'
        }

        return roles.map(role => (
            {
                id: role.id,
                name: role.name,
                description: role.description,
                active: role.active.toString(),
                updatedByUserAccountId: role.updatedByUserAccountId,
                updatedBy: role.updatedByUserAccountFirstName+" "+role.updatedByUserAccountLastName,
                updatedDate: new Date(role.updatedDate).toLocaleDateString('en-US', dateFormatOptions),
                writeDate: new Date(role.writeDate).toLocaleDateString('en-US', dateFormatOptions),
                eventId: role.eventId
            }
        ));
    }

    columns =[
        {path: 'id', label: this.props.t("RecordID")},
        {path: 'name', label: this.props.t("RoleName")},
        {path: 'description', label: this.props.t("RoleDescription")},
        {path: 'active', label: this.props.t("Active")},
        {path: 'updatedBy', label: this.props.t("UpdatedBy")},
        {path: 'updatedDate', label: this.props.t("UpdateDate")},
        {path: 'writeDate', label: this.props.t("WriteDate")},
        {path: 'eventId', label: this.props.t("EventID")}
    ];

    render() { 
        const {roleHistory, sortColumn, isLoading} = this.state;

        if (isLoading) return (<Loading/>);

        const {t, roleId} = this.props;

        return (
            <div className="history-table-container">
                {t("RoleID")}{roleId}
                <Table className="table-history" columns= {this.columns} sortColumn = {sortColumn} data={roleHistory} valueProperty="id"/>
            </div>
        );
    }
}

export default withTranslation(["auth"])(RoleHistory);