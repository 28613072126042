import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"instrument/";

export async function getInstruments() {
    const {data: instruments} = await http.get(apiEndpoint);
    return instruments;
}

export async function getInstrument(instrumentId) {
    const {data: instrument} = await http.get(apiEndpoint+instrumentId);
    return instrument;
}

export async function addInstrument(instrumentInput) {  
    const {data: instrument} = await http.post(apiEndpoint, instrumentInput);
    return instrument;
}
  
export async function getOrAddInstrument(instrumentInput) {  
    const {data: instrument} = await http.post(apiEndpoint+"GetOrAdd/", instrumentInput);
    return instrument;
}