import React from 'react';

const Select = ({name, label, options, error, idField, optionField, value, ...rest}) => {
   
    return (  
        <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select 
            {...rest}
            id={name}
            name={name}
            className="form-control"            
            value={value}
        >
            {(!value || !options.includes(x => x[idField === value])) && <option value="" /> }
            {options.map(option => (
                <option key={option[idField]} value={option[idField]}>
                    {option[optionField ?? "name"]}
                </option>
            ))}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
    );
}
 
export default Select;