import React, { Component } from "react";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import {
  getMarksForReview,
  approveMark,
  rejectMark,
} from "../../common/services/marketdata/markService";
import ReviewMarksTableHeader from "./reviewMarksTableHeader";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import Input from "../../common/components/form/input";
import uuid from "react-uuid";
import { formatDate } from "../../common/services/utilities";

class ReviewMarks extends Component {
  state = {
    marks: [],
    marketDate: null,
    isLoading: true,
  };

  async componentDidMount() {
    const date = new Date();
    const marketDate = formatDate(date);

    const marks = await getMarksForReview(marketDate);

    this.setState({
      marks: marks,
      isLoading: false,
      marketDate: marketDate,
    });
  }

  handleMarketDateChange = (marketDate) => {
    this.setState({ marketDate: marketDate });

    (async () => {
      const marks = await getMarksForReview(marketDate);
      this.setState({ marks: marks });
    })();
  };

  handleApprove = async (markIds) => {
    const { t } = this.props;

    try {
      const numOfMarksApproved = await approveMark({
        MarkIds: markIds,
        EventId: uuid(),
      });

      if (markIds.length === 1) {
        toast.success(t("MarkApproved"));
      } else {
        toast.success(t("MarksApproved", { count: numOfMarksApproved }));
      }
      //Update the State
      const marks = [...this.state.marks];

      for (const markId of markIds) {
        const index = marks.findIndex((mark) => mark.markId === markId);
        var updatedMark = { ...marks[index] };
        updatedMark.markState = "Approved";
        marks[index] = updatedMark;
      }

      this.setState({ marks: marks });
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        var errorKeys = Object.keys(ex.response.data.errors);
        errorKeys.forEach(function (key) {
          errorList[key].map((error) => {
            toast.error(t("ErrorSaving") + " " + key + ": " + error);
            return null;
          });
        });
      }
    }
  };

  handleReject = async (markIds) => {
    const { t } = this.props;

    try {
      const numOfMarksRejected = await rejectMark({
        MarkIds: markIds,
        EventId: uuid(),
      });

      if (numOfMarksRejected === 1) {
        toast.success(t("MarkRejected"));
      } else {
        toast.success(t("MarksRejected", { count: numOfMarksRejected }));
      }

      //Update the State
      const marks = [...this.state.marks];

      for (const markId of markIds) {
        const index = marks.findIndex((mark) => mark.markId === markId);
        var updatedMark = { ...marks[index] };
        updatedMark.markState = "Rejected";
        marks[index] = updatedMark;
      }

      this.setState({ marks: marks });
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        var errorKeys = Object.keys(ex.response.data.errors);
        errorKeys.forEach(function (key) {
          errorList[key].map((error) => {
            toast.error(t("ErrorSaving") + " " + key + ": " + error);
            return null;
          });
        });
      }
    }
  };

  render() {
    const { marks, marketDate, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, canApproveMarks } = this.props;

    var date = new Date();
    const endDate = formatDate(date);

    const groups = _.groupBy(marks, "marketName");

    return (
      <div className="container-fluid">
        <h3>{t("ReviewMarks")}</h3>
        <div className="row">
          <div className="col-md-2">
            <Input
              data-bs-theme="dark"
              type="date"
              max={endDate}
              onChange={(e) =>
                this.handleMarketDateChange(e.currentTarget.value)
              }
              //name = "name"
              value={marketDate}
              label={t("MarketDate")}
            />
          </div>
        </div>
        <Accordion data-bs-theme="dark" flush alwaysOpen>
          {_.map(groups, (group, marketName) => (
            <Accordion.Item key={marketName} eventKey={marketName}>
              <Accordion.Header>
                <div style={{ width: "95%" }}>{marketName}</div>
                <div style={{ width: "5%" }}>
                  {group.filter((mark) => mark.markState === "Submitted")
                    .length > 0 && ( //Suppress if none
                    <Badge pill bg="warning" text="dark">
                      {
                        group.filter((mark) => mark.markState === "Submitted")
                          .length
                      }
                    </Badge>
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ReviewMarksTableHeader
                  handleApprove={this.handleApprove}
                  handleReject={this.handleReject}
                  marks={group}
                  canApproveMarks={canApproveMarks}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(ReviewMarks);
