import React, { Component } from 'react';
import FontAwesome from "react-fontawesome";
import Table from '../../common/components/table/table';
import {formatDateTime} from '../../common/services/utilities';
import OrderCell from "../common/orderCell";
import QualityAttributeBadges from "../common/qualityAttributeBadges";
import { withTranslation } from 'react-i18next';
import _ from "lodash";

class OrganizationOrders extends Component {
    columns =[
        {path: 'orderId', label: this.props.t("OrderId")},
        {path: 'marketName', label: this.props.t("MarketName")},
        {path: 'termName', label: this.props.t("TermName")},        
        {path: 'qualityAttributeValues', label: this.props.t("QualityAttributes"), content: data => <QualityAttributeBadges qualityAttributeValues={data.qualityAttributeValues} />  },
        {path: 'orderDate', label: this.props.t("OrderDate"), content: data => <span>{formatDateTime(data.orderDate,Intl.DateTimeFormat().resolvedOptions().timeZone," ")}</span> },       
        {path: 'orderState', label: this.props.t("OrderState")},
        {path: 'allOrNone', label: this.props.t("AllOrNone"), content: data => <FontAwesome name={data.allOrNone ? "check" : ""}/>},
        // {path: 'volume', label: this.props.t("Volume")},   
        {path: 'price', label: this.props.t("Price"),  content: data => <OrderCell       
            value={data.price}
            type={data.orderDirection === "Buy" ? "Bid" : "Offer"}
            isDepth={false}
            pricePrecision={4} //Stub
            data={data}
            align={false}
            isOrganizationOrder={true}
        />},
        {path: 'volume', label: this.props.t("Volume"),  content: data => <OrderCell       
            value={data.volume}
            type={data.orderDirection === "Buy" ? "Bid" : "Offer"}            
            isDepth={false}
            pricePrecision={1} //Stub
            data={data}
            align={false}
            isOrganizationOrder={true}
        />},          
    ];

    render() { 
        const {organizationOrders } = this.props;
        const sortColumn = { path: "orderDate", order: "desc" };
        const data = _.orderBy(
            organizationOrders,
            "orderDate",
            "desc"
          )

        return (
            <Table className="table table-dark table-sm market-grid" columns= {this.columns} sortColumn = {sortColumn} data={data} valueProperty="orderId"/>
        );
    }
}

export default withTranslation(["trading"])(OrganizationOrders);