import _ from 'lodash';

export function pivotQualityAttributes(product) 
{

  //Get the priceDriver qualityAttributeIds
  const priceDrivers = product.qualityAttributes
  .filter(qualityAttribute => qualityAttribute.isPriceDriver !== false)
  .map(qualityAttribute => (
          qualityAttribute.qualityAttributeId
  ));

  //Group the qualityAttributeValues that are priceDrivers by QualityAttributeId to facilitate the cartesian product
  var qualityAttributes = product.qualityAttributeValues.filter(item => priceDrivers.includes(item.qualityAttributeId));

  //Push the product name onto the array so it's always included in the cartesian (so we alwasy get at least one row)
  qualityAttributes.push({qualityAttributeId:"shortName", value:product.shortName});

  //Create the grouped arrays so the cartesian can be performed
  const groups = _.groupBy(qualityAttributes,  'qualityAttributeId');

  //Set up the cartesian product calculator
  const cartesianProduct = (arrays) => {
      const keys = Object.keys(arrays);

      const product = keys.reduce((a, key) => {
        const values = arrays[key];
        return _.flatMap(a, (x) =>
          values.map((y) => ({ ...x, [y.qualityAttributeId]: {qualityAttributeId: y.qualityAttributeId, qualityAttributeValueId: y.qualityAttributeValueId, value:y.value }}))                
        );
      }, [{}]);

      return product               
         .map((element) => {
          const qualityAttributeValues = keys
         .map((key) => ({
           qualityAttributeId: element[key].qualityAttributeId,
           qualityAttributeValueId: element[key].qualityAttributeValueId,
           value: element[key].value,
         }))
         .filter(x => x.qualityAttributeValueId);

         const elements=Object.fromEntries(
              Object.entries(element).map(([key, value]) => [key, value.value])
          );
        return { ...elements, qualityAttributeValues };
      });
    };

  //Get the cartesian product
  const data = cartesianProduct(groups);

  //Return the cartesian product
  return data;
}
