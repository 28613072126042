import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getAliasesByProduct } from "../../../common/services/products/aliasService";
import { getOrganizations } from "../../../common/services/auth/organizationService";
import { withTranslation } from "react-i18next";

class ProductAliases extends Component {
  state = {
    aliases: [],
    organizations: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { productId } = this.props;

    await this.setState({ organizations: await getOrganizations() });

    const aliases = this.mapToViewModel(
      await getAliasesByProduct(productId),
      this.state.organizations
    );

    this.setState({
      aliases: _.orderBy(aliases, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(aliases, organizations) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return aliases.map((alias) => ({
      id: alias.aliasId,
      name: alias.name,
      organization: organizations.find(
        (org) => org.organizationId === alias.organizationId
      )?.name,
      active: alias.active.toString(),
      updatedByUserAccountId: alias.createdByUserAccountId,
      updatedBy:
        alias.updatedByUserAccountFirstName +
        " " +
        alias.updatedByUserAccountLastName,
      updatedDate: new Date(alias.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(alias.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: alias.eventId,
    }));
  }

  columns = [
    { path: "name", label: this.props.t("Name") },
    { path: "organization", label: this.props.t("Organization") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { aliases, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, productId } = this.props;

    return (
      <div className="history-table-container">
        {t("ProductID")} {productId}
        <Table
          className="table table-dark table-sm"
          columns={this.columns}
          sortColumn={sortColumn}
          data={aliases}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["products"])(ProductAliases);
