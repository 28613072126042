import React, { Component } from 'react';
import Loading from '../../common/components/loading/loading';
import { getProductWithQualityAttributes} from "../../common/services/products/productService";
import { getVenue } from "../../common/services/markets/venueService";
import { getAggregateMarksByMarket } from "../../common/services/marketdata/markService";
import { getTermSchedule} from "../../common/services/markets/termScheduleService";
import HistoricalMarketDataTable from "./historicalMarketDataTable";
import { withTranslation } from 'react-i18next';
import ListGroup from 'react-bootstrap/ListGroup';
import Input from "../../common/components/form/input";

class HistoricalMarketDataHeader extends Component {
    state = {
        marketAttributes: [],
        marks: [],
        forwardMarks: [],
        trades: [],
        marketPrices: [],
        isLoading: true,
        marketDate: null,
        fromDate: null,
        toDate: null,
        termScheduleId: null,
        priceBasisId:null,
        currencyId:null,
        uomId:null, 
        pricePrecision:2,
        volumePrecision:0,
        currencySymbol:"$",
   };

    async componentDidMount() {
        const {productId, market} = this.props;

        let date = new Date();
        date.setMonth(date.getMonth());
        date.setDate(1);
        const marketDate= date.toLocaleDateString('fr-CA')
        

        const venue = await getVenue(market.venueId);
        const product = await getProductWithQualityAttributes(productId);
        const termSchedule = await getTermSchedule(market.termScheduleId);
      
        const timeZone = venue.timeZone + " ("+Intl.DateTimeFormat("us",{
            timeZoneName: "short",
            timeZone: venue.timeZone,
          })
            .formatToParts()
            .find((i) => i.type === "timeZoneName").value+")"

        this.setState(
          {
            marketDate: marketDate,
            product: product,          
            marketAttributes: market.marketAttributes,
            termScheduleId: market.termScheduleId,
            forwardReportingPeriodicity: termSchedule.forwardReportingPeriodicity,
            historicalReportingPeriodicity: termSchedule.historicalReportingPeriodicity,
            priceBasisId: market.marketAttributes[0].priceBasisId,
            currencyId: market.marketAttributes[0].currencyId,
            uomId: market.marketAttributes[0].uomId,
            pricePrecision: market.marketAttributes[0].pricePrecision ?? 2,
            volumePrecision: market.marketAttributes[0].volumePrecision ?? 0,
            currencySymbol: market.marketAttributes[0].currencySymbol ?? "$",
            timeZone: timeZone,
            isLoading:false
        });

        marketDate && this.props.market && termSchedule && (await this.loadData(marketDate, termSchedule.historicalReportingPeriodicity))
    };

    async loadData(marketDate, historicalReportingPeriodicity)
    {
        //Set the fromMarketDate      
        const fromDate = new Date(marketDate);
        fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset())

        const currentDate = new Date()
        const eomDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 0);
        //Get the end of the month for the from date, or the current date, whichever is earlier.
        const toDate = currentDate < eomDate ? currentDate : eomDate;


        this.setState({fromDate: fromDate, toDate: toDate});

        const{market} =this.props;
        const {marketId} = market;

        await this.loadMarks(marketId, fromDate, toDate);
    }
  
    async loadMarks(marketId, fromDate, toDate)
    {
      const marks = (await getAggregateMarksByMarket(marketId, fromDate.toLocaleDateString('fr-CA'), toDate.toLocaleDateString('fr-CA'))).filter(x => x.markState === "Approved");

      this.setState ({marks: marks});
    }

    handleMarketDateChange = (marketDate) => {
      this.setState({marketDate: marketDate});
      const {historicalReportingPeriodicity} = this.state;

      (async () => {
        await this.loadData(marketDate, historicalReportingPeriodicity);
      })();

    };
  
    handlemarketAttributeSelect = (marketAttribute) => {      
      this.setState({priceBasisId: marketAttribute.priceBasisId, currencyId: marketAttribute.currencyId, uomId: marketAttribute.uomId });
    };

    getFilteredMarks()
    {
      const {marks, priceBasisId, uomId, currencyId} = this.state;

      const filteredMarks = marks.filter(x =>
        x.priceBasisId === priceBasisId
        && x.uomId === uomId
        && x.currencyId === currencyId
      );
  
      return { marks: filteredMarks };
    }

    render() { 
        const {product, timeZone, marketAttributes, termScheduleId, historicalReportingPeriodicity, isLoading, toDate, fromDate, marketDate, priceBasisId, currencyId, uomId, pricePrecision, volumePrecision, currencySymbol } = this.state;


        const { marks } = this.getFilteredMarks();

        if (isLoading) return (<Loading/>);

        const {t, market} = this.props;
        const { marketId } = market;

        var date = new Date();
        date.setMonth(date.getMonth());
        date.setDate(1);

        const endDate= date.toLocaleDateString('fr-CA').substring(0,7);
        const monthValue = marketDate.substring(0,7);

        return (
            <div className="container-fluid">          
              <div className='row' >
                <div className='col-md-3'>              
                </div>
                <div  className='col-md-6'>
                  <ListGroup value={marketAttributes[0]} horizontal="sm" data-bs-theme="dark">
                    {marketAttributes.map((attribute, index) => (
                      <ListGroup.Item className="market-attribute-selector" key={index} action active={priceBasisId === attribute.priceBasisId} onClick={() => this.handlemarketAttributeSelect(attribute)}>
                        {attribute.priceBasisName+" ("+attribute.currencyName+"/"+attribute.uomName+")"}
                      </ListGroup.Item>
                    ))} 
                  </ListGroup>
                </div>
                <div className='col-md-3'>
                </div>
              </div>
              <div className='row' >
                <div className="col-md-3">
                  <Input                    
                      data-bs-theme="dark" 
                      type="month"
                      max={endDate} 
                      onChange={e => this.handleMarketDateChange(e.currentTarget.value)}
                      //name = "name"
                      value = {monthValue}
                      label = {t("MarketDate")}
                  />                
                </div>
                <div className="col-md-3"></div>
                <div className="col-md-3"></div>
                <div className='col-md-2 mt-auto'>
                      {t("TimeZone")}:<br/>{timeZone}
                </div>
              </div>           
              <div className="row">
                <div className="col-md-12 market-data-tile">
                  <h5>{t("MarketDataHistory")}</h5>
                  <HistoricalMarketDataTable marks={marks} product={product} marketId={marketId} termScheduleId={termScheduleId} historicalReportingPeriodicity={historicalReportingPeriodicity} fromDate={fromDate} toDate={toDate} priceBasisId={priceBasisId} currencyId={currencyId} uomId={uomId} pricePrecision={pricePrecision} volumePrecision={volumePrecision} currencySymbol={currencySymbol}/>
                </div>                
              </div>
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(HistoricalMarketDataHeader)