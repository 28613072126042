import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_ORGANIZATION_API+"organization/";

export async function getOrganizations() {
  const {data: Organizations} = await http.get(apiEndpoint);
  return Organizations;
}

export async function getOrganization(organizationId) {
  const {data: Organization} = await http.get(apiEndpoint+organizationId);
  return Organization;
}

export async function addOrganization(organization) {  
  const {data: Organization} = await http.post(apiEndpoint, organization);
  return Organization;
}

export async function updateOrganization(organization) {
  const {data: Organization} = await http.put(apiEndpoint, organization);
  return Organization;
}

export async function deleteOrganization(organizationId) {
  return await http.delete(apiEndpoint+organizationId);
}

export async function getOrganizationHistory(organizationId) {
  const {data: OrganizationHistory} = await http.get(apiEndpoint+"audit/"+organizationId);
  return OrganizationHistory;
}
