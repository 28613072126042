import React, {useEffect, useCallback} from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";

const MessageHandler = () => {

    const { keycloak } = useKeycloak();

    const messageHub = process.env.REACT_APP_TRADING_ENGINE+"hub/message";

    const connect = useCallback(() => {
        try {
            //initiate a connection
            const connection = new HubConnectionBuilder()
                //.withUrl("https://localhost:7247/hub/message", {
                .withUrl(messageHub, {
                    accessTokenFactory: () => keycloak.token,
                })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            //set up handler
            connection.on("ReceiveMessage", (message)  => {
                console.log("Message Received:",message);
                toast.info(message,{autoClose: false})
            });

            connection.start();
        }
        catch (e)
        {
            console.log(e)
        }    
    },[keycloak, messageHub] )

    useEffect(()=>
    {
        connect();
    },[connect]);


    return (
        <>
        </>
    );
};

export default MessageHandler