import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class MarketsTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Name") },
    { path: "venueName", label: this.props.t("Venue") },
    { path: "productName", label: this.props.t("Product") },
    { path: "termScheduleName", label: this.props.t("TermSchedule") },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (market) => (
        <FontAwesome name={market.active ? "check" : "times"} />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = markets.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { markets, onSort, sortColumn, onRowClick } = this.props;
    return (
      <Table
        className="table table-dark table-sm"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={markets}
        onRowClick={onRowClick}
        valueProperty="marketId"
      />
    );
  }
}

export default withTranslation(["markets"])(MarketsTable);
