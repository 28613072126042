import React from 'react';
import Select, {components} from 'react-select';

const ValueContainer = ({ children, getValue, ...props }) => {
    var values = getValue();
    var valueLabel = "";

    if (values.length > 0) valueLabel += props.selectProps.getOptionLabel(values[0]);
    if (values.length > 1) valueLabel += ` (+${values.length - 1})`;

    // Keep standard placeholder and input from react-select
    var childsToRender = React.Children.toArray(children).filter((child) => ['Input', 'DummyInput', 'Placeholder'].indexOf(child.type.name) >= 0);

    return (
      <components.ValueContainer {...props}>
        {!props.selectProps.inputValue && valueLabel }
        { childsToRender }
      </components.ValueContainer>
    );
  };


const Multiselect = ({name, label, options, error, idField, optionField, value, ...rest}) => {
   
    return (  
        <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Select 
            {...rest}
            id={name}
            name={name}
            options={options}
            value={value}
            components={{
                ValueContainer
            }}                   
        >
        </Select>
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
    );
}
 
export default Multiselect;