import React, { useState, useEffect  } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import NavHeader from "./components/navHeader";
import NotFound from "./pages/notFound";
import WelcomePage from "./pages/Homepage";
import UserProfile from "./pages/userProfile";
import Maintenance from "./pages/maintenance";
import ProtectedRoute from '../common/components/routes/protectedRoute';
import AuthAdmin from "../admin/auth/authAdmin";
import ProductAdmin from "../admin/products/productAdmin";
import MarketAdmin from "../admin/markets/marketAdmin";
import TradingAdmin from "../admin/trading/tradingAdmin";
import Loading from './../common/components/loading/loading';
import 'react-toastify/dist/ReactToastify.css';
import EnvironmentIndicator from './components/environmentIndicator';
import MessageHandler from './components/messageHandler';
import SubmitTradesAuthWrapper from './../marketData/submitTrades/submitTradesAuthWrapper';
import ReviewTradesAuthWrapper from './../marketData/reviewTrades/reviewTradesAuthWrapper';
import SubmitMarksAuthWrapper from './../marketData/submitMarks/submitMarksAuthWrapper';
import ReviewMarksAuthWrapper from './../marketData/reviewMarks/reviewMarksAuthWrapper';
import MarketDataAuthWrapper from './../marketData/marketData/marketDataAuthWrapper';
import HistoricalMarketDataAuthWrapper from './../marketData/historicalMarketData/historicalMarketDataAuthWrapper';
import HistoricalTradeDataAuthWrapper from './../marketData/historicalTradeData/historicalTradeDataAuthWrapper';
import DashboardContainer from './../dashboards/dashboardContainer';
import MarketDataTicker from './../marketData/marketDataTicker';
import TradingComponent from './../trading/tradingComponent';
import { useKeycloak } from "@react-keycloak/web";
import AlbertaEnvironmentalTrades from '../dashboards/albertaEnvironmental/albertaEnvironmentalTrades';
import AlbertaEnvironmentalIssues from '../dashboards/albertaEnvironmental/albertaEnvironmentalIssues';
import AlbertaEnvironmentalStatusChanges from '../dashboards/albertaEnvironmental/albertaEnvironmentalStatusChanges';
import AlbertaEnvironmentalRegistry from '../dashboards/albertaEnvironmental/albertaEnvironmentalRegistry';
import { getMarketsWithAttributes } from "../common/services/markets/marketService";
import { getMarketGroupMarkets } from "../common/services/markets/marketGroupMarketService";
import { getProducts } from "../common/services/products/productService";
import { getUserAccountSubscriptionsByUserAccount } from "../common/services/marketdata/userAccountSubscriptionService";
import { getVenues } from "../common/services/markets/venueService";
import { getTermSchedules } from "../common/services/markets/termScheduleService";
import { getUnapprovedMarkCount } from "../common/services/marketdata/markService";
import { getUnapprovedTradeCount } from "../common/services/marketdata/tradeService";
import { usePermitted } from "../common/components/permissions/permissions";

const App = () => {

  //Markets with Attributes
  const [marketsWithAttributes, setMarketsWithAttributes] = useState(null);

  const { keycloak, initialized } = useKeycloak();
  const userAccountId = initialized && keycloak.tokenParsed.OriginatingUserAccountID;
  
  const approveMarks = usePermitted(["api-approve-marks"]);
  const approveTrades = usePermitted(["api-approve-trades"]);

  const queryMarkets = usePermitted(["api-query-markets"]);
  const queryMarketGroups = usePermitted(["api-query-market-groups"]);
  const queryProducts = usePermitted(["api-query-products"]);
  const queryVenues= usePermitted(["api-query-venues"]);
  const queryTermSchedules = usePermitted(["api-query-term-schedules"]);
  const queryUserAccountSubscriptions = usePermitted(["api-query-user-account-market-subscriptions"]);
  const queryMarks = usePermitted(["api-query-marks"]);

  const isSubscriber = usePermitted(["market-data-subscriber","market-data-provider","operator","administrator"]);

  const enableMessaging = process.env.REACT_APP_ENABLE_SIGNALR_MESSAGING === 'true';

    //Markets with Attributes
    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryMarkets)
          {
            const result = await getMarketsWithAttributes();
            setMarketsWithAttributes(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryMarkets]);

    //Markets Group Markets
    const [marketGroupMarkets, setMarketGroupMarkets] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryMarketGroups)
          {
            const result = await getMarketGroupMarkets();
            setMarketGroupMarkets(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryMarketGroups]);

    //Products
    const [products, setProducts] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryProducts)
          {
            const result = await getProducts();
            setProducts(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryProducts]); 

    //User Account Subscriptions By UserAccount
    const [userAccountSubscriptionsByUserAccount, setUserAccountSubscriptionsByUserAccount] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryUserAccountSubscriptions)
          {
            const result = await getUserAccountSubscriptionsByUserAccount(userAccountId);
            setUserAccountSubscriptionsByUserAccount(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryUserAccountSubscriptions, userAccountId]);

    //Venues
    const [venues, setVenues] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryVenues)
          {
            const result = await getVenues();
            setVenues(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryVenues]);

    //Term Schedules
    const [termSchedules, setTermSchedules] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && queryTermSchedules)
          {
            const result = await getTermSchedules();
            setTermSchedules(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [initialized, queryTermSchedules]);    

    //Unapproved Mark Count 
    const [unapprovedMarkCount, setUnapprovedMarkCount] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && approveMarks)
          {
            const result = await getUnapprovedMarkCount();
            setUnapprovedMarkCount(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();

      const intervalId = setInterval(() =>
        {
          fetchData();
        }
        , 300000
      )

      return () => {
        clearInterval(intervalId); //This is important
      }
 
    }, [initialized, approveMarks]);       

    //Unapproved Trade Count 
    const [unapprovedTradeCount, setUnapprovedTradeCount] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          if(initialized && approveTrades)
          {
            const result = await getUnapprovedTradeCount();
            setUnapprovedTradeCount(result);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();

      const intervalId = setInterval(() =>
        {
          fetchData();
        }
        , 300000
      )

      return () => {
        clearInterval(intervalId); //This is important
      }

    }, [initialized, approveTrades]);        

    if 
    (
      !keycloak.authenticated ||
      (queryMarketGroups && !marketGroupMarkets) ||
      (queryMarkets && !marketsWithAttributes) ||
      (queryProducts && !products) ||
      (queryVenues && !venues) ||
      (queryTermSchedules && !termSchedules)
    )
    {
      return  <div style={{height:"100vh"}}><Loading/></div>;
    }

    return (
      <React.Fragment>
        <ToastContainer theme="dark"/>
        {enableMessaging && <MessageHandler/>}
        <EnvironmentIndicator/>
        <div className="div-window">
          <div className="div-header">
            <NavHeader unapprovedMarkCount={unapprovedMarkCount} unapprovedTradeCount={unapprovedTradeCount} />
          </div>
          <div className="div-main">
            <Routes>
              <Route exact path='/' element={<WelcomePage />} />
              <Route exact
                path='/profile'
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route exact
                path='/admin/auth'
                element={
                  <ProtectedRoute permittedRoles={['administrator', 'operator']}>
                    <AuthAdmin />
                  </ProtectedRoute>
                }
              />              
              <Route exact
                path='/admin/products'
                element={
                  <ProtectedRoute permittedRoles={['administrator', 'operator']}>
                    <ProductAdmin />
                  </ProtectedRoute>
                }
              />
              <Route exact
                path='/admin/markets'
                element={
                  <ProtectedRoute permittedRoles={['administrator', 'operator']}>
                    <MarketAdmin />
                  </ProtectedRoute>
                }
              />
              <Route exact
                path='/admin/trading'
                element={
                  <ProtectedRoute permittedRoles={['administrator', 'operator']}>
                    <TradingAdmin />
                  </ProtectedRoute>
                }
              />              
              <Route exact
                path='/marketdata/submitmarks'
                element={
                  <ProtectedRoute permittedRoles={['api-manage-marks','api-approve-marks']}>
                    <SubmitMarksAuthWrapper                      
                      marketsWithAttributes={marketsWithAttributes} 
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={userAccountSubscriptionsByUserAccount} />
                  </ProtectedRoute>
                }
                />                           
              <Route exact
                path='/marketdata/reviewmarks'
                element={
                  <ProtectedRoute permittedRoles={['api-approve-marks']}>
                    <ReviewMarksAuthWrapper />
                  </ProtectedRoute>
                }                
              />     
              <Route exact
                path='/marketdata/submittrades'
                element={
                  <ProtectedRoute permittedRoles={['api-manage-trades','api-approve-trades']}>
                    <SubmitTradesAuthWrapper
                        marketsWithAttributes={marketsWithAttributes} 
                        marketGroupMarkets={marketGroupMarkets}
                        products={products}
                        venues={venues}
                        termSchedules={termSchedules}
                        userAccountSubscriptionsByUserAccount={userAccountSubscriptionsByUserAccount} />
                  </ProtectedRoute>
                }
                />       
              <Route exact
                path='/marketdata/reviewtrades'
                element={
                  <ProtectedRoute permittedRoles={['api-approve-trades']}>
                    <ReviewTradesAuthWrapper />
                  </ProtectedRoute>
                }
                />                                      
              <Route exact
                path='/marketdata/'
                element={
                  <ProtectedRoute permittedRoles={['api-query-marks', 'api-query-trades']}>
                    <MarketDataAuthWrapper 
                      marketsWithAttributes={marketsWithAttributes} 
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={userAccountSubscriptionsByUserAccount}
                    />
                  </ProtectedRoute>
                }                
              />     
              <Route exact
                path='/historicalmarketdata/'
                element={
                  <ProtectedRoute permittedRoles={['api-query-marks']}>
                    <HistoricalMarketDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes} 
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={userAccountSubscriptionsByUserAccount}
                    />                    
                  </ProtectedRoute>
                }                
              />       
              <Route exact
                path='/historicaltrades/'
                element={
                  <ProtectedRoute permittedRoles={['api-query-trades']}>
                    <HistoricalTradeDataAuthWrapper
                      marketsWithAttributes={marketsWithAttributes} 
                      marketGroupMarkets={marketGroupMarkets}
                      products={products}
                      venues={venues}
                      termSchedules={termSchedules}
                      userAccountSubscriptionsByUserAccount={userAccountSubscriptionsByUserAccount}
                    />                    
                  </ProtectedRoute>
                }                
              />                            
              <Route exact
                path='/dashboards/albertaenvironmental'
                element={
                    <DashboardContainer fileName="albertaEnvironmentalDashboard.html" />
                }                
              />       
              <Route exact
                path='/dashboards/albertaelectricity'
                element={
                    <DashboardContainer fileName="albertaElectricityDashboard.html" />
                }                
              />       
              <Route exact
                path='/trading'
                element={
                  //Stub
                  <ProtectedRoute permittedRoles={['administrator']}> 
                    <TradingComponent />
                  </ProtectedRoute>
                }                
              />                                                      
              <Route exact
                path='/dashboards/albertaenvironmental/trades'
                element={
/*                   <ProtectedRoute permittedRoles={['api-query-trades']}> */
                    <AlbertaEnvironmentalTrades isSubscriber={isSubscriber}/>
/*                   </ProtectedRoute> */
                }                
              />         
              <Route exact
                path='/dashboards/albertaenvironmental/issues'
                element={
                  <ProtectedRoute permittedRoles={['api-query-trades']}>
                    <AlbertaEnvironmentalIssues />
                  </ProtectedRoute>
                }                
              />        
              <Route exact
                path='/dashboards/albertaenvironmental/statuschanges'
                element={
                  <ProtectedRoute permittedRoles={['api-query-trades']}>
                    <AlbertaEnvironmentalStatusChanges />
                  </ProtectedRoute>
                }                
              />                                    
              <Route exact
                path='/dashboards/albertaenvironmental/registry'
                element={
/*                   <ProtectedRoute permittedRoles={['api-query-trades']}> */
                    <AlbertaEnvironmentalRegistry isSubscriber={isSubscriber}/>
/*                   </ProtectedRoute> */
                }                
              />                                                                    

              <Route path='/maintenance' element={<Maintenance />} />
              <Route path='/not-found' element={<NotFound />} />
              <Route path='*' element={<Navigate replace to="/not-found" />}/>
            </Routes>          
          </div>
          {
            queryMarks &&
            <Routes>
              {["/", "/marketdata/*", "/historicalmarketdata/*", "/dashboards/*"].map(path => (
                <Route 
                  key="Home" // optional: avoid full re-renders on route changes
                  path={path}
                  element = {
                    <div className="div-footer">
                      <div className="ticker-container">
                        <MarketDataTicker userAccountId = {userAccountId} />
                      </div>
                    </div>
                  }
                />
              ))}     
            </Routes>
          }      
        </div>
      </React.Fragment>
    );
};

export default App;