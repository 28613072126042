import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import Uoms from "./uoms";
import Uom from "./uom";
import UomTypes from "./uomTypes";
import UomType from "./uomType";
import { withTranslation } from "react-i18next";

class SubscriptionAdmin extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 50,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Uoms"),
              enableClose: false,
              component: "Uoms",
            },
            {
              type: "tab",
              name: this.props.t("UomTypes"),
              enableClose: false,
              component: "UomTypes",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 50,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
            // {
            //   type: "tab",
            //   name: "User",
            //   visible: false,
            //   component: "Placeholder"
            // }
          ],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleUomSelect = (Uom, submitCallbackFunction, isNew) => {
    const { uomId, name } = Uom;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "Uom",
            name: `${name}`,
            id: `${uomId}`,
            config: {
              uomId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(uomId));
    }
  };

  handleUomTypeSelect = (UomType, submitCallbackFunction, isNew) => {
    const { uomTypeId, name } = UomType;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "UomType",
            name: `${name}`,
            id: `${uomTypeId}`,
            config: {
              uomTypeId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(uomTypeId));
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const UomId = node.getId();

    if (componentId === "Uoms") {
      return (
        <Uoms onClick={this.handleUomSelect} allowEdit={this.props.allowEdit} />
      );
    } else if (componentId === "Uom") {
      const { uomId, submitCallbackFunction, isNew } = node.getConfig();
      return (
        <Uom
          uomId={uomId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "UomTypes") {
      return (
        <UomTypes
          onClick={this.handleUomTypeSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "UomType") {
      const { uomTypeId, submitCallbackFunction, isNew } = node.getConfig();
      return (
        <UomType
          uomTypeId={uomTypeId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["markets"])(SubscriptionAdmin);
