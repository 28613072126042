import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"marketGroup/";

export async function getMarketGroups() {
    const {data: MarketGroups} = await http.get(apiEndpoint);
    return MarketGroups;
}

export async function getMarketGroup(marketGroupId) {
  const {data: MarketGroup} = await http.get(apiEndpoint+marketGroupId);
  return MarketGroup;
}

export async function addMarketGroup(marketGroup) {  
  const {data: MarketGroup} = await http.post(apiEndpoint, marketGroup);
  return MarketGroup;
}

export async function updateMarketGroup(marketGroup) {
  const {data: MarketGroup} = await http.put(apiEndpoint, marketGroup);
  return MarketGroup;
}

export async function deleteMarketGroup(marketGroupId) {
  return await http.delete(apiEndpoint+marketGroupId);
}

export async function getMarketGroupHistory(marketGroupId) {
  const {data: MarketGroupHistory} = await http.get(apiEndpoint+"audit/"+marketGroupId);
  return MarketGroupHistory;
}
