import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../../common/components/table/table';
import Loading from '../../../common/components/loading/loading';
import { getOrganizationHistory} from "../../../common/services/auth/organizationService";
import { withTranslation } from 'react-i18next';

class OrganizationHistory extends Component {

    state = {
        organizationHistory: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true
   };

    async componentDidMount() {
        const {organizationId} = this.props;
        const organizationHistory = this.mapToViewModel(await getOrganizationHistory(organizationId));

        this.setState({organizationHistory: _.orderBy(organizationHistory, "id", "desc"), isLoading:false});
    };

    mapToViewModel(organizations) {
        const dateFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
            second: 'numeric'
        }

        return organizations.map(organization => (
            {
                id: organization.id,
                name: organization.name,
                shortName: organization.shortName,
                websiteUrl: organization.websiteUrl,
                active: organization.active.toString(),
                updatedByUserAccountId: organization.updatedByUserAccountId,
                updatedBy: organization.updatedByUserAccountFirstName+" "+organization.updatedByUserAccountLastName,
                updatedDate: new Date(organization.updatedDate).toLocaleDateString('en-US', dateFormatOptions),
                writeDate: new Date(organization.writeDate).toLocaleDateString('en-US', dateFormatOptions),
                eventId: organization.eventId
            }
        ));
    }

    columns =[
        {path: 'id', label: this.props.t("RecordID")},
        {path: 'name', label: this.props.t("OrganizationName")},
        {path: 'shortName', label: this.props.t("OrganizationShortName")},
        {path: 'websiteUrl', label: this.props.t("OrganizationWebsite")},
        {path: 'active', label: this.props.t("Active")},
        {path: 'updatedBy', label: this.props.t("UpdatedBy")},
        {path: 'updatedDate', label: this.props.t("UpdateDate")},
        {path: 'writeDate', label: this.props.t("WriteDate")},
        {path: 'eventId', label: this.props.t("EventID")}
    ];

    render() { 
        const {organizationHistory, sortColumn, isLoading} = this.state;

        if (isLoading) return (<Loading/>);

        const {t, organizationId} = this.props;

        return (
            <div className="history-table-container">
                {t("OrganizationID")} {organizationId}
                <Table className="table-history" columns= {this.columns} sortColumn = {sortColumn} data={organizationHistory} valueProperty="id"/>
            </div>
        );
    }
}

export default withTranslation(["auth"])(OrganizationHistory)