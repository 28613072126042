import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"uomType/";

export async function getUomTypes() {
    const {data: UomTypes} = await http.get(apiEndpoint);
    return UomTypes;
}

export async function getUomType(uomTypeId) {
  const {data: UomType} = await http.get(apiEndpoint+uomTypeId);
  return UomType;
}

export async function addUomType(uomType) {  
  const {data: UomType} = await http.post(apiEndpoint, uomType);
  return UomType;
}

export async function updateUomType(uomType) {
  const {data: UomType} = await http.put(apiEndpoint, uomType);
  return UomType;
}

export async function deleteUomType(uomTypeId) {
  return await http.delete(apiEndpoint+uomTypeId);
}

export async function getUomTypeHistory(uomTypeId) {
  const {data: UomTypeHistory} = await http.get(apiEndpoint+"audit/"+uomTypeId);
  return UomTypeHistory;
}
