import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class OrganizationSubscriptionsTable extends Component {
  columns = [
    {
      path: "organizationName",
      label: this.props.t("OrganizationNameFull"),
    },
    {
      path: "subscribableName",
      label: this.props.t("SubscribableName"),
    },
    {
      path: "subscriptionType",
      label: this.props.t("SubscriptionType"),
    },
    {
      path: "compensated",
      label: this.props.t("Compensated"),
      content: (organizationSubscription) => (
        <FontAwesome
          name={organizationSubscription.compensated ? "check" : "times"}
        />
      ),
    },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (organizationSubscription) => (
        <FontAwesome
          name={organizationSubscription.active ? "check" : "times"}
        />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = auth.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { organizationSubscriptions, onSort, sortColumn, onRowClick } =
      this.props;
    return (
      <Table
        className="table table-dark table-sm"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={organizationSubscriptions}
        onRowClick={onRowClick}
        valueProperty="organizationSubscriptionId"
      />
    );
  }
}

export default withTranslation(["auth"])(OrganizationSubscriptionsTable);
