import React, { useEffect, useState, useRef } from 'react';
import * as d3 from 'd3';
import {aggregateMarksWithoutAttributes} from "../../services/marketdata/marketDataUtilities";

const CandlestickChart = ({ data, historicalReportingPeriodicity, startDate, endDate, yMin, yMax, currencySymbol, pricePrecision }) => {
  const svgRef = useRef();
  const tooltipRef = useRef(null);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });


  useEffect(() => {

     const svg = d3.select(svgRef.current);
     svg.selectAll('*').remove();
     
    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;

    const margin = { top: 20, right: 20, bottom: 45, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    //setDimensions({ innerWidth, innerHeight });   
    setDimensions({ width: parentWidth, height: parentHeight }); // Update the dimensions


    const chartSvg = svg
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${parentWidth} ${parentHeight}`)
      .attr('preserveAspectRatio', 'none') // Preserve aspect ratio
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);    

      const dateSequence = (() =>
      {
        switch (historicalReportingPeriodicity)
        {
          case "Hour":
            return d3.timeHours(
                d3.timeDay.offset(startDate, 0) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
              , d3.timeDay.offset(endDate, 1)
            );
          case "Day":
            return d3.timeDays(
                d3.timeDay.offset(startDate, 0) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
                , d3.timeDay.offset(endDate, 1)
              );
          default:
            return d3.timeDays(
              d3.timeDay.offset(startDate, 0) //Need to assess if this is necessary, or an adjustment for an upstream UTC bug.
              , d3.timeDay.offset(endDate, 1)
            );
        };
      })();

    //Get aggregateMarks for the provided data
    const aggregatedData = aggregateMarksWithoutAttributes(data);

    // Create scales
    // Ensure unique dates in your dateSequence array
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(dateSequence))
      .range([0, innerWidth]);


    const yScale = d3
      .scaleLinear()
      .domain([yMin ?? Math.min(0, d3.min(data, (d) => d.low ?? d.price)), yMax ?? d3.max(data, (d) => d.high ?? d.price)]) //Stopgap in case yMax/yMin not provided
      .range([innerHeight, 0]);

    //Calculate the width of a single period
    const bandProportion = 
     (() =>
    {
      switch (historicalReportingPeriodicity)
      {
        case "Hour":
          return 1000 * 60 * 60;
        case "Day":
          return 1000 * 60 * 60 * 24;
        default:
          return 1000 * 60 * 60 * 24;
      };
    })()/(endDate - startDate);

    const xBandWidth = bandProportion * (xScale(new Date(endDate)) - xScale(new Date(startDate)));

    aggregatedData.sort((a, b) => new Date(a.date ?? a.marketDate) - new Date(b.date ?? b.marketDate));

    // Add line
    const line = d3.line()
      .defined(d => d.price) // Ignore undefined or NaN values
      .x(d => xScale(new Date(d.date ?? d.marketDate)))
      .y(d => yScale(d.price));


    // Remove existing lines
    chartSvg.selectAll('.line').remove();
    // Append the path element for the line
    chartSvg
      .selectAll('.line')
      .data([aggregatedData]) // Bind data to the path element
      .enter()
      .append('path')
      .attr('class', 'line')
      .style("stroke-dasharray", ("3, 3"))  // <== This line here!!
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', 'grey')
      .attr('stroke-width', 1)

    // Draw high and low lines
    chartSvg
      .selectAll('.high-low-line')
      .data(aggregatedData.filter(x => x.high && x.low)) //must be both a high and a low.
      .enter()
      .append('line')      
      .attr('class', 'high-low-line')
      .attr('x1', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('x2', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('y1', (d) => yScale(d.high))
      .attr('y2', (d) => yScale(d.low))
      .attr('stroke', 'white')
      .attr('stroke-width', 1);

    // Draw candlesticks
    chartSvg
      .selectAll('.candlestick')
      .data(aggregatedData.filter(x => x.open && x.close)) //must be both an open and a close.
      .enter()
      .append('rect')
      .attr('class', 'candlestick')
      .attr('x', (d) => xScale(new Date(d.date ?? d.marketDate)) - xBandWidth/2)
      .attr('y', (d) => yScale(Math.max(d.open, d.close)))
      .attr('width', xBandWidth)      
      .attr('height', (d) => Math.abs(yScale(d.open) - yScale(d.close)))
      .attr('fill', (d) => (d.open < (d.close) ? 'green' : 'red'));

      // Create a tooltip element
      const tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("border-radius", "5px")
      .style("color", "white")
      .style("pointer-events", "none")
      .style("opacity", 0);

      tooltipRef.current = tooltip;

    // Draw circles for each price
    const circleGroup = chartSvg.append('g').attr('class', 'price-circles');

    circleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.price !== 'undefined' && d.price !== null &&d.price && d.markState === "Approved"))) //must be a price to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'price-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.price))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'grey')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong></strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);        
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });      

    // Draw circles for each price
    const submittedCircleGroup = chartSvg.append('g').attr('class', 'price-circles');

    submittedCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.price !== 'undefined' && d.price !== null &&d.price && d.markState === "Submitted"))) //must be a price to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'price-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.price))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'goldenrod')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong></strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });      


    // Draw circles for each bid
    const bidCircleGroup = chartSvg.append('g').attr('class', 'bid-circles');

    bidCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.bid !== 'undefined' && d.bid !== null &&d.bid))) //must be a bid to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'bid-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.bid))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'blue')
      .attr('opacity', '75%')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");
        tooltip.html(`<strong>Bid:</strong> ${currencySymbol}${d.bid.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });      


    // Draw circles for each offer
    const offerCircleGroup = chartSvg.append('g').attr('class', 'offer-circles');

    offerCircleGroup
      .selectAll('.price-circle')
      .data(data.filter((d) => (typeof d.offer !== 'undefined' && d.offer !== null &&d.offer))) //must be a offer to draw the point.
      .enter()
      .append('circle')
      .attr('class', 'offer-circle')
      .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
      .attr('cy', (d) => yScale(d.offer))
      .attr('r', 2) // Adjust the radius as needed
      .attr('fill', 'red')
      .attr('opacity', '50%')
      .attr('stroke', 'black')
      .on("mouseover", function(event, d) {
        // Show tooltip
        tooltip.transition().duration(200).style("opacity", 1);
        tooltip.style("padding", "5px");        
        tooltip.html(`<strong>Offer:</strong> ${currencySymbol}${d.offer.toFixed(pricePrecision)}`);
      })
      .on("mousemove", function(event) {
          // Move tooltip with the mouse
          tooltip
              .style("left", (event.pageX + 10) + "px")
              .style("top", (event.pageY - 28) + "px");
      })
      .on("mouseout", function() {
          // Hide tooltip
          tooltip.transition().duration(500).style("opacity", 0);
      });      


      // Draw circles for each aggregated price
      const aggregatedCircleGroup = chartSvg.append('g').attr('class', 'price-aggregated-circles');

      aggregatedCircleGroup
        .selectAll('.price-aggregated-circle')
        .data(aggregatedData.filter((d) => (typeof d.price !== 'undefined' && d.price !== null &&d.price && d.markState === "Approved"))) //must be a price to draw the point.
        .enter()
        .append('circle')
        .attr('class', 'price-aggregated-circle')
        .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
        .attr('cy', (d) => yScale(d.price))
        .attr('r', 3) // Adjust the radius as needed
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .on("mouseover", function(event, d) {
          // Show tooltip
          tooltip.transition().duration(200).style("opacity", 1);
          tooltip.style("padding", "5px");          
          tooltip.html(`<strong>Average:</strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);
        })
        .on("mousemove", function(event) {
            // Move tooltip with the mouse
            tooltip
                .style("left", (event.pageX + 10) + "px")
                .style("top", (event.pageY - 28) + "px");
        })
        .on("mouseout", function() {
            // Hide tooltip
            tooltip.transition().duration(500).style("opacity", 0);
        });      
  

      // Draw circles for each aggregated price
      const submittedAggregatedCircleGroup = chartSvg.append('g').attr('class', 'price-aggregated-circles');

      submittedAggregatedCircleGroup
        .selectAll('.price-aggregated-circle')
        .data(aggregatedData.filter((d) => (typeof d.price !== 'undefined' && d.price !== null &&d.price && d.markState === "Submitted"))) //must be a price to draw the point.
        .enter()
        .append('circle')
        .attr('class', 'price-aggregated-circle')
        .attr('cx', (d) => xScale(new Date(d.date ?? d.marketDate)))
        .attr('cy', (d) => yScale(d.price))
        .attr('r', 3) // Adjust the radius as needed
        .attr('fill', 'goldenrod')
        .attr('stroke', 'black')
        .on("mouseover", function(event, d) {
          // Show tooltip
          tooltip.transition().duration(200).style("opacity", 1);
          tooltip.style("padding", "5px");          
          tooltip.html(`<strong>Average:</strong> ${currencySymbol}${d.price.toFixed(pricePrecision)}`);
        })
        .on("mousemove", function(event) {
            // Move tooltip with the mouse
            tooltip
                .style("left", (event.pageX + 10) + "px")
                .style("top", (event.pageY - 28) + "px");
        })
        .on("mouseout", function() {
            // Hide tooltip
            tooltip.transition().duration(500).style("opacity", 0);
        });      
  


    const tickFormat = (() =>
    {
      switch (historicalReportingPeriodicity)
      {
        case "Hour":
          return '%a %H:%M';
        case "Day":
          return '%b-%d';
        default:
          return '%b-%d';
      };
    })();

    // Draw axes
    const priceFormat = (currencySymbol??'$')+',.'+(pricePrecision??'2')+'f';
    const xAxis = d3.axisBottom(xScale)
      .tickValues(dateSequence) // Specify the tick values explicitly
      .tickFormat(d3.timeFormat(tickFormat));


    const yAxis = d3.axisLeft(yScale)
      .tickFormat(d3.format(priceFormat));


    chartSvg
      .append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end') // Set the text-anchor property to 'end' to align the text at the end of the tick
      .attr('transform', 'rotate(-45)'); // Rotate the text by -45 degrees


    chartSvg
      .append('g')
      .call(yAxis)
      .selectAll('text')
      .attr('dy', '-0.5em') // Adjust the vertical position of the labels
      .style('text-anchor', 'end'); // Align the text to the end of the tick


    // Y-axis label
    chartSvg
    .append('text')
    .attr('x', 0 - innerHeight / 2)
    .attr('y', -margin.left)
    .attr('dy', '1em')
    .style('text-anchor', 'middle')
    .text('Price'); // Customize the label as needed

    return () => {
      // Remove the tooltip when the component unmounts
      if (tooltipRef.current) {
        tooltipRef.current.remove();
      }
    };

  }, [data, currencySymbol, endDate, historicalReportingPeriodicity, pricePrecision, startDate, yMax, yMin]);

  return (
    <svg ref={svgRef} width={dimensions.width} height={dimensions.height}>
      {/* Chart will be drawn here */}
    </svg>
  );
};

export default CandlestickChart;