import _ from 'lodash';

//Used by spot history table
export function aggregateMarks(marks)
{
        // Grouping by 'instrumentId', 'termId', 'qualityAttributeValueHash', and 'marketDate'
        const groupedArray = _.groupBy(marks, obj =>
            _.join([obj.instrumentId, obj.termId, obj.qualityAttributeValueHash, obj.marketDate], '|')
        );
      
        // Creating a new array with rolled-up values
        const aggregateMarks = _.map(groupedArray, group => {
            const {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            marketDate,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            } = group[0];
                    
            const overridePrice = calculateAveragePrice(group.filter(item => item.override)); // Calculate average price for items where override is true
            const price = overridePrice ?? calculateAveragePrice(group);

            const high = _.maxBy(group, record => (record.high !== null ? record.high : -Infinity)).high;
            const low = _.minBy(group, record => (record.low !== null ? record.low : Infinity)).low;
            
            const filteredOpen = group.map(item => item.open).filter(value => value !== undefined && value !== null && !isNaN(value));
            const open = _.mean(filteredOpen);
            const filteredClose = group.map(item => item.close).filter(value => value !== undefined && value !== null && !isNaN(value));
            const close = _.mean(filteredClose);
            const markState = getLowestDenominatorMarkState(group)

            return {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            marketDate,
            termName,
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            price,
            high,
            low,
            open,
            close,
            markState,
            };
        });

    return aggregateMarks
}

//Used by forward curve table
export function aggregateMarksWithoutDate(marks)
{
        // Grouping by 'instrumentId', 'termId', 'qualityAttributeValueHash', and 'marketDate'
        const groupedArray = _.groupBy(marks, obj =>
            _.join([obj.instrumentId, obj.termId, obj.qualityAttributeValueHash], '|')
        );
      
        // Creating a new array with rolled-up values
        const aggregateMarks = _.map(groupedArray, group => {
            const {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            } = group[0];
                    
            const overridePrice = calculateAveragePrice(group.filter(item => item.override)); // Calculate average price for items where override is true
            const price = overridePrice ?? calculateAveragePrice(group);

            const high = _.maxBy(group, record => (record.high !== null ? record.high : -Infinity)).high;
            const low = _.minBy(group, record => (record.low !== null ? record.low : Infinity)).low;
            
            const filteredOpen = group.map(item => item.open).filter(value => value !== undefined && value !== null && !isNaN(value));
            const open = _.mean(filteredOpen);
            const filteredClose = group.map(item => item.close).filter(value => value !== undefined && value !== null && !isNaN(value));
            const close = _.mean(filteredClose);
            const markState = getLowestDenominatorMarkState(group)

            return {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            termName,
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            price,
            high,
            low,
            open,
            close,
            markState,
            };
        });

    return aggregateMarks
}


//Used by Forward Curve chart
export function aggregateMarksByDate(marks)
{
        // Grouping by 'instrumentId', 'termId', 'qualityAttributeValueHash', and 'marketDate'
        const groupedArray = _.groupBy(marks, obj =>
            _.join([obj.instrumentId, obj.termId, obj.qualityAttributeValueHash, obj.date], '|')
        );
        
        // Creating a new array with rolled-up values
        const aggregateMarks = _.map(groupedArray, group => {
            const {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            date,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            } = group[0];
        
            const overridePrice = calculateAveragePrice(group.filter(item => item.override)); // Calculate average price for items where override is true
            const price = overridePrice ?? calculateAveragePrice(group);

            const high = _.maxBy(group, record => (record.high !== null ? record.high : -Infinity)).high;
            const low = _.minBy(group, record => (record.low !== null ? record.low : Infinity)).low;
            
            const filteredOpen = group.map(item => item.open).filter(value => value !== undefined && value !== null && !isNaN(value));
            const open = _.mean(filteredOpen);
            const filteredClose = group.map(item => item.close).filter(value => value !== undefined && value !== null && !isNaN(value));
            const close = _.mean(filteredClose);
            const markState = getLowestDenominatorMarkState(group)

            return {
            instrumentId,
            termId,
            qualityAttributeValueHash,
            date,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            price,
            high,
            low,
            open,
            close,
            markState,
            };
        });

    return aggregateMarks
}

//Used by Spot/Candlestick Chart.
export function aggregateMarksWithoutAttributes(marks)
{
        const preAggregatedMarks = aggregateMarks(marks);

        // Grouping by 'instrumentId', 'termId', 'qualityAttributeValueHash', and 'marketDate'
        const groupedArray = _.groupBy(preAggregatedMarks, obj =>
            _.join([ obj.termId, obj.marketDate], '|')
        );
        
        // Creating a new array with rolled-up values
        const aggregatedMarks = _.map(groupedArray, group => {
            const {
            termId,
            marketDate,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            } = group[0];
        
            const filteredPrice = group.map(item => item.price).filter(value => value !== undefined && value !== null && !isNaN(value));
            const price = _.mean(filteredPrice);
            
            const high = _.maxBy(group, record => (record.high !== null ? record.high : -Infinity)).high;
            const low = _.minBy(group, record => (record.low !== null ? record.low : Infinity)).low;
            
            const filteredOpen = group.map(item => item.open).filter(value => value !== undefined && value !== null && !isNaN(value));
            const open = _.mean(filteredOpen);
            const filteredClose = group.map(item => item.close).filter(value => value !== undefined && value !== null && !isNaN(value));
            const close = _.mean(filteredClose);
            const markState = getLowestDenominatorMarkState(group)

            return {
            termId,
            marketDate,
            termName,            
            currencyId,
            marketId,
            marketName,
            priceBasisId,
            uomId,            
            price,
            high,
            low,
            open,
            close,
            markState,
            };
        });


    return aggregatedMarks
}

export function calculateAveragePrice(group) {
  const validPrices = _.map(group, record => record.price).filter(price => price !== null);
  if (validPrices.length > 0) {
    return _.mean(validPrices);
  } else {
    const validBids = _.map(group, record => record.bid).filter(bid => bid !== null);
    const validOffers = _.map(group, record => record.offer).filter(offer => offer !== null);
    if (validBids.length > 0 && validOffers.length > 0) {
      const highBid = _.maxBy(validBids, bid => (bid !== null ? bid: -Infinity));
      const lowOffer = _.minBy(validOffers, offer => (offer !== null ? offer : Infinity));
      return (highBid + lowOffer) / 2;
    } else {
      return null;
    }
  }
};

export function getLowestDenominatorMarkState(group) {
  if (group.filter(x => x.markState === "Rejected").length> 0)
  {
    return "Rejected"
  }
  if (group.filter(x => x.markState === "Submitted").length> 0)
  {
    return "Submitted"
  }
  if (group.filter(x => x.markState === "Approved").length> 0)
  {
    return "Approved"
  }
};