import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

class MarkCell extends Component {
    state = 
    {
        showModal: false, // Set the initial state to show the modal
        price: null,
        bid: null,
        offer: null
    };

    async componentDidMount() 
    {
        const {mark} = this.props;
        this.setState({price: mark.price, bid: mark.bid, offer: mark.offer})
    }

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.mark.price !== this.props.mark.price ||
            previousProps.mark.bid !== this.props.mark.bid  ||
            previousProps.mark.offer !== this.props.mark.offer 
        )
        {
            const {mark} = this.props;
            this.setState({price: mark.price, bid: mark.bid, offer: mark.offer})
        }
    };

    // handleClose = () => {
    //   this.setState({ showModal: false });
    // };
    
    // handleOpen = () => {
    //   this.setState({ showModal: true });
    // };

    handleUpdate = (data) => {
        const {price, bid, offer} = data;
        this.setState({price: price, bid: bid, offer: offer, showModal: false})
    }

    render() {         
        const { price, bid, offer} = this.state;

        const {
            mark,
            pricePrecision,
            currencySymbol            
        } = this.props; 

        //Show the price if populated, bid/offer if not, and nothing if neither of those are popualted.
        const cellValue= price ? currencySymbol+Number(price).toFixed(pricePrecision) : (bid ? currencySymbol+Number(bid).toFixed(pricePrecision): "") +((bid || offer) ? "/" : "-")+(offer? currencySymbol+Number(offer).toFixed(pricePrecision) : "");
        const markStateClass = mark.markState === "Submitted" ? "submitted" :  mark.markState === "Rejected" ? "rejected" : mark.markState === "Approved" ? "approved" : "";

        return (
            <div>
                <div onClick={this.handleOpen} className={"mark-cell clickable " + markStateClass}>
                    {cellValue}
                </div>
            </div>
        );
    }
}
 
export default withTranslation(["marketdata"])(MarkCell);