import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"trade/";

export async function getTrades() {
    const {data: Trades} = await http.get(apiEndpoint);
    return Trades;
}

export async function getTrade(tradeId) {
  const {data: Trade} = await http.get(apiEndpoint+tradeId);
  return Trade;
}

export async function getTradesByMarket(marketId, organizationId, tradeDate, toTradeDate=null) {
    const {data: Trades} = await http.get(apiEndpoint+"market/"+marketId+"/"+organizationId+"/"+tradeDate+"/"+(toTradeDate??""));
  return Trades;
}

export async function getAggregateTradesByMarket(marketId, tradeDate, toTradeDate=null) {
  const {data: Trades} = await http.get(apiEndpoint+"aggregate/"+marketId+"/"+tradeDate+"/"+(toTradeDate??""));
  return Trades;
}

export async function getTradesForReview(marketDate) {
  const {data: Trades} = await http.get(apiEndpoint+"review/"+marketDate);
  return Trades;
}

export async function addTrade(trade) {  
  const {data: Trade} = await http.post(apiEndpoint, trade);
  return Trade;
}

export async function updateTrade(trade) {
  const {data: Trade} = await http.put(apiEndpoint, trade);
  return Trade;
}

export async function approveTrade(tradeId, event) {
  const {data: Trade} = await http.put(apiEndpoint+"approve/"+tradeId, event);
  return Trade;
}

export async function rejectTrade(tradeId, event) {
  const {data: Trade} = await http.put(apiEndpoint+"reject/"+tradeId, event);
  return Trade;
}

export async function deleteTrade(tradeId) {
  return await http.delete(apiEndpoint+tradeId);
}

export async function getTradeHistory(tradeId) {
  const {data: TradeHistory} = await http.get(apiEndpoint+"audit/"+tradeId);
  return TradeHistory;
}

export async function getUnapprovedTradeCount() {
  const {data: UnapprovedTradeCount} = await http.get(apiEndpoint+"GetUnapprovedCount");
  return UnapprovedTradeCount;
}
