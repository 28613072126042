import React, { Component } from "react";
import _ from "lodash";
import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import Loading from "../common/components/loading/loading";
import { getMarketGroupMarkets } from "../common/services/markets/marketGroupMarketService";
import { withTranslation } from "react-i18next";

class MarketSelector extends Component {
  state = {
    markets: [],
    products: [],

    isLoading: true,
  };

  async componentDidMount() {
    const {
      userOrganizations,
      bypassSubscriptions,
      contributeOnly,
      openDefaultTab,
    } = this.props;

    const subscriptions = this.props.userAccountSubscriptionsByUserAccount

    //Ensure no latent subscriptions from organizations the user no longer belongs to:
    const filteredSubscriptions = subscriptions && subscriptions.filter(
      (s) =>
        userOrganizations.includes(s.organizationId) &&
        s.active === true &&
        (s.contributor || !contributeOnly)
    );

    //Simply cannot figure out why FlexLayout freaks out and throws a cryptic error if I try to get this value from props.    
    const marketGroups = (await getMarketGroupMarkets()).filter(
    //const marketGroups = this.props.marketGroupMarkets && this.props.marketGroupMarkets.filter(
      (m) => m.active
    );

    //Filter the markets by Subscription
    const markets = this.props.marketsWithAttributes && this.props.marketsWithAttributes.filter(
      (m) => m.marketAttributes.length > 0 && m.active
    );

    const subscribedItems = filteredSubscriptions && filteredSubscriptions.map((x) => x.subscribableId);

    const marketGroupMarkets = marketGroups && subscribedItems && marketGroups.filter((m) =>
      subscribedItems.includes(m.marketGroupId)
    ).map((x) => x.marketId);

    const filteredMarkets = markets && subscribedItems && marketGroupMarkets && markets.filter((m) =>
      subscribedItems.includes(m.marketId)
      || marketGroupMarkets.includes(m.marketId)
    );

    //Filter the Products by Market
    const products = this.props.products && this.props.products.filter((p) => p.active);
    const subscribedProducts = filteredMarkets && filteredMarkets.map((x) => x.productId);
    const filteredProducts = products && subscribedItems && products.filter((m) =>
      subscribedProducts.includes(m.productId)
    );

    this.setState({
      markets: bypassSubscriptions ? markets : filteredMarkets,
      products: bypassSubscriptions ? products : filteredProducts,
      isLoading: false,
    });

    if (openDefaultTab && markets) {
      this.handleMarketSelected(markets[0]);
    }
  }

  handleMarketSelected = (market) => {
    this.props.onClick(market);
  };

  render() {
    const { markets, products, isLoading } = this.state;

    const productGroups = _.groupBy(products, "commodityName");

    if (isLoading) return <Loading />;

    return (
      <div>
        <Accordion data-bs-theme="dark" flush alwaysOpen>
          {_.map(productGroups, (group, commodityName) => (
            <Accordion.Item key={commodityName} eventKey={commodityName}>
              <Accordion.Header>{commodityName}</Accordion.Header>
              <Accordion.Body className="AccordionBodyL1">
                {group.map((product, index) => (
                  <Accordion.Item
                    key={product.productId}
                    eventKey={product.productId}
                  >
                    <Accordion.Header>{product.name}</Accordion.Header>
                    <Accordion.Body className="AccordionBodyL2">
                      <ListGroup data-bs-theme="dark">
                        {markets && markets
                          .filter(
                            (market) => market.productId === product.productId
                          )
                          .map((market, index) => (
                            <ListGroup.Item
                              key={index}
                              action
                              onClick={() => this.handleMarketSelected(market)}
                            >
                              {market.venueName}
                            </ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarketSelector);
