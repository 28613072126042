import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"alias/";

export async function getAliases() {
  const {data: Aliases} = await http.get(apiEndpoint);
  return Aliases;
}

export async function getAlias(aliasId) {
  const {data: Alias} = await http.get(apiEndpoint+aliasId);
  return Alias;
}

export async function getAliasesByProduct(productId) {
  const {data: Alias} = await http.get(apiEndpoint+"ByProduct/"+productId);
  return Alias;
}

export async function getAliasByOrganization(organizationId) {
  const {data: Alias} = await http.get(apiEndpoint+"ByOrganization/"+organizationId);
  return Alias;
}

export async function addAlias(alias) {  
  const {data: Alias} = await http.post(apiEndpoint, alias);
  return Alias;
}

export async function updateAlias(alias) {
  const {data: Alias} = await http.put(apiEndpoint, alias);
  return Alias;
}

export async function deleteAlias(aliasId) {
  return await http.delete(apiEndpoint+aliasId);
}

export async function getAliasHistory(aliasId) {
  const {data: AliasHistory} = await http.get(apiEndpoint+"audit/"+aliasId);
  return AliasHistory;
}
