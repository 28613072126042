import React from "react";
import { usePermitted } from "../../common/components/permissions/permissions";
import { useKeycloak } from "@react-keycloak/web";
import ReviewMarks from "./reviewMarks";

const ReviewMarksAuthWrapper = () => {
  const { keycloak, initialized } = useKeycloak();
  const canApproveMarks = usePermitted(["api-approve-marks"]);
  const userAccountId =
    initialized && keycloak.tokenParsed.OriginatingUserAccountID;

  return (
    <ReviewMarks
      canApproveMarks={canApproveMarks}
      userAccountId={userAccountId}
    />
  );
};

export default ReviewMarksAuthWrapper;
