import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"qualityAttributeCommodity/";

export async function getAttributeCommodities() {
  const {data: AttributeCommodities} = await http.get(apiEndpoint);
  return AttributeCommodities;
}

export async function getQualityAttributeCommodity(qualityAttributeCommodityId) {
  const {data: QualityAttributeCommodity} = await http.get(apiEndpoint+qualityAttributeCommodityId);
  return QualityAttributeCommodity;
}

export async function getQualityAttributeCommodityByAttribute(qualityAttributeId) {
  const {data: QualityAttributeCommodity} = await http.get(apiEndpoint+"ByQualityAttribute/"+qualityAttributeId);
  return QualityAttributeCommodity;
}

export async function getQualityAttributeCommoditiesByCommodity(commodityId) {
  const {data: QualityAttributeCommodity} = await http.get(apiEndpoint+"ByCommodity/"+commodityId);
  return QualityAttributeCommodity;
}

export async function assignQualityAttributeCommodity(qualityAttributeCommodity) {  
  const {data: QualityAttributeCommodity} = await http.post(apiEndpoint, qualityAttributeCommodity);
  return QualityAttributeCommodity;
}

export async function revokeQualityAttributeCommodity(commodityId, qualityAttributeId) {
  return await http.delete(apiEndpoint+commodityId+"/"+qualityAttributeId);
}

export async function getQualityAttributeCommodityHistory(qualityAttributeCommodityId) {
  const {data: QualityAttributeCommodityHistory} = await http.get(apiEndpoint+"audit/"+qualityAttributeCommodityId);
  return QualityAttributeCommodityHistory;
}
