import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"termSchedule/";

export async function getTermSchedules() {
    const {data: TermSchedules} = await http.get(apiEndpoint);
    return TermSchedules;
}

export async function getTermSchedule(termScheduleId) {
  const {data: TermSchedule} = await http.get(apiEndpoint+termScheduleId);
  return TermSchedule;
}

export async function addTermSchedule(termSchedule) {  
  const {data: TermSchedule} = await http.post(apiEndpoint, termSchedule);
  return TermSchedule;
}

export async function updateTermSchedule(termSchedule) {
  const {data: TermSchedule} = await http.put(apiEndpoint, termSchedule);
  return TermSchedule;
}

export async function deleteTermSchedule(termScheduleId) {
  return await http.delete(apiEndpoint+termScheduleId);
}

export async function getTermScheduleHistory(termScheduleId) {
  const {data: TermScheduleHistory} = await http.get(apiEndpoint+"audit/"+termScheduleId);
  return TermScheduleHistory;
}
