import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../common/components/table/table';
import Loading from '../../common/components/loading/loading';
import { pivotQualityAttributes} from "../../common/services/products/productUtilities";
import { getSpotByTermSchedule} from "../../common/services/markets/termService";
import { joinArrays } from "../../common/services/utilities";
import MarkCell from "./markCell";
import CandlestickChart from '../../common/components/d3/candlestickChart';
import { SHA256 } from 'crypto-js';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {aggregateMarks} from "../../common/services/marketdata/marketDataUtilities";

class MarksHistoryTable extends Component {

    constructor(props) {
        super(props);
    
        this.rowHover = this.rowHover.bind(this);
        this.rowExit = this.rowExit.bind(this);
      }

    state = {
        marks: [],
        qualityAttributeValueHash: null,
        candlestickMarksRaw: [],
        startDate: null,
        endDate: null,
        yMax: null,
        yMin: null,
        columns: [],
        pageSize: 20,
        currentPage: 1,
        sortColumn: {path: 'id', order: 'desc'},
        isLoading: true,
   };

    async componentDidMount() {
        await this.initializeTable(this.props);
    };

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.fromDate !== this.props.fromDate ||
            previousProps.toDate !== this.props.toDate ||
            !_.isEqual(previousProps.marks, this.props.marks)            
        )
        {
            await this.initializeTable(this.props);
        }
    };

    rowHover(data)
    {        
        this.setState({qualityAttributeValueHash: data.qualityAttributeValueHash})
    }

    rowExit(data)
    {
        this.setState({qualityAttributeValueHash: null})
    }


    async initializeTable(passedProps)
    {
        const {marks, product, fromDate, toDate, termScheduleId } = passedProps;

        const terms = await getSpotByTermSchedule(termScheduleId, fromDate.toLocaleDateString('fr-CA'), toDate.toLocaleDateString('fr-CA'));

        const aggregatedMarks= aggregateMarks(marks);

        const columns = this.mapToColumns(product, terms);

        const pivotedData = pivotQualityAttributes(product);

        const joinedData = this.joinMarks(pivotedData, aggregatedMarks)
     
        const candlestickMarksRaw = marks.filter (m => terms.map(t => t.marketDate+t.termId).includes(m.marketDate+m.termId));

        this.setState({marks: joinedData, candlestickMarksRaw: candlestickMarksRaw, startDate: new Date(fromDate), endDate: new Date(toDate), columns: columns, isLoading:false});

    };

    mapToColumns(products, terms)
    {
        const
        {
            organizationId,
            marketId,            
            marketDate,
            historicalReportingPeriodicity,
            priceBasisId,
            currencyId,
            uomId,
            pricePrecision,
            volumePrecision,
            currencySymbol  
        } = this.props;

        var columns = [
        {path: 'shortName', label: this.props.t('MarketDataProduct')}, //Set the product name as the first column
        ...products.qualityAttributes
        .filter(qualityAttribute => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
        .map(qualityAttribute => ( //Add one new column for each qualityAttribute
            {
                path: qualityAttribute.qualityAttributeId,
                label: qualityAttribute.qualityAttributeName,
            }
        )),
        ...terms
        .sort((a, b) => new Date(a.startDate) < new Date(b.startDate) ? 1 : -1)
        .map(term =>( //Add one new column for each term
            {
                path: term.marketDate+term.termId,
                label: term.marketDate.slice(0,10)+ (historicalReportingPeriodicity === "Hour" ? "H"+term.marketDate.slice(11,13): ""),
                //className: "clickable",            
                content: data =>
                <MarkCell                         
                    termId={term.termId} 
                    organizationId={organizationId}
                    marketId={marketId}
                    priceBasisId={priceBasisId}
                    currencyId={currencyId}
                    uomId={uomId}
                    pricePrecision={pricePrecision}
                    volumePrecision={volumePrecision}
                    currencySymbol={currencySymbol}                    
                    marketDate={marketDate}
                    mark={data[term.marketDate+term.termId] ?? {markId: uuid(), eventId: uuid(), active: true}} 
                    qualityAttributeValues ={data.qualityAttributeValues} 
                    shortName={data["shortName"]}
                    isNew={data[term.termId] ? false: true}
                />
            }
        ))]

        return columns;
    }

    joinMarks(pivotedData, marks)
    {        
        const dataWithHashes = pivotedData.map(obj =>
        {
            // Extract and sort the values of the QualityAttributeValueId field
            const sortedQualityAttributeValues = obj.qualityAttributeValues
                .map(qualityAttributeValue => qualityAttributeValue.qualityAttributeValueId)  // Extract the values
                .filter(value => value !== undefined)  // Filter out undefined values
                .sort();  // Sort the values

                // Concatenate the sorted values
                const concatenatedValues = sortedQualityAttributeValues.join('');
                const qualityAttributeValueHash = SHA256(concatenatedValues).toString().toUpperCase();

                return {...obj, qualityAttributeValueHash}
        });

        //Pivot the marks
        const groupedByQualityAttributeValueHash = marks.reduce((result, obj) => {
            const { qualityAttributeValueHash, termId, marketDate, ...rest } = obj;
        
            if (!result[qualityAttributeValueHash]) {
            result[qualityAttributeValueHash] = {};
            }
            
            //Add a spot key so we don't mix marks from same instrument/different days
            const spotKey=marketDate+termId;
            result[qualityAttributeValueHash][spotKey] = { ...rest};
        
            return result;
        }, {});

        const pivotedMarks = Object.entries(groupedByQualityAttributeValueHash).map(([qualityAttributeValueHash, terms]) => ({
            qualityAttributeValueHash,
            ...terms
        }));

        const joinedData = joinArrays(dataWithHashes,pivotedMarks,'qualityAttributeValueHash');
        return joinedData
    }

    getCandleStickMarks()
    {
        const {candlestickMarksRaw, qualityAttributeValueHash} = this.state;        

        const candlestickMarks = candlestickMarksRaw.filter(x => x.qualityAttributeValueHash === (qualityAttributeValueHash ?? x.qualityAttributeValueHash));

        const yMin = candlestickMarksRaw.reduce((minValue, currentItem) => {
            return Math.min(minValue, currentItem.price ?? currentItem.bid ?? currentItem.offer);
          }, Number.POSITIVE_INFINITY);


        const yMax = candlestickMarksRaw.reduce((maxValue, currentItem) => {
            return Math.max(maxValue, currentItem.price ?? currentItem.offer ?? currentItem.bid);
          }, Number.NEGATIVE_INFINITY);

         return {
            candlestickMarks: candlestickMarks,
            yMax: yMax > 0  ? yMax * 1.05 : yMax * 0.95, //scale to within 20% for aesthetic reasons
            yMin: yMin > 0 ? yMin * 0.95 : yMin * 1.05 //scale to within 20% for aesthetic reasons
        };
    }



    render() { 
        const {sortColumn, isLoading, columns, marks, startDate, endDate} = this.state;

        const {yMin, yMax, candlestickMarks } = this.getCandleStickMarks();

        if (isLoading) return (<Loading/>);

        const { currencySymbol, pricePrecision, historicalReportingPeriodicity } = this.props;

        return (
            <div className="container-fluid">
                <div className="row market-data-graph">
                    <CandlestickChart data={candlestickMarks ?? []} historicalReportingPeriodicity={historicalReportingPeriodicity} startDate={startDate} endDate={endDate} yMin={yMin} yMax={yMax} currencySymbol={currencySymbol} pricePrecision={pricePrecision}/>
                </div>
                <div className="row marks-table">
                    <Table className="table-marks" columns={columns} sortColumn = {sortColumn} data={marks} onMouseEnter={this.rowHover} onMouseLeave={this.rowExit} valueProperty="qualityAttributeValueHash"/>
                </div>                                
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(MarksHistoryTable)