import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"qualityAttributeValue/";

export async function getQualityAttributeValues() {
    const {data: AttributeValues} = await http.get(apiEndpoint);
    return AttributeValues;
}

export async function getQualityAttributeValue(qualityAttributeValueId) {
  const {data: AttributeValue} = await http.get(apiEndpoint+qualityAttributeValueId);
  return AttributeValue;
}

export async function getQualityAttributeValueByAttribute(qualityAttributeId) {
  const {data: AttributeValue} = await http.get(apiEndpoint+"ByAttribute"+qualityAttributeId);
  return AttributeValue;
}

export async function addQualityAttributeValue(qualityAttributeValue) {  
  const {data: AttributeValue} = await http.post(apiEndpoint, qualityAttributeValue);
  return AttributeValue;
}

export async function updateQualityAttributeValue(qualityAttributeValue) {
  const {data: AttributeValue} = await http.put(apiEndpoint, qualityAttributeValue);
  return AttributeValue;
}

export async function deleteQualityAttributeValue(qualityAttributeValueId) {
  return await http.delete(apiEndpoint+qualityAttributeValueId);
}

export async function getQualityAttributeValueHistory(qualityAttributeValueId) {
  const {data: AttributeValueHistory} = await http.get(apiEndpoint+"audit/"+qualityAttributeValueId);
  return AttributeValueHistory;
}
