import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import QualityAttributes from "./qualityAttributes";
import QualityAttribute from "./qualityAttribute";
import { withTranslation } from "react-i18next";

class QualityAttributeAdmin extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 50,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("QualityAttributes"),
              enableClose: false,
              component: "QualityAttributes",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 50,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
            // {
            //   type: "tab",
            //   name: "User",
            //   visible: false,
            //   component: "Placeholder"
            // }
          ],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleQualityAttributeSelect = (
    QualityAttribute,
    submitCallbackFunction,
    isNew
  ) => {
    const { qualityAttributeId, name } = QualityAttribute;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "QualityAttribute",
            name: `${name}`,
            id: `${qualityAttributeId}`,
            config: { qualityAttributeId, submitCallbackFunction, isNew },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(
        FlexLayout.Actions.selectTab(qualityAttributeId)
      );
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const QualityAttributeId = node.getId();

    if (componentId === "QualityAttributes") {
      return (
        <QualityAttributes
          onClick={this.handleQualityAttributeSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "QualityAttribute") {
      const { qualityAttributeId, submitCallbackFunction, isNew } =
        node.getConfig();
      return (
        <QualityAttribute
          qualityAttributeId={qualityAttributeId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["products"])(QualityAttributeAdmin);
