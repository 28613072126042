import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"mark/";

export async function getMarks() {
    const {data: Marks} = await http.get(apiEndpoint);
    return Marks;
}

export async function getMark(markId) {
  const {data: Mark} = await http.get(apiEndpoint+markId);
  return Mark;
}

export async function getMarksByMarket(marketId, marketDate, organizationId) {
  const {data: Marks} = await http.get(apiEndpoint+"market/"+marketId+"/"+marketDate+"/"+organizationId);
  return Marks;
}

export async function getAggregateMarksByMarket(marketId, marketDate, toMarketDate=null) {
  const {data: Marks} = await http.get(apiEndpoint+"aggregate/"+marketId+"/"+marketDate+"/"+toMarketDate??"");
  return Marks;
}

export async function getAggregateForwardMarksByMarket(marketId, marketDate, toMarketDate=null) {
  const {data: Marks} = await http.get(apiEndpoint+"aggregateforward/"+marketId+"/"+marketDate+"/"+toMarketDate??"");
  return Marks;
}

export async function getMarksForReview(marketDate) {
  const {data: Marks} = await http.get(apiEndpoint+"review/"+marketDate);
  return Marks;
}

export async function addMark(mark) {  
  const {data: Mark} = await http.post(apiEndpoint, mark);
  return Mark;
}

export async function updateMark(mark) {
  const {data: Mark} = await http.put(apiEndpoint, mark);
  return Mark;
}

export async function approveMark(updateMarkStatus) {
  const {data: NumOfMarksApproved} = await http.put(apiEndpoint+"approve", updateMarkStatus);
  return NumOfMarksApproved;
}

export async function rejectMark(updateMarkStatus) {
  const {data: NumOfMarksApproved} = await http.put(apiEndpoint+"reject", updateMarkStatus);
  return NumOfMarksApproved;
}

export async function deleteMark(mark) {
  const {data: Mark} = await http.put(apiEndpoint+"delete", mark);
  return Mark;
}

export async function getMarkHistory(markId) {
  const {data: MarkHistory} = await http.get(apiEndpoint+"audit/"+markId);
  return MarkHistory;
}

export async function getUnapprovedMarkCount() {
  const {data: UnapprovedMarkCount} = await http.get(apiEndpoint+"GetUnapprovedCount");
  return UnapprovedMarkCount;
}
