import React, { Component } from 'react';
import FontAwesome from "react-fontawesome";
import Table from '../../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class ProductsTable extends Component {
    columns =[
        {path: 'name', label: this.props.t("ProductName")},
        {path: 'shortName', label: this.props.t("ProductShortName")},
        {path: 'active', label: this.props.t("Active"), content: product => <FontAwesome name={product.active ? "check" : "times"}/>}
    ];

    // constructor() {
    //     super();
    //     const user = products.getCurrentUser();
    //     if (user && user.isAdmin)
    //         this.columns.push(this.deleteColumn);
    // }

    render() { 
        const {products, onSort, sortColumn, onRowClick} = this.props;
        return (
            <Table className="table table-dark table-sm" columns= {this.columns} sortColumn = {sortColumn} onSort={onSort} data={products} onRowClick={onRowClick} valueProperty="productId"/>
        );
    }
}

export default withTranslation(["products"])(ProductsTable)