import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"qualityAttribute/";

export async function getQualityAttributes() {
    const {data: Attributes} = await http.get(apiEndpoint);
    return Attributes;
}

export async function getQualityAttribute(qualityAttributeId) {
  const {data: Attribute} = await http.get(apiEndpoint+qualityAttributeId);
  return Attribute;
}

export async function addQualityAttribute(qualityAttribute) {  
  const {data: Attribute} = await http.post(apiEndpoint, qualityAttribute);
  return Attribute;
}

export async function updateQualityAttribute(qualityAttribute) {
  const {data: Attribute} = await http.put(apiEndpoint, qualityAttribute);
  return Attribute;
}

export async function deleteQualityAttribute(qualityAttributeId) {
  return await http.delete(apiEndpoint+qualityAttributeId);
}

export async function getQualityAttributeHistory(qualityAttributeId) {
  const {data: AttributeHistory} = await http.get(apiEndpoint+"audit/"+qualityAttributeId);
  return AttributeHistory;
}
