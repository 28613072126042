import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_USER_ACCOUNT_API+"useraccount/";

export async function getUserAccounts() {
  const {data: userAccounts} = await http.get(apiEndpoint);
  return userAccounts;
}

export async function getUserAccount(userAccountId) {
  const {data: userAccount} = await http.get(apiEndpoint+userAccountId);
  return userAccount;
}

export async function addUserAccount(userAccount) {  
  const {data: UserAccount} = await http.post(apiEndpoint, userAccount);
  return UserAccount;
}

export async function updateUserAccount(userAccount) {
  const {data: UserAccount} = await http.put(apiEndpoint, userAccount);
  return UserAccount;
}

export async function deleteUserAccount(userAccountId) {
  return await http.delete(apiEndpoint+userAccountId);
}

export async function setPassword(userAccountId) {
  return await http.put(apiEndpoint+"SetPassword/"+userAccountId);
}

export async function getUserAccountHistory(userAccountId) {
  const {data: userAccountHistory} = await http.get(apiEndpoint+"audit/"+userAccountId);
  return userAccountHistory;
}
