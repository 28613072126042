import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "../../../common/services/utilities";

class TermTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Name") },
    { path: "termScheduleName", label: this.props.t("TermSchedule") },
    {
      path: "startDate",
      label: this.props.t("StartDate"),
      content: (term) => ((term.startDate.slice(-8) === "00:00:00" && term.endDate.slice(-8) === "00:00:00") ? formatDate(term.startDate) : formatDateTime(term.startDate))
    },
    {
      path: "endDate",
      label: this.props.t("EndDate"),
      content: (term) => ((term.startDate.slice(-8) === "00:00:00" && term.endDate.slice(-8) === "00:00:00") ? formatDate(term.endDate) : formatDateTime(term.endDate)),
    },

    {
      path: "active",
      label: this.props.t("Active"),
      content: (term) => <FontAwesome name={term.active ? "check" : "times"} />,
    },
  ];

  // constructor() {
  //     super();
  //     const user = markets.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { terms, onSort, sortColumn, onRowClick } = this.props;

    return (
      <Table
        className="table table-dark table-sm"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={terms}
        onRowClick={onRowClick}
        valueProperty="termId"
      />
    );
  }
}

export default withTranslation(["markets"])(TermTable);
