import React, { Component } from 'react';
import uuid from'react-uuid';
import { toast } from "react-toastify";
import { getOrganizations} from "../../../common/services/auth/organizationService";
import { getOrganizationsByUser, assignOrganization, revokeOrganization } from "../../../common/services/auth/userAccountOrganizationService";
import Loading from '../../../common/components/loading/loading';
import DualListBox from '../../../common/components/form/dualListBox';
import { withTranslation } from 'react-i18next';

class UserAccountOrganizations extends Component {

    state = { 
        options: [],
        selected: [],        
        isLoading: true
     } 

     async componentDidMount() {
        const {userAccountId} = this.props;
        const allOrganizations = await getOrganizations();
        const organizations = await getOrganizationsByUser(userAccountId);

        this.setState(
            {   
                options: this.mapToOptionsViewModel(allOrganizations)
                , selected: this.mapToSelectedViewModel(organizations)
                , isLoading: false
            });       
    };

    mapToOptionsViewModel(organizations) {
        return organizations.map(organization => (
            {
                value: organization.organizationId,
                label: organization.name
            })
         );
    }

    mapToSelectedViewModel(organizations) {
        return organizations.map(organization => (
                organization.organizationId
            )
        );
    }

    async handleChange(selected, selection, controlKey)
    {      
        const {userAccountId} = this.props;
        const eventId = uuid();

        if(controlKey !== 'available'  && controlKey !== 'selected')
        {
            toast.warning(this.props.t("UnexpectedError"));
            return null;
        }

        // set the initial state,will clean up at the end if there are failures.
        this.setState({selected: selected});

        for (const organizationId of selection)
        {
            if(controlKey==="available")
            {
                try
                {
                    await assignOrganization(userAccountId, {organizationId: organizationId, eventId: eventId})
                    toast.success(this.props.t("OrganizationAdded"));
                }
                catch (ex)
                {
                    console.log(ex);
                    var index = selected.indexOf(organizationId);
                    delete selected[index];
                    toast.warning(this.props.t("OrganizationAdditionFailed"));
                }
            }
            else if (controlKey==="selected")
            {
                try
                {
                    await revokeOrganization(userAccountId, {organizationId: organizationId, eventId: eventId})
                    toast.success(this.props.t("OrganizationRemoved"));
                }
                catch (ex)
                {
                    console.log(ex);
                    selected.push(organizationId);
                    toast.warning(this.props.t("OrganizationRemovalFailed"));
                }
            }
        }

        //Clean up with the actual outcome state
        this.setState({selected: selected});
    };

    render() { 
        const {options, selected, isLoading} = this.state
        const {allowEdit} = this.props;
        if (isLoading) return (<Loading/>);

        return (
            <div>
                <DualListBox canFilter
                    className = "rdl-hide-move-all"
                    disabled= {!allowEdit}
                    options={options}
                    selected={selected}
                    onChange={(selected, selection, controlKey)=>this.handleChange(selected, selection, controlKey)}
                />
            </div>    
        );
    }
}
 
export default withTranslation(["auth"])(UserAccountOrganizations);