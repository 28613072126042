import React, { Component } from "react";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";
import { getOrganizationsByUser } from "../../common/services/auth/userAccountOrganizationService";
import { getOrganizations } from "../../common/services/auth/organizationService";
import { getTermsByDate } from "../../common/services/markets/termService";
import {
  getMarksByMarket,
  getAggregateMarksByMarket,
  getAggregateForwardMarksByMarket,
} from "../../common/services/marketdata/markService";
import { getMarketGroupMarkets } from "../../common/services/markets/marketGroupMarketService";
import { getProductWithQualityAttributes } from "../../common/services/products/productService";
import { getContributorSubscriptionsByMarket } from "../../common/services/marketdata/organizationSubscriptionService";
import { getUserAccountSubscriptionsByUserAccount } from "../../common/services/marketdata/userAccountSubscriptionService";
import MarksTable from "./marksTable";
import { withTranslation } from "react-i18next";
import ListGroup from "react-bootstrap/ListGroup";
import Select from "../../common/components/form/select";
import Input from "../../common/components/form/input";
import MarksHistoryTable from "../marketData/marksHistoryTable";
import MarksForwardTable from "../marketData/marksForwardTable";
import { priorBusinessDay} from "../../common/services/utilities";

class MarketMarks extends Component {
  state = {
    marketAttributes: [],
    organizations: [],
    marks: [],
    historicalMarks: [],
    forwardMarks: [],
    terms: [],
    fromDate: null,
    toDate: null,
    product: null,
    organizationId: null,
    isLoading: true,
    marketDate: null,
    termScheduleId: null,
    priceBasisId: null,
    currencyId: null,
    uomId: null,
    pricePrecision: 2,
    volumePrecision: 0,
    currencySymbol: "$",
  };

  async componentDidMount() {
    await this.initialize();
  }

  async initialize() {
    this.loadMarks = this.loadMarks.bind(this);

    const { productId, market, allowEditOnBehalf, userAccountId } = this.props;
    const { marketId } = market;

    const marketDate = new Date().toLocaleDateString("fr-CA");

    const {venue, termSchedule} =this.props;
    const {forwardReportingPeriodicity, historicalReportingPeriodicity} = termSchedule;
    const [product] = await Promise.all([
      getProductWithQualityAttributes(productId),
    ]);

    //Build the organization select list.
    const myOrganizations = (
      await getOrganizationsByUser(userAccountId)
    ).filter((o) => o.active);
    const allOrganizations = allowEditOnBehalf
      ? (await getOrganizations())
          .filter((o) => o.active)
          .map(({ organizationId, name, active }) => ({
            organizationId,
            name,
            active,
          }))
      : [];

    const mySubscriptions = await getUserAccountSubscriptionsByUserAccount(
      userAccountId
    );

    const marketGroups = (await getMarketGroupMarkets()).filter(
      (m) => m.active
    );

    const subscribedItems = mySubscriptions.map((x) => x.subscribableId);

    const marketGroupMarkets = marketGroups
      .filter((m) => subscribedItems.includes(m.marketGroupId))
      .map((x) => x.marketId);

    const myMarketSubscriptions = mySubscriptions
      .filter(
        (s) =>
          subscribedItems.includes(marketId) ||
          marketGroupMarkets.includes(marketId)
      )
      .map((x) => x.organizationId);

    const allSubscriptions = allowEditOnBehalf
      ? (await getContributorSubscriptionsByMarket(marketId)).map(
          (x) => x.organizationId
        )
      : [];

    //Build the full list
    const organizationSuperset = [
      ...myOrganizations.filter(
        (o) =>
          myMarketSubscriptions.includes(o.organizationId) || allowEditOnBehalf
      ),
      ...allOrganizations.filter((o) =>
        allSubscriptions.includes(o.organizationId)
      ),
    ];

    //Remove any duplicates
    const organizations = _.uniqWith(organizationSuperset, _.isEqual);

    const timeZone =
      venue.timeZone +
      " (" +
      Intl.DateTimeFormat("us", {
        timeZoneName: "short",
        timeZone: venue.timeZone,
      })
        .formatToParts()
        .find((i) => i.type === "timeZoneName").value +
      ")";
    this.setState({
      product: product,
      organizations: organizations,
      organizationId: organizations[0].organizationId,
      marketDate: marketDate,
      marketAttributes: market.marketAttributes,
      termScheduleId: market.termScheduleId,
      termSchedule: termSchedule,
      forwardReportingPeriodicity: forwardReportingPeriodicity,
      historicalReportingPeriodicity: historicalReportingPeriodicity,
      priceBasisId: market.marketAttributes[0].priceBasisId,
      currencyId: market.marketAttributes[0].currencyId,
      uomId: market.marketAttributes[0].uomId,
      pricePrecision: market.marketAttributes[0].pricePrecision ?? 2,
      volumePrecision: market.marketAttributes[0].volumePrecision ?? 0,
      currencySymbol: market.marketAttributes[0].currencySymbol ?? "$",
      timeZone: timeZone,
      isLoading: false,
    });

    if (marketDate && market.termScheduleId) {
      await this.loadTerms(market.termScheduleId, marketDate);
    }

    if (marketDate && marketId && organizations) {
      await this.loadMarks(
        marketId,
        marketDate,
        organizations[0].organizationId
      );
    }
    if (
      marketDate &&
      termSchedule.historicalReportingPeriodicity &&
      allowEditOnBehalf
    ) {
      await this.loadMarketData(
        marketDate,
        termSchedule.historicalReportingPeriodicity
      );
    }
  }

  async loadTerms(termScheduleId, marketDate) {
    const terms = await getTermsByDate(termScheduleId, marketDate);

    this.setState({ terms: terms });
  }

  async loadMarks(marketId, marketDate, organizationId) {
    const marks = await getMarksByMarket(marketId, marketDate, organizationId);

    this.setState({ marks: marks });
  }

  async loadMarketData(marketDate, historicalReportingPeriodicity) {
    //Set the fromMarketDate
    const lookbackWindow = historicalReportingPeriodicity === "Hour" ? 1 : 7; // trailing week for Day, trailing 48 hours for Hour, other historical periodicities don't really apply.
    let fromDate = new Date(marketDate);
    fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset());
    fromDate.setDate(fromDate.getDate() - lookbackWindow);

    fromDate = priorBusinessDay(fromDate);

    const curveFromDate = new Date(marketDate);
    curveFromDate.setMinutes(
      curveFromDate.getMinutes() + curveFromDate.getTimezoneOffset()
    );
    curveFromDate.setDate(curveFromDate.getDate() - 1);

    const toDate = new Date(marketDate);
    toDate.setMinutes(toDate.getMinutes() + toDate.getTimezoneOffset());

    this.setState({ fromDate: fromDate, toDate: toDate });

    const { market } = this.props;
    const { marketId } = market;

    //await this.loadTrades(marketId, fromDate, toDate);
    await this.loadDashboardMarks(marketId, fromDate, toDate, curveFromDate);
    //await this.loadMarketPrices(marketId, toDate);
  }

  async loadDashboardMarks(marketId, fromDate, toDate, curveFromDate) {
    const historicalMarks = (
      await getAggregateMarksByMarket(
        marketId,
        fromDate.toLocaleDateString("fr-CA"),
        toDate.toLocaleDateString("fr-CA")
      )
    ).filter((x) => x.markState === "Approved" || x.markState === "Submitted");
    const forwardMarks = (
      await getAggregateForwardMarksByMarket(
        marketId,
        curveFromDate.toLocaleDateString("fr-CA"),
        toDate.toLocaleDateString("fr-CA")
      )
    ).filter((x) => x.markState === "Approved" || x.markState === "Submitted");

    this.setState({
      historicalMarks: historicalMarks,
      forwardMarks: forwardMarks,
    });
  }

  handleMarketDateChange = (marketDate) => {
    this.setState({ marketDate: marketDate });

    const { organizationId, termScheduleId, historicalReportingPeriodicity } =
      this.state;
    const { market, allowEditOnBehalf } = this.props;
    const { marketId } = market;

    (async () => {
      await this.loadMarks(marketId, marketDate, organizationId);
    })();

    (async () => {
      await this.loadTerms(termScheduleId, marketDate);
    })();

    if (allowEditOnBehalf) {
      (async () => {
        await this.loadMarketData(
          marketDate,
          historicalReportingPeriodicity
        );
      })();
    }
  };

  handleOrganizationChange = (organizationId) => {
    this.setState({ organizationId: organizationId });

    const { marketDate } = this.state;
    const { market } = this.props;
    const { marketId } = market;

    (async () => {
      await this.loadMarks(marketId, marketDate, organizationId);
    })();
  };

  handleUpdate = (mark) => {
    var marks = [...this.state.marks];
    const i = marks.findIndex((_mark) => _mark.markId === mark.markId);
    if (i > -1) marks[i] = mark;
    else marks.push(mark);
    this.setState({ marks: marks });

    //Also update the marketData context views
    //This is not working properly on update due to no MarkId on the AggregateMarks model....
    const { allowEditOnBehalf } = this.props;
    if (allowEditOnBehalf) {
      var historicalMarks = [...this.state.historicalMarks];
      const j = historicalMarks.findIndex(
        (_mark) => _mark.markId === mark.markId
      );
      if (j > -1) historicalMarks[j] = mark;
      else historicalMarks.push(mark);
      this.setState({ historicalMarks: historicalMarks });

      var forwardMarks = [...this.state.forwardMarks];
      const k = forwardMarks.findIndex((_mark) => _mark.markId === mark.markId);
      if (k > -1) forwardMarks[k] = mark;
      else forwardMarks.push(mark);
      this.setState({ forwardMarks: forwardMarks });
    }
  };

  handleDelete = (markId) => {
    var marks = [...this.state.marks];
    const i = marks.findIndex((_mark) => _mark.markId === markId);
    if (i > -1) marks.pop(marks[i]);
    this.setState({ marks: marks });

    //Also update the marketData context views
    //This is not working properly on update due to no MarkId on the AggregateMarks model....
    const { allowEditOnBehalf } = this.props;
    if (allowEditOnBehalf) {
      var historicalMarks = [...this.state.historicalMarks];
      const j = historicalMarks.findIndex((_mark) => _mark.markId === markId);
      if (j > -1) historicalMarks.pop(historicalMarks[j]);
      this.setState({ historicalMarks: historicalMarks });

      var forwardMarks = [...this.state.forwardMarks];
      const k = forwardMarks.findIndex((_mark) => _mark.markId === markId);
      if (k > -1) forwardMarks.pop(forwardMarks[k]);
      this.setState({ forwardMarks: forwardMarks });
    }
  };

  handleBulkUpdate = (newMarks) => {
    var marks = [...this.state.marks];
    var historicalMarks = [...this.state.historicalMarks];
    var forwardMarks = [...this.state.forwardMarks];

    for (const mark of newMarks) {
      const i = marks.findIndex((_mark) => _mark.markId === mark.markId);
      if (i > -1) marks[i] = mark;
      else marks.push(mark);

      //Also update the marketData context views
      //This is not working properly on update due to no MarkId on the AggregateMarks model....
      const { allowEditOnBehalf } = this.props;
      if (allowEditOnBehalf) {
        const j = historicalMarks.findIndex(
          (_mark) => _mark.markId === mark.markId
        );
        if (j > -1) historicalMarks[j] = mark;
        else historicalMarks.push(mark);

        const k = forwardMarks.findIndex(
          (_mark) => _mark.markId === mark.markId
        );
        if (k > -1) forwardMarks[k] = mark;
        else forwardMarks.push(mark);
      }
    }

    this.setState({ marks: marks });
    this.setState({ historicalMarks: historicalMarks });
    this.setState({ forwardMarks: forwardMarks });
  };

  handlemarketAttributeSelect = (marketAttribute) => {
    this.setState({
      priceBasisId: marketAttribute.priceBasisId,
      currencyId: marketAttribute.currencyId,
      uomId: marketAttribute.uomId,
    });
  };

  getFilteredMarks() {
    const { marks, priceBasisId, uomId, currencyId } = this.state;

    const filteredMarks = marks.filter(
      (x) =>
        x.priceBasisId === priceBasisId &&
        x.uomId === uomId &&
        x.currencyId === currencyId
    );

    return { marks: filteredMarks };
  }

  getFilteredHistoricalMarks() {
    const { historicalMarks, priceBasisId, uomId, currencyId } = this.state;

    const filteredMarks = historicalMarks.filter(
      (x) =>
        x.priceBasisId === priceBasisId &&
        x.uomId === uomId &&
        x.currencyId === currencyId
    );

    return { historicalMarks: filteredMarks };
  }

  getFilteredForwardMarks() {
    const { forwardMarks, priceBasisId, uomId, currencyId } = this.state;

    const filteredMarks = forwardMarks.filter(
      (x) =>
        x.priceBasisId === priceBasisId &&
        x.uomId === uomId &&
        x.currencyId === currencyId
    );

    return { forwardMarks: filteredMarks };
  }

  render() {
    const {
      organizations,
      product,
      terms,
      organizationId,
      timeZone,
      marketAttributes,
      termScheduleId,
      historicalReportingPeriodicity,
      forwardReportingPeriodicity,
      toDate,
      fromDate,
      isLoading,
      marketDate,
      priceBasisId,
      currencyId,
      uomId,
      pricePrecision,
      volumePrecision,
      currencySymbol,
    } = this.state;

    if (isLoading) return <Loading />;

    const {
      t,
      allowEdit,
      allowOverrideMarks,
      allowEditOnBehalf,
      allowGenerateMarks,
      allowCopyMarks,
      productId,
      market,
    } = this.props;
    const { marketId } = market;

    const { marks } = this.getFilteredMarks();
    const { forwardMarks } = this.getFilteredForwardMarks();
    const { historicalMarks } = this.getFilteredHistoricalMarks();

    var date = new Date();

    const endDate = date.toLocaleDateString("fr-CA");

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <ListGroup
              value={marketAttributes[0]}
              horizontal="sm"
              data-bs-theme="dark"
            >
              {marketAttributes.map((attribute, index) => (
                <ListGroup.Item
                  className="market-attribute-selector"
                  key={index}
                  action
                  active={priceBasisId === attribute.priceBasisId}
                  onClick={() => this.handlemarketAttributeSelect(attribute)}
                >
                  {attribute.priceBasisName +
                    " (" +
                    attribute.currencyName +
                    "/" +
                    attribute.uomName +
                    ")"}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>{" "}
          <div className="col-md-3"></div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <Input
              data-bs-theme="dark"
              type="date"
              max={endDate}
              onChange={(e) =>
                this.handleMarketDateChange(e.currentTarget.value)
              }
              //name = "name"
              value={marketDate}
              label={t("MarketDate")}
            />
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-2 mt-auto">
            {t("TimeZone")}:<br />
            {timeZone}
          </div>
          <div className="col-md-2">
            <Select
              data-bs-theme="dark"
              idField="organizationId"
              name="name"
              disabled={Object.keys(organizations).length < 2}
              value={organizationId}
              label={t("Submitter")}
              options={organizations}
              onChange={(e) =>
                this.handleOrganizationChange(e.currentTarget.value)
              }
            />
          </div>
        </div>
        <div className="col marks-table">
          <MarksTable
            submitCallbackFunction={this.handleUpdate}
            allowEdit={allowEdit}
            allowOverrideMarks={allowOverrideMarks}
            allowEditOnBehalf={allowEditOnBehalf}
            allowGenerateMarks={allowGenerateMarks}
            allowCopyMarks={allowCopyMarks}
            product={product}
            terms={terms}
            marks={marks}
            organizationId={organizationId}
            productId={productId}
            marketId={marketId}
            termScheduleId={termScheduleId}
            marketDate={marketDate}
            priceBasisId={priceBasisId}
            currencyId={currencyId}
            uomId={uomId}
            pricePrecision={pricePrecision}
            volumePrecision={volumePrecision}
            currencySymbol={currencySymbol}
            loadMarks={this.loadMarks}
            updateGeneratedMarks={this.handleBulkUpdate}
            handleDelete={this.handleDelete}
          />
        </div>
        {allowEditOnBehalf && fromDate && toDate && (
          <div className="row" style={{ paddingTop: "20px" }}>
            <div className="col-md-6 market-data-tile">
              <h5>{t("SpotHistory")}</h5>
              <MarksHistoryTable
                marks={historicalMarks}
                product={product}
                marketId={marketId}
                termScheduleId={termScheduleId}
                historicalReportingPeriodicity={historicalReportingPeriodicity}
                fromDate={fromDate}
                toDate={toDate}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                currencySymbol={currencySymbol}
              />
            </div>
            <div className="col-md-6 market-data-tile">
              <h5>{t("ForwardCurve")}</h5>
              <MarksForwardTable
                marks={forwardMarks}
                product={product}
                marketId={marketId}
                termScheduleId={termScheduleId}
                forwardReportingPeriodicity={forwardReportingPeriodicity}
                marketDate={marketDate}
                priceBasisId={priceBasisId}
                currencyId={currencyId}
                uomId={uomId}
                pricePrecision={pricePrecision}
                volumePrecision={volumePrecision}
                currencySymbol={currencySymbol}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(MarketMarks);
