import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"term/";

export async function getTerms() {
    const {data: Terms} = await http.get(apiEndpoint);
    return Terms;
}

export async function getTerm(termId) {
  const {data: Term} = await http.get(apiEndpoint+termId);
  return Term;
}

export async function getTermsByTermSchedule(termScheduleId) {
  const {data: Terms} = await http.get(apiEndpoint+"ByTermSchedule/"+termScheduleId);
  return Terms;
}

export async function getTermsByDate(termScheduleId, date) {
  const {data: Terms} = await http.get(apiEndpoint+"ByTermSchedule/"+termScheduleId+'/'+date);
  return Terms;
}

export async function getSpotByTermSchedule(termScheduleId, fromDate, toDate) {
  const {data: Terms} = await http.get(apiEndpoint+"SpotByTermSchedule/"+termScheduleId+'/'+fromDate+"/"+toDate);
  return Terms;
}

export async function addTerm(term) {  
  const {data: Term} = await http.post(apiEndpoint, term);
  return Term;
}

export async function updateTerm(term) {
  const {data: Term} = await http.put(apiEndpoint, term);
  return Term;
}

export async function deleteTerm(termId) {
  return await http.delete(apiEndpoint+termId);
}

export async function getTermHistory(termId) {
  const {data: TermHistory} = await http.get(apiEndpoint+"audit/"+termId);
  return TermHistory;
}
