import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"uom/";

export async function getUoms() {
    const {data: Uoms} = await http.get(apiEndpoint);
    return Uoms;
}

export async function getUom(uomId) {
  const {data: Uom} = await http.get(apiEndpoint+uomId);
  return Uom;
}

export async function addUom(uom) {  
  const {data: Uom} = await http.post(apiEndpoint, uom);
  return Uom;
}

export async function updateUom(uom) {
  const {data: Uom} = await http.put(apiEndpoint, uom);
  return Uom;
}

export async function deleteUom(uomId) {
  return await http.delete(apiEndpoint+uomId);
}

export async function getUomHistory(uomId) {
  const {data: UomHistory} = await http.get(apiEndpoint+"audit/"+uomId);
  return UomHistory;
}
