//import Raven from 'raven-js';

//function init(){
//    Raven.config('https://2fe5af65039143f88c4334a445c74f90@o4505365910650880.ingest.sentry.io/4505365926445056', {
//      release:'1.0.0',
//      environment: 'development-test'
//  }).install();
//}

function log(message)
{
//  Raven.captureException(error);
  console.log(message);
}
    

const logInterface = 
{
  //  init,
  log
}

export default logInterface