import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"userAccountSubscription/";

export async function getUserAccountSubscriptions() {
  const {data: UserAccountSubscriptions} = await http.get(apiEndpoint);
  return UserAccountSubscriptions;
}

export async function getUserAccountSubscription(userAccountSubscriptonId) {
    const {data: UserAccountSubscriptions} = await http.get(apiEndpoint+userAccountSubscriptonId);
    return UserAccountSubscriptions;
}

export async function getUserAccountSubscriptionsByUserAccount(userAccountId) {
  const {data: UserAccountSubscriptions} = await http.get(apiEndpoint+"User/"+userAccountId);
  return UserAccountSubscriptions;
}

export async function getUserAccountSubscriptionsByMarket(marketId) {
  const {data: UserAccountSubscriptions} = await http.get(apiEndpoint+"market/"+marketId);
  return UserAccountSubscriptions;
}

export async function geValidMarketsForSubscription(organizationId) {
  const {data: Markets} = await http.get(apiEndpoint+"getValidMarketsForSubscription/"+organizationId);
  return Markets;
}

export async function getUserAccountSubscriptionsByOrganizationSubscription(organizationSubscriptionId) {
  const {data: UserAccountSubscriptions} = await http.get(apiEndpoint+"organizationSubscription/"+organizationSubscriptionId);
  return UserAccountSubscriptions;
}

export async function addUserAccountSubscription(userAccountSubscriptionInput) {  
  const {data: UserAccountSubscription} = await http.post(apiEndpoint, userAccountSubscriptionInput);
  return UserAccountSubscription;
}

export async function updateUserAccountSubscription(userAccountSubscriptionInput) {
  const {data: UserAccountSubscription} = await http.put(apiEndpoint, userAccountSubscriptionInput);
  return UserAccountSubscription;
}

export async function deleteUserAccountSubscription(userAccountSubscriptionInput) {
  await http.delete(apiEndpoint, userAccountSubscriptionInput);
}

export async function getUserAccountSubscriptionHistory(userAccountSubscriptionId) {
  const {data: UserAccountSubscriptionHistory} = await http.get(apiEndpoint+"Audit/"+userAccountSubscriptionId);
  return UserAccountSubscriptionHistory;
}
