import React from "react";
import Joi from "joi-browser";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { Tabs } from "react-bootstrap";
import {
  getMarketControlByMarket,
  insertOrUpdateMarketControl
} from "../../../common/services/tradingEngine/marketStateService";
import { getMarkets } from "../../../common/services/markets/marketService";
import { getMarketControl } from "../../../common/services/tradingEngine/marketStateService";
import Form from "../../../common/components/form/form";
import Loading from "../../../common/components/loading/loading";
import { withTranslation } from "react-i18next";

class TradingMarket extends Form {
  state = {
    data: {
      marketId: "",
      halted: "",
      allowPick: "",
      allowAllOrNone: "",
      allowFillOrKill: "",
      withdrawOnCrossTrade: "",
      applyCounterpartyWhitelist: "",
      active: "",
      eventId: "",
    },
    createdDate: "",
    updatedDate: "",
    errors: {},
    isLoading: true,
    isNew: true,
    markets: []
  };

  schema = {
    marketId: Joi.string().required(),
    halted: Joi.boolean().required().label(this.props.t("Hatled")),
    allowPick: Joi.boolean().required().label(this.props.t("AllowPick")),
    allowAllOrNone: Joi.boolean().required().label(this.props.t("AllowAllOrNone")),
    allowFillOrKill: Joi.boolean().required().label(this.props.t("AllowFillOrKill")),
    withdrawOnCrossTrade: Joi.boolean().required().label(this.props.t("WithdrawOnCrossTrade")),
    applyCounterpartyWhitelist: Joi.boolean().required().label(this.props.t("ApplyCounterpartyWhitelist")),
    active: Joi.boolean().label(this.props.t("Active")),
    eventId: Joi.string(),
  };

  async componentDidMount() {
    //try{
    const { marketId, isNew } = this.props;
    this.setState({ isNew: isNew, tabId: marketId }); //marketId can change on new adds, as it's selected from the dropdown
    var tradingMarket = {};

    //Load the markets for the picklist
    const markets = (await getMarkets()).filter(x => x.active);
    const tradingMarkets = (await getMarketControl()).filter(x => x.active).map(x => x.marketId);
    const nonTradingMarkets = markets.filter(x => !tradingMarkets.includes(x.marketId) || x.marketId === marketId);
    this.setState({ markets: nonTradingMarkets});

    if (!isNew) {
      tradingMarket = await getMarketControlByMarket(marketId);
      tradingMarket.eventId = uuid();
    } //New User Add
    else {
      tradingMarket = {
        marketId: "",
        halted: true,
        allowPick: false,
        allowAllOrNone: false,
        allowFillOrKill: true,
        withdrawOnCrossTrade: true,
        applyCounterpartyWhitelist: false,
        active: true,
        eventId: uuid(),
      };
    }

    this.setState({
      data: this.mapToViewModel(tradingMarket),
      isLoading: false,
    });

    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    this.setState({
      createdDate: new Date(tradingMarket.createdDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      updatedDate: new Date(tradingMarket.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
    });
    // }
    // catch(ex)
    // {
    //     //Handle Erorr..
    // }
  }

  mapToViewModel(tradingMarket) {
    return {
      marketId: tradingMarket.marketId,
      halted: tradingMarket.halted,
      allowPick: tradingMarket.allowPick,
      allowAllOrNone: tradingMarket.allowAllOrNone,
      allowFillOrKill: tradingMarket.allowFillOrKill,
      withdrawOnCrossTrade: tradingMarket.withdrawOnCrossTrade,
      applyCounterpartyWhitelist: tradingMarket.applyCounterpartyWhitelist,
      active: tradingMarket.active,
      eventId: tradingMarket.eventId,
    };
  }

  doSubmit = async () => {
    const { t, onRename } = this.props;
    const { isNew, markets, tabId } = this.state;

    try {
      //Reset the event ID
      const data = { ...this.state.data };
      data.eventId = uuid();
      this.setState({ data: data });
      const marketName = markets.find(x => x.marketId === this.state.data.marketId).name;

      if (!isNew) {
        await insertOrUpdateMarketControl(this.state.data);
        toast.success(t("TradingMarketUpdated"));        
      } else {
        await insertOrUpdateMarketControl(this.state.data);
        this.setState({ isNew: false });
        onRename(tabId, this.state.data.marketId, marketName);
        this.setState ({ tabId: this.state.data.marketId});
        toast.success(t("TradingMarketAdded"));
      }

      //Call back to the select list to allow it to update.
      const callbackData = {...this.state.data, name: marketName}
      this.props.submitCallbackFunction(callbackData);
    } catch (ex) {
      console.log(t("ErrorSaving"), ex);

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(t("ErrorSaving") + " " + key + ": " + error);
              return null;
            });
          });
        } else {
          toast.error(t("ErrorSaving") + " " + ex.response.data);
        }
        const errors = { ...this.state.errors };
        errors.Name = ex.response.data;
        toast.error(ex.response.data.errors.title);
        this.setState({ errors });
      }
    }
  };

  render() {
    const { isLoading, isNew, createdDate, updatedDate } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit } = this.props;

    return (
      <div className="container-fluid">
        <form
          data-bs-theme="dark"
          className="form-inline"
          onSubmit={this.handleSubmit}
        >
          <fieldset disabled={allowEdit ? "" : "disabled"}>
            <div>
              {this.renderSwitch("active", t("Active"))}
              {this.renderSelect(
                "marketId",
                t("Market"),
                this.state.markets,
                "marketId",
                "name",
                !this.state.isNew
              )}              
              {this.renderSwitch("halted", t("Halted"))}
              {this.renderSwitch("allowPick", t("AllowPick"))}
              {this.renderSwitch("allowAllOrNone", t("AllowAllOrNone"))}
              {this.renderSwitch("allowFillOrKill", t("AllowFillOrKill"))}
              {this.renderSwitch("withdrawOnCrossTrade", t("WithdrawOnCrossTrade"))}
              {this.renderSwitch("applyCounterpartyWhitelist", t("ApplyCounterpartyWhitelist"))}
              {allowEdit && this.renderButton(t("Save"))}
              <div className="form-footnote">
                {createdDate !== "Invalid Date" && (
                  <div>
                    {t("Created")} {createdDate}
                  </div>
                )}
                {updatedDate !== "Invalid Date" && (
                  <div>
                    {t("Updated")} {updatedDate}
                  </div>
                )}
              </div>
            </div>
          </fieldset>
        </form>
        {!isNew && (
          <div>
            <Tabs
              className="nav-dark nav-tabs-sm"
              defaultActiveKey={1}
              id="useraccount-tabs"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              {/* Removing temporarily, endpoint still not built. */}
              {/* <Tab eventKey={1} title={t("History")}>
                <TradingMarketHistory marketId={marketId} />
              </Tab> */}
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["trading"])(TradingMarket);
