import React from 'react';
import { useTranslation} from 'react-i18next';

const Unauthorized = () => {
 const {t} = useTranslation();
 const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

 return (
  <>
    <div className="d-flex align-items-center justify-content-center">
      <h1>{t("Unauthorized")}</h1>
    </div>
    <div className="d-flex align-items-center justify-content-center">
      <h5>{t("SubscriptionInquiry")}{" "}<a href={"mailto:"+contactEmail}>{contactEmail}</a></h5>
    </div>
   </>
 );
};

export default Unauthorized;