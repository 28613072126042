import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API+"organizationSubscription/";

export async function getOrganizationSubscriptions() {
  const {data: OrganizationSubscriptions} = await http.get(apiEndpoint);
  return OrganizationSubscriptions;
}

export async function getOrganizationSubscription(organizationSubscriptonId) {
    const {data: OrganizationSubscriptions} = await http.get(apiEndpoint+organizationSubscriptonId);
    return OrganizationSubscriptions;
}

export async function getContributorSubscriptionsByMarket(marketId) {
  const {data: OrganizationSubscriptions} = await http.get(apiEndpoint+"contributors/"+marketId);
  return OrganizationSubscriptions;
}

export async function getOrganizationSubscriptionsByOrganization(organizationId) {
  const {data: OrganizationSubscriptions} = await http.get(apiEndpoint+"organization/"+organizationId);
  return OrganizationSubscriptions;
}

export async function getOrganizationSubscriptionsByMarket(marketId) {
  const {data: OrganizationSubscriptions} = await http.get(apiEndpoint+"market/"+marketId);
  return OrganizationSubscriptions;
}

export async function getByOrganizationAndMarket(organizationId, marketId) {
  const {data: OrganizationSubscriptions} = await http.get(apiEndpoint+"organization/"+organizationId+"/market/"+marketId);
  return OrganizationSubscriptions;
}

export async function addOrganizationSubscription(organizationSubscriptionInput) {  
  const {data: OrganizationSubscription} = await http.post(apiEndpoint, organizationSubscriptionInput);
  return OrganizationSubscription;
}

export async function updateOrganizationSubscription(organizationSubscriptionInput) {
  const {data: OrganizationSubscription} = await http.put(apiEndpoint, organizationSubscriptionInput);
  return OrganizationSubscription;
}

export async function deleteOrganizationSubscription(organizationSubscriptionInput) {
  await http.delete(apiEndpoint, organizationSubscriptionInput);
}

export async function getOrganizationSubscriptionHistory(organizationSubscriptionId) {
  const {data: OrganizationSubscriptionHistory} = await http.get(apiEndpoint+"Audit/"+organizationSubscriptionId);
  return OrganizationSubscriptionHistory;
}
