import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getUomTypes } from "../../../common/services/markets/uomTypeService";
import SearchBox from "../../../common/components/search/searchBox";
import UomTypesTable from "./uomTypesTable";
import { withTranslation } from "react-i18next";

class UomTypes extends Component {
  constructor(props) {
    super(props);

    this.handleUomTypeUpdated = this.handleUomTypeUpdated.bind(this);
  }

  state = {
    uomTypes: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {
    this.setState({ uomTypes: await getUomTypes(), isLoading: false });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleUomTypeSelected = (uomType, isNew = false) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(uomType, this.handleUomTypeUpdated, isNew);
  };

  handleUomTypeUpdated = (uomType) => {
    const uomTypes = [...this.state.uomTypes];
    const index = uomTypes.findIndex((u) => u.uomTypeId === uomType.uomTypeId);
    if (index > -1) {
      uomTypes.splice(index, 1);
    }
    uomTypes.push(uomType);
    this.setState({ uomTypes: uomTypes });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      uomTypes: allUomTypes,
      activeOnly,
    } = this.state;

    let filtered = allUomTypes;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.name != null &&
            u.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (u.description != null &&
            u.description.toLowerCase().includes(searchQuery.toLowerCase()))
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const uomTypes = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: uomTypes };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit } = this.props;

    const { totalCount, data: uomTypes } = this.getPagedData();

    const newUomType = {
      uomTypeId: uuid(),
      name: t("NewUomType"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() => this.handleUomTypeSelected(newUomType, true)}
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddUomType")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedUomType", { count: totalCount })}</p>
        <UomTypesTable
          uomTypes={uomTypes}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleUomTypeSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(UomTypes);
