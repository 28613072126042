import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getCurrencyHistory } from "../../../common/services/markets/currencyService";
import { withTranslation } from "react-i18next";

class CurrencyHistory extends Component {
  state = {
    currencyHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { currencyId } = this.props;
    const currencyHistory = this.mapToViewModel(
      await getCurrencyHistory(currencyId)
    );

    this.setState({
      currencyHistory: _.orderBy(currencyHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(currencys) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return currencys.map((currency) => ({
      id: currency.id,
      name: currency.name,
      symbol: currency.symbol,
      active: currency.active.toString(),
      updatedByUserAccountId: currency.createdByUserAccountId,
      updatedBy:
        currency.updatedByUserAccountFirstName +
        " " +
        currency.updatedByUserAccountLastName,
      updatedDate: new Date(currency.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(currency.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: currency.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("CurrencyName") },
    { path: "symbol", label: this.props.t("CurrencySymbol") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { currencyHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, currencyId } = this.props;

    return (
      <div className="history-table-container">
        {t("CurrencyID")} {currencyId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={currencyHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(CurrencyHistory);
