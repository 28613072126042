import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import _ from "lodash";
import uuid from "react-uuid";
import Loading from "../../../common/components/loading/loading";
import Pagination, {
  paginate,
} from "../../../common/components/table/pagination";
import { getMarketControl } from "../../../common/services/tradingEngine/marketStateService";
import { getMarkets } from "../../../common/services/markets/marketService";
import SearchBox from "../../../common/components/search/searchBox";
import TradingMarketsTable from "./tradingMarketsTable";
import { withTranslation } from "react-i18next";

class TradingMarkets extends Component {
  constructor(props) {
    super(props);

    this.handleTradingMarketUpdated = this.handleTradingMarketUpdated.bind(this);
  }

  state = {
    tradingMarkets: [],
    allMarkets: [],
    pageSize: 20,
    searchQuery: "",
    currentPage: 1,
    activeOnly: true,
    sortColumn: { path: "name", order: "asc" },
    isLoading: true,
  };

  async componentDidMount() {

    const tradingMarkets = await getMarketControl();
    const allMarkets = await getMarkets();
    const tradingMarketsNamed = tradingMarkets.map(item=> {
      return {...item, name: allMarkets.find(x => x.marketId === item.marketId).name};
    })
    
    this.setState({
      tradingMarkets: tradingMarketsNamed,
      isLoading: false,
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleToggleActiveOnly = () => {
    this.setState({ activeOnly: !this.state.activeOnly });
  };

  handleTradingMarketSelected = (tradingMarket, isNew = false) => {
    //Inject the callback function before calling back to the onClick event.
    this.props.onClick(tradingMarket, this.handleTradingMarketUpdated, isNew);
  };

  handleTradingMarketUpdated = (tradingMarket) => {
    const tradingMarkets = [...this.state.tradingMarkets];
    const index = tradingMarkets.findIndex(
      (u) => u.marketId === tradingMarket.marketId
    );
    if (index > -1) {
      tradingMarkets.splice(index, 1);
    }
    tradingMarkets.push(tradingMarket);
    this.setState({ tradingMarkets: tradingMarkets });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      tradingMarkets: allTradingMarkets,
      activeOnly,
    } = this.state;

    let filtered = allTradingMarkets;

    if (activeOnly) filtered = filtered.filter((u) => u.active);

    if (searchQuery)
      filtered = filtered.filter(
        (u) =>
          (u.name != null &&
            u.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (u.shortName != null &&
            u.shortName.toLowerCase().includes(searchQuery.toLowerCase()))
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const tradingMarkets = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: tradingMarkets };
  };

  render() {
    const {
      pageSize,
      searchQuery,
      currentPage,
      sortColumn,
      activeOnly,
      isLoading,
    } = this.state;

    if (isLoading) return <Loading />;

    const { t, allowEdit } = this.props;

    const { totalCount, data: tradingMarkets } = this.getPagedData();

    const newTradingMarket = {
      marketId: uuid(),
      name: t("NewTradingMarket"),
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <div className="col">
            <Form.Switch
              className="switch"
              type="switch"
              label={t("ActiveOnly")}
              checked={activeOnly}
              value={activeOnly}
              onChange={this.handleToggleActiveOnly}
            />
          </div>
          <div className="col">
            {allowEdit && (
              <button
                onClick={() =>
                  this.handleTradingMarketSelected(newTradingMarket, true)
                }
                className="btn btn-sm btn-success float-sm-right"
              >
                {t("AddTradingMarket")}
              </button>
            )}
          </div>
        </div>
        <p>{t("RetrievedTradingMarket", { count: totalCount })}</p>
        <TradingMarketsTable
          tradingMarkets={tradingMarkets}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onRowClick={this.handleTradingMarketSelected}
        />
        <Pagination
          itemsCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default withTranslation(["trading"])(TradingMarkets);
