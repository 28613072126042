import React, { Component } from 'react';
import _ from 'lodash';
import Table from '../../common/components/table/table';
import Loading from '../../common/components/loading/loading';
import ReviewTradesToggle from './reviewTradesToggle';
import { getProductWithQualityAttributes } from "../../common/services/products/productService";
import { getMarketWithAttributes } from "../../common/services/markets/marketService";
import { withTranslation } from 'react-i18next';

class ReviewTradesTable extends Component {

    state = {
        mappedTrades: [],
        market: {},
        sortColumn: "updatedDate",
        isLoading: true
   };

    async componentDidMount() {
        const {trades} = this.props;

        const productId = trades[0].productId;
        const marketId = trades[0].marketId;
        const product = await getProductWithQualityAttributes(productId);
        const market = await getMarketWithAttributes(marketId);

        const mappedTrades = market && this.mapToViewModel(trades, market);


        this.setState({mappedTrades: _.orderBy(mappedTrades, "updatedDate", "desc"), columns: this.mapToColumns(product), market: market, product: product, isLoading:false});
    };

    async componentDidUpdate(previousProps) {
        if
        (
            previousProps.trades !== this.props.trades
        )
        {
            const {trades} = this.props;
            const {market} = this.state;
            const mappedTrades = this.mapToViewModel(trades, market);
            this.setState({mappedTrades: _.orderBy(mappedTrades, "updatedDate", "desc")});
        }
    };


    mapToViewModel(trades, market) {
        const dateFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
        }

        const dateTimeFormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: 'numeric',
            hour: 'numeric',
            hour12: false,
            minute: 'numeric',
            second: 'numeric'
        }

        return trades.map(trade => {
            const marketAttributeIndex=market.marketAttributes && market.marketAttributes.findIndex((u) => u.priceBasisId === trade.priceBasisId);
            const currencySymbol= (marketAttributeIndex > -1 && market.marketAttributes[marketAttributeIndex].currencySymbol) ?? '$';
            const pricePrecision= (marketAttributeIndex > -1 && market.marketAttributes[marketAttributeIndex].pricePrecision) ?? '2';
            const volumePrecision= (marketAttributeIndex > -1 && market.marketAttributes[marketAttributeIndex].volumePrecision) ?? '0';

            return({
                tradeId: trade.tradeId,
                organizationName: trade.organizationName,
                tradeDateFormatted: new Date(trade.tradeDate).toLocaleDateString('en-US', dateFormatOptions),
                priceFormatted: currencySymbol + Number(trade.price).toFixed(pricePrecision),
                volumeFormatted: Number(trade.volume).toFixed(volumePrecision),
                priceBasisName: trade.priceBasisName,
                termName: trade.termName,
                price: trade.price,
                volume: trade.volume,
                buyerOrganizationName: trade.buyerOrganizationName,
                sellerOrganizationName: trade.sellerOrganizationName,
                updatedDate: new Date(trade.updatedDate).toLocaleDateString('en-US', dateTimeFormatOptions),
                tradeState: trade.tradeState,
                ...trade.qualityAttributeValues
                    .reduce((acc, attribute) => {
                        acc[attribute.qualityAttributeId] = attribute.value;
                        return acc;
                    }, {}),                 
                })
    });
    }

    mapToColumns(product)
    {
        var columns = [
            {path: 'organizationName', label: this.props.t("SubmittingOrganizationName")},
            ...product.qualityAttributes
            .filter(qualityAttribute => qualityAttribute.isPriceDriver !== false) //Exclude PriceDrivers
            .map(qualityAttribute => ( //Add one new column for each qualityAttribute
                {
                    path: qualityAttribute.qualityAttributeId,
                    label: qualityAttribute.qualityAttributeName,
                }
            )),        
            {path: 'tradeDateFormatted', label: this.props.t("TradeDate")},
            {path: 'priceBasisName', label: this.props.t("PriceBasis")},
            {path: 'priceFormatted', label: this.props.t("Price")},
            {path: 'volumeFormatted', label: this.props.t("Volume")},
            {path: 'termName', label: this.props.t("Term")},
            {path: 'buyerOrganizationName', label: this.props.t("BuyerOrganization")},
            {path: 'sellerOrganizationName', label: this.props.t("SellerOrganization")},
            {path: 'updatedDate', label: this.props.t("UpdateDate")},
            {path: 'tradeState', content:data => <ReviewTradesToggle tradeId={data.tradeId} tradeState={data.tradeState} handleApprove={this.props.handleApprove} handleReject={this.props.handleReject}/>},
        ];

        return columns;
    }

    render() { 
        const {mappedTrades, sortColumn, columns, isLoading} = this.state;
        if (isLoading) return (<Loading/>);

        return (
            <div>
                <Table className="table-review-trades" columns= {columns} sortColumn = {sortColumn}  data={mappedTrades} valueProperty="tradeId"/>
            </div>
        );
    }
}

export default withTranslation(["marketdata"])(ReviewTradesTable);