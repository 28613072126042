import React, { Component } from "react";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { getQualityAttributes } from "../../../common/services/products/qualityAttributeService";
import Loading from "../../../common/components/loading/loading";
import DualListBox from "../../../common/components/form/dualListBox";
import { withTranslation } from "react-i18next";
import {
  getQualityAttributeCommoditiesByCommodity,
  assignQualityAttributeCommodity,
  revokeQualityAttributeCommodity,
} from "../../../common/services/products/qualityAttributeCommodityService";

class QualityAttributeCommodity extends Component {
  state = {
    options: [],
    selected: [],
    isLoading: true,
  };

  async componentDidMount() {
    const { commodityId } = this.props;
    const allQualityAttributes = await getQualityAttributes();
    const qualityAttributeCommodities =
      await getQualityAttributeCommoditiesByCommodity(commodityId);

    this.setState({
      options: this.mapToOptionsViewModel(allQualityAttributes),
      selected: this.mapToSelectedViewModel(qualityAttributeCommodities),
      isLoading: false,
    });
  }

  mapToOptionsViewModel(qualityAttributeCommodities) {
    return qualityAttributeCommodities.map((qualityAttribute) => ({
      value: qualityAttribute.qualityAttributeId,
      label: qualityAttribute.name,
    }));
  }

  mapToSelectedViewModel(qualityAttributeCommodities) {
    return qualityAttributeCommodities.map(
      (qualityAttributeCommodity) =>
        qualityAttributeCommodity.qualityAttributeId
    );
  }

  async handleChange(selected, selection, controlKey) {
    const { commodityId } = this.props;
    const eventId = uuid();

    if (controlKey !== "available" && controlKey !== "selected") {
      toast.warning(this.props.t("UnexpectedError"));
      return null;
    }

    // set the initial state,will clean up at the end if there are failures.
    this.setState({ selected: selected });

    for (const qualityAttributeId of selection) {
      if (controlKey === "available") {
        try {
          await assignQualityAttributeCommodity({
            qualityAttributeId: qualityAttributeId,
            commodityId: commodityId,
            active: true,
            eventId: eventId,
          });
          toast.success(this.props.t("QualityAttributeAdded"));
        } catch (ex) {
          console.log(ex);
          var index = selected.indexOf(qualityAttributeId);
          delete selected[index];
          toast.warning(this.props.t("QualityAttributeAdditionFailed"));
        }
      } else if (controlKey === "selected") {
        try {
          await revokeQualityAttributeCommodity(
            qualityAttributeId,
            commodityId
          );
          toast.success(this.props.t("QualityAttributeRevoked"));
        } catch (ex) {
          console.log(ex);
          selected.push(qualityAttributeId);
          toast.warning(this.props.t("QualityAttributeRevocationFailed"));
        }
      }
    }

    //Clean up with the actual outcome state
    this.setState({ selected: selected });
  }

  render() {
    const { options, selected, isLoading } = this.state;
    const { allowEdit } = this.props;

    if (isLoading) return <Loading />;

    return (
      <div>
        <DualListBox
          canFilter
          className="rdl-hide-move-all"
          disabled={!allowEdit}
          options={options}
          selected={selected}
          onChange={(selected, selection, controlKey) =>
            this.handleChange(selected, selection, controlKey)
          }
        />
      </div>
    );
  }
}

export default withTranslation(["products"])(QualityAttributeCommodity);
