import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"commodityClass/";

export async function getCommodityClasses() {
  const {data: CommodityClasses} = await http.get(apiEndpoint);
  return CommodityClasses;
}

export async function getCommodityClass(commodityClassId) {
  const {data: CommodityClass} = await http.get(apiEndpoint+commodityClassId);
  return CommodityClass;
}

export async function addCommodityClass(commodityClass) {  
  const {data: CommodityClass} = await http.post(apiEndpoint, commodityClass);
  return CommodityClass;
}

export async function updateCommodityClass(commodityClass) {
  const {data: CommodityClass} = await http.put(apiEndpoint, commodityClass);
  return CommodityClass;
}

export async function deleteCommodityClass(commodityClassId) {
  return await http.delete(apiEndpoint+commodityClassId);
}

export async function getCommodityClassHistory(commodityClassId) {
  const {data: CommodityClassHistory} = await http.get(apiEndpoint+"audit/"+commodityClassId);
  return CommodityClassHistory;
}
