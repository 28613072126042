import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"market/";

export async function getMarkets() {
    const {data: Markets} = await http.get(apiEndpoint);
    return Markets;
}

export async function getMarketsWithAttributes() {
  const {data: Markets} = await http.get(apiEndpoint+"WithAttributes");
  return Markets;
}


export async function getMarket(marketId) {
  const {data: Market} = await http.get(apiEndpoint+marketId);
  return Market;
}

export async function getMarketWithAttributes(marketId) {
  const {data: Market} = await http.get(apiEndpoint+"WithAttributes/"+marketId);
  return Market;
}

export async function addMarket(market) {  
  const {data: Market} = await http.post(apiEndpoint, market);
  return Market;
}

export async function updateMarket(market) {
  const {data: Market} = await http.put(apiEndpoint, market);
  return Market;
}

export async function deleteMarket(marketId) {
  return await http.delete(apiEndpoint+marketId);
}

export async function getMarketHistory(marketId) {
  const {data: MarketHistory} = await http.get(apiEndpoint+"audit/"+marketId);
  return MarketHistory;
}
