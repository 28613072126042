import React, { Component } from "react";
import Badge from 'react-bootstrap/Badge';
import { withTranslation } from 'react-i18next';

class qualityAttributeBadges extends Component {

    render() {         

        //const {t, allowEdit, isNew} = this.props;

        const {
            qualityAttributeValues,
        } = this.props; 

        return (
            <div style={{textAlign:"center"}}>
                {qualityAttributeValues && qualityAttributeValues.map(v => <Badge key={v.qualityAttributeValueId} bg="secondary">{v.value}</Badge>)}
            </div>
        );
    }
}
 
export default withTranslation(["marketdata"])(qualityAttributeBadges);