import { getMarketsWithAttributes } from "../markets/marketService";
import { getMarketGroupMarkets } from "../markets/marketGroupMarketService";
import { getUserAccountSubscriptionsByUserAccount } from "./userAccountSubscriptionService";

export async function getSubscribedMarkets(userAccountId)
{
  const subscriptions = (await getUserAccountSubscriptionsByUserAccount(userAccountId)).filter((s) => s.active);

  const marketGroups = (await getMarketGroupMarkets()).filter(
    (m) => m.active
  );

  //Filter the markets by Subscription
  const markets = (await getMarketsWithAttributes()).filter(
    (m) => m.active
  );

  const subscribedItems = subscriptions.map((x) => x.subscribableId);

  const marketGroupMarkets = marketGroups.filter((m) =>
    subscribedItems.includes(m.marketGroupId)
  ).map((x) => x.marketId);

  const subscribedMarkets = markets.filter((m) =>
    subscribedItems.includes(m.marketId)
    || marketGroupMarkets.includes(m.marketId)
  );

  return subscribedMarkets;
};