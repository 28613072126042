import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getOrganizationSubscriptionHistory } from "../../../common/services/marketdata/organizationSubscriptionService";
import { withTranslation } from "react-i18next";

class OrganizationSubscriptionHistory extends Component {
  state = {
    organizationSubscriptionHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { organizationSubscriptionId } = this.props;
    const organizationSubscriptionHistory = this.mapToViewModel(
      await getOrganizationSubscriptionHistory(organizationSubscriptionId)
    );

    this.setState({
      organizationSubscriptionHistory: _.orderBy(
        organizationSubscriptionHistory,
        "id",
        "desc"
      ),
      isLoading: false,
    });
  }

  mapToViewModel(organizationSubscriptions) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return organizationSubscriptions.map((organizationSubscription) => ({
      id: organizationSubscription.id,
      organizationName: organizationSubscription.organizationName,
      subscribableName: organizationSubscription.subscribableName,
      compensated: organizationSubscription.compensated.toString(),
      active: organizationSubscription.active.toString(),
      updatedByUserAccountId: organizationSubscription.updatedByUserAccountId,
      updatedBy:
        organizationSubscription.updatedByUserAccountFirstName +
        " " +
        organizationSubscription.updatedByUserAccountLastName,
      updatedDate: new Date(
        organizationSubscription.updatedDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      writeDate: new Date(
        organizationSubscription.writeDate
      ).toLocaleDateString("en-US", dateFormatOptions),
      eventId: organizationSubscription.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "organizationName", label: this.props.t("OrganizationName") },
    {
      path: "subscribableName",
      label: this.props.t("SubscribableName"),
    },
    { path: "compensated", label: this.props.t("Compensated") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { organizationSubscriptionHistory, sortColumn, isLoading } =
      this.state;

    if (isLoading) return <Loading />;

    const { t, organizationSubscriptionId } = this.props;

    return (
      <div className="history-table-container">
        {t("OrganizationSubscriptionID")} {organizationSubscriptionId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={organizationSubscriptionHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["auth"])(OrganizationSubscriptionHistory);
