import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";

class AliasesTable extends Component {
  columns = [
    { path: "name", label: this.props.t("Name") },
    { path: "productName", label: this.props.t("Product") },
    { path: "organizationName", label: this.props.t("Organization") },
    {
      path: "active",
      label: this.props.t("Active"),
      content: (alias) => (
        <FontAwesome name={alias.active ? "check" : "times"} />
      ),
    },
  ];

  // constructor() {
  //     super();
  //     const user = aliases.getCurrentUser();
  //     if (user && user.isAdmin)
  //         this.columns.push(this.deleteColumn);
  // }

  render() {
    const { aliases, onSort, sortColumn, onRowClick } = this.props;
    return (
      <Table
        className="table table-dark table-sm"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={aliases}
        onRowClick={onRowClick}
        valueProperty="aliasId"
      />
    );
  }
}

export default withTranslation(["products"])(AliasesTable);
