import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import App from './App/App';
import keycloak from "./common/services/auth/keycloak";
import httpService from "./common/services/app/httpService";
import './index.css';
import { toast } from 'react-toastify';
import './common/services/app/i18n';

const handleEvent = async (event, error) => {
  console.log('index - onKeycloakEvent', event, error)
  if (event === 'onAuthSuccess' || event ==='onAuthRefreshSuccess')
  {
    httpService.setJwt(keycloak.token);
  } 
  else if (event === 'onAuthLogout' || event ==='onAuthRefreshError')
  {
    //window.location ='/';
    keycloak.login();
    toast("Your session has been logged out.", {autoClose:false });
  }
  else if (event ==='onInitError') 
  {    
    if (window.location.pathname !== "/maintenance")
    {
      window.location = "/maintenance";
      toast.warning("Your session has been logged out.", {autoClose:false });
    }
  }
  else if (event ==='onReady') 
  {    
    if (window.location.pathname === "/maintenance")
    {
      window.location = "/";
    }
  }
}

const handleTokens = (tokens) => {
  console.log('index - onKeycloakTokens')
  httpService.setJwt(tokens.token);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required', promiseType: 'native' , checkLoginIframe: false, silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html' }}  autoRefreshToken = {true} onEvent={handleEvent} onTokens={handleTokens}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>  
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();