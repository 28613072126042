import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Pagination = ({itemsCount, pageSize, currentPage, onPageChange, disabled}) => {    


    const pagesCount = Math.ceil(itemsCount / pageSize);
    if (pagesCount <= 1) return null;

    const pages = _.range(1, pagesCount + 1);

    return <nav>
        <ul className="pagination pagination-sm clickable">
            {pages.map(page => (
             <li key = {page} className={page === currentPage ? "page-item active" : "page-item"}><button onClick={() => onPageChange(page)} className = "page-link">{page}</button></li>
            ))}
        </ul>
    </nav>; 
};

Pagination.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
}

export default Pagination;

export function paginate (items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    return _(items)
        .slice(startIndex)
        .take(pageSize)
        .value();
}