import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import Markets from "./markets";
import Market from "./market";
import MarketGroups from "./marketGroups";
import MarketGroup from "./marketGroup";
import { withTranslation } from "react-i18next";

class MarketAdmin extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 50,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Markets"),
              enableClose: false,
              component: "Markets",
            },
            {
              type: "tab",
              name: this.props.t("MarketGroups"),
              enableClose: false,
              component: "MarketGroups",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 50,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
            // {
            //   type: "tab",
            //   name: "User",
            //   visible: false,
            //   component: "Placeholder"
            // }
          ],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleMarketSelect = (Market, submitCallbackFunction, isNew) => {
    const { marketId, name } = Market;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "Market",
            name: `${name}`,
            id: `${marketId}`,
            config: { marketId, submitCallbackFunction, isNew },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(marketId));
    }
  };

  handleMarketGroupSelect = (MarketGroup, submitCallbackFunction, isNew) => {
    const { marketGroupId, name } = MarketGroup;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "MarketGroup",
            name: `${name}`,
            id: `${marketGroupId}`,
            config: { marketGroupId, submitCallbackFunction, isNew },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(marketGroupId));
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const MarketId = node.getId();

    if (componentId === "Markets") {
      return (
        <Markets
          onClick={this.handleMarketSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Market") {
      const { marketId, submitCallbackFunction, isNew } = node.getConfig();
      return (
        <Market
          marketId={marketId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "MarketGroups") {
      return (
        <MarketGroups
          onClick={this.handleMarketGroupSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "MarketGroup") {
      const { marketGroupId, submitCallbackFunction, isNew } = node.getConfig();
      return (
        <MarketGroup
          marketGroupId={marketGroupId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["markets"])(MarketAdmin);
