import React, { Component } from 'react';
import Table from '../../common/components/table/table';
import {formatDateTime} from '../../common/services/utilities';
import OrderCell from "../common/orderCell";
import QualityAttributeBadges from "../common/qualityAttributeBadges";
import { withTranslation } from 'react-i18next';
import _ from "lodash";

class MarketTrades extends Component {

    state = {
        columns: [],
      };

    async componentDidMount() {
        await this.setState({
          columns: this.mapToColumns(),
        });
      }

      async componentDidUpdate(previousProps) {
        if (
          !_.isEqual(previousProps.marketTrades, this.props.marketTrades) ||
          !_.isEqual(previousProps.organizationTrades, this.props.organizationTrades)
        ) {
            await this.setState({
                columns: this.mapToColumns(),
              });
        }
    }

    mapToColumns() {
        const {organizationTrades} = this.props;

        var     columns =[
            {path: 'tradeId', label: this.props.t("TradeId")},
            {path: 'marketName', label: this.props.t("MarketName")},
            {path: 'termName', label: this.props.t("TermName")},
            {path: 'qualityAttributeValues', label: this.props.t("QualityAttributes"), content: data => <QualityAttributeBadges qualityAttributeValues={data.qualityAttributeValues} />  },            
            {path: 'tradeDate', label: this.props.t("TradeDate"), content: data => <span>{formatDateTime(data.tradeDate,Intl.DateTimeFormat().resolvedOptions().timeZone," ")}</span> },
            {path: 'price', label: this.props.t("Price"),  content: data => <OrderCell       
                value={data.price}
                type={
                    organizationTrades.findIndex(x=>x.tradeId === data.tradeId)>=0
                    ? (organizationTrades[organizationTrades.findIndex(x=>x.tradeId === data.tradeId)].orderDirection === "Buy" ? "Bid" : "Offer")
                    : undefined
                }
                isDepth={false}
                pricePrecision={4} //Stub
                data={data}
                align={false}
                isOrganizationOrder={organizationTrades.findIndex(x=>x.tradeId === data.tradeId)>=0}
            />},
            {path: 'volume', label: this.props.t("Volume"),  content: data => <OrderCell       
                value={data.volume}
                type={
                    organizationTrades.findIndex(x=>x.tradeId === data.tradeId)>=0
                    ? (organizationTrades[organizationTrades.findIndex(x=>x.tradeId === data.tradeId)].orderDirection === "Buy" ? "Bid" : "Offer")
                    : undefined
                }
                isDepth={false}
                pricePrecision={1} //Stub
                data={data}
                align={false}
                isOrganizationOrder={organizationTrades.findIndex(x=>x.tradeId === data.tradeId)>=0}
            />},           
        ];
    
        return columns;
      }
    



    render() { 
        const {marketTrades } = this.props;
        const {columns} = this.state;
        const sortColumn = { path: "tradeDate", order: "desc" };
        const data = _.orderBy(
            marketTrades,
            "tradeDate",
            "desc"
          )

        return (
            <Table className="table table-dark table-sm market-grid" columns= {columns} sortColumn = {sortColumn} data={data} valueProperty="tradeId"/>
        );
    }
}

export default withTranslation(["trading"])(MarketTrades);