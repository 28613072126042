import React, { Component } from 'react';
import { formatDate } from "../../common/services/utilities";
import Table from '../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class AlbertaEnvironmentalTradesTable extends Component {
    columns =[
        {path: 'TransactionId', label: this.props.t("TransactionId")},
        {path: 'TransactionDate', label: this.props.t("TransactionDate")},
        {path: 'Buyer', label: this.props.t("Buyer"), content: trade => <span> {trade.Buyer ?? <i>[Pending]</i>}</span>},
        {path: 'Seller', label: this.props.t("Seller")},
        {path: 'Quantity', label: this.props.t("Quantity")},
        {path: 'Registry', label: this.props.t("Registry")},
        {path: 'Vintage', label: this.props.t("Vintage")},
        {path: 'QuantificationProtocol', label: this.props.t("QuantificationProtocol")},
        {path: 'SerialStart', label: this.props.t("SerialStart")},
        {path: 'SerialEnd', label: this.props.t("SerialEnd")},
    ];

    mapToViewModel(trades) {

        return trades.map((trade, index) => (
            {
                index: index,
                Id: trade.Id,
                TransactionId: trade.TransactionId,
                TransactionDate: formatDate(trade.TransactionDate),
                Buyer: trade.Buyer,
                Seller: trade.Seller,
                Quantity: trade.Quantity,
                Registry: trade.Registry,
                Vintage: trade.Vintage,
                QuantificationProtocol: trade.QuantificationProtocol,
                SerialStart: trade.SerialStart,
                SerialEnd: trade.SerialEnd,
            }
        ));
    }

    render() { 
        const {trades, onSort, sortColumn, onRowClick} = this.props;

        const mappedTrades = this.mapToViewModel(trades);

        return (
            <Table className="table table-dark table-sm" columns= {this.columns} sortColumn = {sortColumn} onSort={onSort} data={mappedTrades} onRowClick={onRowClick} valueProperty="index"/>
        );
    }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalTradesTable);