import React from 'react';
import {Typeahead as Ta} from 'react-bootstrap-typeahead'

const Typeahead = ({name, label, options, error, idField, optionField, selected, disabled, ...rest}) => {

    return (  
        <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Ta 
            {...rest}
            id={name}
            name={name}
            labelKey={optionField}
            selected={selected}
            options={options}
            disabled={disabled}
        />
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
    );
}
 
export default Typeahead;