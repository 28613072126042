import React, { Component } from 'react';
import _ from 'lodash';
import Loading from '../../common/components/loading/loading';
import { getTradesForReview, approveTrade, rejectTrade} from "../../common/services/marketdata/tradeService";
import ReviewTradesTable from "./reviewTradesTable";
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import { toast } from "react-toastify";
import { withTranslation } from 'react-i18next';
import Input from "../../common/components/form/input";
import uuid from 'react-uuid';
import { formatDate } from "../../common/services/utilities";

class ReviewTrades extends Component {
    state = {
        trades: [],
        marketDate: null,        
        isLoading: true
   };

    async componentDidMount() {
      const date = new Date();
      const marketDate =  formatDate(date);  

        const trades = await getTradesForReview(marketDate);

        this.setState(
          {
            trades: trades,
            isLoading: false,
            marketDate: marketDate
          });
    };

    handleMarketDateChange = (marketDate) => {
      this.setState({ marketDate: marketDate });
  
      (async () => {
        const trades = await getTradesForReview(marketDate);
        this.setState({trades: trades});
      })();      
    };


    handleApprove = async (tradeId) =>
    {
      const {t} = this.props;
      const eventId = uuid();

      try{         
          await approveTrade(tradeId, {eventId: eventId});        
          toast.success(t("TradeApproved"))

          //Update the State
          const trades = [...this.state.trades];
          const index = trades.findIndex(trade => trade.tradeId === tradeId);
          var updatedTrade = {...trades[index]};
          updatedTrade.tradeState = "Approved";
          trades[index] = updatedTrade
          this.setState({ trades: trades });          
      }
      catch (ex){
          console.log(t("ErrorSaving"),ex);
          
          if (ex.response && ex.response.status === 400) {

              var errorList = ex.response.data.errors
              var errorKeys = Object.keys(ex.response.data.errors);               
              errorKeys.forEach(function(key) {
                  errorList[key].map(error => {
                      toast.error(t("ErrorSaving")+" "+key+": "+error)
                      return null;
                  });
              });
          }
      }
    }

    handleReject = async (tradeId) =>
    {
      const {t} = this.props;
      const eventId = uuid();

      try{         
          await rejectTrade(tradeId, {eventId: eventId});        
          toast.success(t("TradeRejected"))

          //Update the State
          const trades = [...this.state.trades];
          const index = trades.findIndex(trade => trade.tradeId === tradeId);
          var updatedTrade = {...trades[index]};
          updatedTrade.tradeState = "Rejected";
          trades[index] = updatedTrade
          this.setState({ trades: trades });          
      }
      catch (ex){
          console.log(t("ErrorSaving"),ex);
          
          if (ex.response && ex.response.status === 400) {

              var errorList = ex.response.data.errors
              var errorKeys = Object.keys(ex.response.data.errors);               
              errorKeys.forEach(function(key) {
                  errorList[key].map(error => {
                      toast.error(t("ErrorSaving")+" "+key+": "+error);
                      return null;
                  });
              });
          }
      }
    } 


    render() { 
        const {trades, marketDate, isLoading} = this.state;

        if (isLoading) return (<Loading/>);

        const {t} = this.props;

        var date = new Date();
        const endDate = formatDate(date);

        const groups = _.groupBy(trades,  'marketName', 'productId');

        return (
          <div className="container-fluid">
              <h3>{t("ReviewTrades")}</h3>
              <div className="row">
                <div className="col-md-2">
                  <Input
                    data-bs-theme="dark"
                    type="date"
                    max={endDate}
                    onChange={(e) =>
                      this.handleMarketDateChange(e.currentTarget.value)
                    }
                    //name = "name"
                    value={marketDate}
                    label={t("MarketDate")}
                  />
                </div>
              </div>    
              <Accordion data-bs-theme="dark" flush alwaysOpen>
      
                {_.map(groups, (group, marketName) => (
                  <Accordion.Item key={marketName} eventKey={marketName}>
                    <Accordion.Header>   
                      <div style={{width:"95%"}}>
                        {marketName}
                      </div>           
                      <div style={{width:"5%"}}>
                      {
                        group.filter(trade => trade.tradeState==="Submitted").length > 0 && //Suppress if none
                        <Badge pill bg="warning" text="dark">
                          {group.filter(trade => trade.tradeState==="Submitted").length}
                        </Badge>
                      }
                      </div>
                    </Accordion.Header>
                      <Accordion.Body >
                        <ReviewTradesTable handleApprove={this.handleApprove} handleReject={this.handleReject} trades={group}/>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>


          </div>

        );
    }
}

export default withTranslation(["marketdata"])(ReviewTrades)