import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_PRODUCT_API+"commodity/";

export async function getCommodities() {
  const {data: Commodities} = await http.get(apiEndpoint);
  return Commodities;
}

export async function getCommodity(commodityId) {
  const {data: Commodity} = await http.get(apiEndpoint+commodityId);
  return Commodity;
}

export async function addCommodity(commodity) {  
  const {data: Commodity} = await http.post(apiEndpoint, commodity);
  return Commodity;
}

export async function updateCommodity(commodity) {
  const {data: Commodity} = await http.put(apiEndpoint, commodity);
  return Commodity;
}

export async function deleteCommodity(commodityId) {
  return await http.delete(apiEndpoint+commodityId);
}

export async function getCommodityHistory(commodityId) {
  const {data: CommodityHistory} = await http.get(apiEndpoint+"audit/"+commodityId);
  return CommodityHistory;
}
