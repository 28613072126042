import React, { Component } from "react";
import _ from "lodash";
import Table from "../../../common/components/table/table";
import Loading from "../../../common/components/loading/loading";
import { getTermHistory } from "../../../common/services/markets/termService";
import { withTranslation } from "react-i18next";
import { formatDate, formatDateTime } from "../../../common/services/utilities";

class TermHistory extends Component {
  state = {
    termHistory: [],
    pageSize: 20,
    currentPage: 1,
    sortColumn: { path: "id", order: "desc" },
    isLoading: true,
  };

  async componentDidMount() {
    const { termId } = this.props;
    const termHistory = this.mapToViewModel(await getTermHistory(termId));

    this.setState({
      termHistory: _.orderBy(termHistory, "id", "desc"),
      isLoading: false,
    });
  }

  mapToViewModel(terms) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      second: "numeric",
    };

    return terms.map((term) => ({
      id: term.id,
      name: term.name,
      termScheduleName: term.termScheduleName,
      startDate: formatDateTime(term.startDate),
      endDate: formatDateTime(term.endDate),
      effectiveDate: formatDate(term.effectiveDate),
      expiryDate: formatDate(term.expiryDate),
      active: term.active.toString(),
      updatedByUserAccountId: term.createdByUserAccountId,
      updatedBy:
        term.updatedByUserAccountFirstName +
        " " +
        term.updatedByUserAccountLastName,
      updatedDate: new Date(term.updatedDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      writeDate: new Date(term.writeDate).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      eventId: term.eventId,
    }));
  }

  columns = [
    { path: "id", label: this.props.t("RecordID") },
    { path: "name", label: this.props.t("TermName") },
    { path: "termScheduleName", label: this.props.t("TermSchedule") },
    { path: "startDate", label: this.props.t("StartDate") },
    { path: "endDate", label: this.props.t("EndDate") },
    { path: "effectiveDate", label: this.props.t("EffectiveDate") },
    { path: "expiryDate", label: this.props.t("ExpiryDate") },
    { path: "active", label: this.props.t("Active") },
    { path: "updatedBy", label: this.props.t("UpdatedBy") },
    { path: "updatedDate", label: this.props.t("UpdateDate") },
    { path: "writeDate", label: this.props.t("WriteDate") },
    { path: "eventId", label: this.props.t("EventID") },
  ];

  render() {
    const { termHistory, sortColumn, isLoading } = this.state;

    if (isLoading) return <Loading />;

    const { t, termId } = this.props;

    return (
      <div className="history-table-container">
        {t("TermID")} {termId}
        <Table
          className="table-history"
          columns={this.columns}
          sortColumn={sortColumn}
          data={termHistory}
          valueProperty="id"
        />
      </div>
    );
  }
}

export default withTranslation(["markets"])(TermHistory);
