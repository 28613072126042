import React, { Component } from 'react';
import { DualListBox as DLB} from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

//Abstracting this out so we can replace this componenta and just reuse it's interface if needed.
class DualListBox extends Component {

    render() { 
        const {options, selected, onChange, ...rest} = this.props
        
        return (
            <div>
                <DLB {...rest}
                    options={options}
                    selected={selected}
                    onChange={(selected, selection, controlKey)=>onChange(selected, selection, controlKey)}
                />
            </div>    
        );
    }
}
 
export default DualListBox;