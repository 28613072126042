import React, { Component } from 'react';
import { formatDate } from "../../common/services/utilities";
import Table from '../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class AlbertaEnvironmentalRegistryTable extends Component {
    columns =[        
        {path: 'ProjectIdentifier', label: this.props.t("ProjectIdentifier")},        
        {path: 'CurrentOwner', label: this.props.t("CurrentOwner")},
        {path: 'EmissionOffsetProjectDeveloper', label: this.props.t("EmissionOffsetProjectDeveloper")},
        {path: 'Quantity', label: this.props.t("Quantity")},
        {path: 'Registry', label: this.props.t("Registry")},
        {path: 'Vintage', label: this.props.t("Vintage")},
        {path: 'QuantificationProtocol', label: this.props.t("QuantificationProtocol")},
        {path: 'ActivityStart', label: this.props.t("ActivityStart")},
        {path: 'ComplianceYear', label: this.props.t("ComplianceYear")},
        {path: 'ExpiryDate', label: this.props.t("ExpiryDate")},
        {path: 'Facility', label: this.props.t("Facility")},
        {path: 'OffsetStartDate', label: this.props.t("OffsetStartDate")},
        {path: 'OffsetEndDate', label: this.props.t("OffsetEndDate")},
        {path: 'Status', label: this.props.t("Status")},
        {path: 'Title', label: this.props.t("Title")},
        {path: 'VerifierCompany', label: this.props.t("VerifierCompany")},
        {path: 'SerialStart', label: this.props.t("SerialStart")},
        {path: 'SerialEnd', label: this.props.t("SerialEnd")},
        {path: 'TransactionId', label: this.props.t("TransactionId")},
    ];

    mapToViewModel(registry) {

        return registry.map((record, index) => (
            {
                index: index,
                ProjectIdentifier: record.ProjectIdentifier,
                CurrentOwner: record.CurrentOwner,
                EmissionOffsetProjectDeveloper: record.EmissionOffsetProjectDeveloper,
                Quantity: record.Quantity,
                Registry: record.Registry,
                Vintage: record.Vintage,                
                QuantificationProtocol: record.QuantificationProtocol,
                ActivityStart: record.ActivityStart ? formatDate(record.ActivityStart) : null,
                ComplianceYear: record.ComplianceYear,
                ExpiryDate: record.ExpiryDate ? formatDate(record.ExpiryDate) : null,
                Facility: record.Facility,
                OffsetStartDate: record.OffsetStartDate ? formatDate(record.OffsetStartDate) : null,
                OffsetEndDate: record.OffsetEndDate ? formatDate(record.OffsetEndDate) : null,
                Status: record.Status,
                Title: record.Title,
                VerifierCompany: record.VerifierCompany,
                SerialStart: record.SerialStart,
                SerialEnd: record.SerialEnd,
                TransactionId: record.TransactionId,
            }
        ));
    }

    render() { 
        const {registry, onSort, sortColumn, onRowClick} = this.props;

        const mappedRegistry = this.mapToViewModel(registry);

        return (
            <Table className="table table-dark table-sm" columns= {this.columns} sortColumn = {sortColumn} onSort={onSort} data={mappedRegistry} onRowClick={onRowClick} valueProperty="index"/>
        );
    }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalRegistryTable);