import React, { Component } from "react";
import * as FlexLayout from "flexlayout-react";
import Terms from "./terms";
import Term from "./term";
import TermSchedules from "./termSchedules";
import TermSchedule from "./termSchedule";
import { withTranslation } from "react-i18next";

class SubscriptionAdmin extends Component {
  LAYOUT = {
    global: {
      minWidth: 100,
      minHeight: 100,
    },
    borders: [],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 50,
          enableMaximize: false,
          children: [
            {
              type: "tab",
              name: this.props.t("Terms"),
              enableClose: false,
              component: "Terms",
            },
            {
              type: "tab",
              name: this.props.t("TermSchedules"),
              enableClose: false,
              component: "TermSchedules",
            },
          ],
        },
        {
          //Figure out how to make this persistent even when empty.
          type: "tabset",
          id: "1",
          weight: 50,
          enableDeleteWhenEmpty: false,
          enableMaximize: false,
          children: [
            // {
            //   type: "tab",
            //   name: "User",
            //   visible: false,
            //   component: "Placeholder"
            // }
          ],
        },
      ],
    },
  };

  state = {
    model: FlexLayout.Model.fromJson(this.LAYOUT),
  };

  handleTermSelect = (Term, submitCallbackFunction, isNew) => {
    const { termId, name } = Term;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "Term",
            name: `${name}`,
            id: `${termId}`,
            config: {
              termId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(termId));
    }
  };

  handleTermScheduleSelect = (TermSchedule, submitCallbackFunction, isNew) => {
    const { termScheduleId, name } = TermSchedule;

    try {
      this.state.model.doAction(
        FlexLayout.Actions.addNode(
          {
            type: "tab",
            component: "TermSchedule",
            name: `${name}`,
            id: `${termScheduleId}`,
            config: {
              termScheduleId,
              submitCallbackFunction,
              isNew,
            },
          },
          "1",
          FlexLayout.DockLocation.CENTER,
          0,
          true
        )
      );
    } catch {
      this.state.model.doAction(FlexLayout.Actions.selectTab(termScheduleId));
    }
  };

  handleTabRename = (nodeId, name) => {
    this.state.model.doAction(
      FlexLayout.Actions.updateNodeAttributes(nodeId, { name: name })
    );
  };

  factory = (node) => {
    const componentId = node.getComponent();
    //const TermId = node.getId();

    if (componentId === "Terms") {
      return (
        <Terms
          onClick={this.handleTermSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Term") {
      const { termId, submitCallbackFunction, isNew } = node.getConfig();
      return (
        <Term
          termId={termId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "TermSchedules") {
      return (
        <TermSchedules
          onClick={this.handleTermScheduleSelect}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "TermSchedule") {
      const { termScheduleId, submitCallbackFunction, isNew } =
        node.getConfig();
      return (
        <TermSchedule
          termScheduleId={termScheduleId}
          submitCallbackFunction={submitCallbackFunction}
          isNew={isNew}
          onRename={this.handleTabRename}
          allowEdit={this.props.allowEdit}
        />
      );
    } else if (componentId === "Placeholder") {
      return <div></div>;
    } else {
      return null;
    }
  };

  buildFlexLayout = () => {
    return (
      <div className="flex-container">
        <FlexLayout.Layout model={this.state.model} factory={this.factory} />;
      </div>
    );
  };

  render() {
    return this.buildFlexLayout();
  }
}

export default withTranslation(["markets"])(SubscriptionAdmin);
