import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"priceBasis/";

export async function getPriceBases() {
    const {data: PriceBases} = await http.get(apiEndpoint);
    return PriceBases;
}

export async function getPriceBasis(priceBasisId) {
  const {data: PriceBasis} = await http.get(apiEndpoint+priceBasisId);
  return PriceBasis;
}

export async function addPriceBasis(priceBasis) {  
  const {data: PriceBasis} = await http.post(apiEndpoint, priceBasis);
  return PriceBasis;
}

export async function updatePriceBasis(priceBasis) {
  const {data: PriceBasis} = await http.put(apiEndpoint, priceBasis);
  return PriceBasis;
}

export async function deletePriceBasis(priceBasisId) {
  return await http.delete(apiEndpoint+priceBasisId);
}

export async function getPriceBasisHistory(priceBasisId) {
  const {data: PriceBasisHistory} = await http.get(apiEndpoint+"audit/"+priceBasisId);
  return PriceBasisHistory;
}
