import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_MARKET_API+"marketGroupMarket/";

export async function getMarketGroupMarkets() {
    const {data: MarketGroupMarkets} = await http.get(apiEndpoint);
    return MarketGroupMarkets;
}

export async function getMarketsByMarketGroup(marketGroupId) {
  const {data: MarketGroupMarket} = await http.get(apiEndpoint+"GetMarkets/"+marketGroupId);
  return MarketGroupMarket;
}

export async function assignMarket(marketGroupMarket) {  
  const {data: MarketGroupMarket} = await http.post(apiEndpoint, marketGroupMarket);
  return MarketGroupMarket;
}

export async function revokeMarket(marketGroupMarketId, marketId) {
  return await http.delete(apiEndpoint+marketGroupMarketId+"/"+marketId);
}

export async function getMarketGroupMarketHistory(marketGroupMarketId) {
  const {data: MarketGroupMarketHistory} = await http.get(apiEndpoint+"audit/"+marketGroupMarketId);
  return MarketGroupMarketHistory;
}
