import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_ALBERTA_ENVIRONMENTAL_API;

export async function getTrades() {
    const {data: trades} = await http.get(apiEndpoint+"trades");
    return trades;
}

export async function getStatusChanges() {
    const {data: trades} = await http.get(apiEndpoint+"statuschanges");
    return trades;
}

export async function getIssues() {
    const {data: trades} = await http.get(apiEndpoint+"issues");
    return trades;
}

export async function getRegistry(registryDate=null) {
    const {data: trades} = await http.get(apiEndpoint+"registry"+ (!registryDate ? "" : "?RegistryDate="+registryDate));
    return trades;
}

  