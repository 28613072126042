import http from '../app/httpService';

const apiEndpoint = process.env.REACT_APP_USER_ACCOUNT_API+"useraccountrole/";

export async function getRolesByUser(userAccountId) {
  const {data: roles} = await http.get(apiEndpoint+userAccountId);
  return roles;
}

export async function assignRole(userAccountId, role) {  
  await http.post(apiEndpoint+userAccountId, role);
}

export async function revokeRole(userAccountId, role) {
  await http.delete(apiEndpoint+userAccountId, {data: role});
}

export async function getUserAccountRoleHistory(userAccountId) {
  const {data: userAccountHistory} = await http.get(apiEndpoint+"audit/"+userAccountId);
  return userAccountHistory;
}
