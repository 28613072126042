import React, { Component } from 'react';
import FontAwesome from "react-fontawesome";
import Table from '../../../common/components/table/table';
import { withTranslation } from 'react-i18next';

class OrganizationsTable extends Component {
    columns =[
        {path: 'name', label: this.props.t("OrganizationName")},
        {path: 'shortName', label: this.props.t("OrganizationShortName")},
        {path: 'active', label: this.props.t("Active"), content: organization => <FontAwesome name={organization.active ? "check" : "times"}/>}
    ];

    // constructor() {
    //     super();
    //     const user = auth.getCurrentUser();
    //     if (user && user.isAdmin)
    //         this.columns.push(this.deleteColumn);
    // }

    render() { 
        const {organizations, onSort, sortColumn, onRowClick} = this.props;
        return (
            <Table className="table table-dark table-sm" columns= {this.columns} sortColumn = {sortColumn} onSort={onSort} data={organizations} onRowClick={onRowClick} valueProperty="organizationId"/>
        );
    }
}

export default withTranslation(["auth"])(OrganizationsTable)